import {Outlet} from 'react-router-dom';
import '../../assets/css/product.scss';

function ThiraProduct() {
  return (
    <div className="ThiraProduct">
      <Outlet />
    </div>
  );
}

export default ThiraProduct;
