import React, {useState, useEffect} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {ThiraTitle} from '../../component/common/index';
import * as AmrJs from '../../assets/js/amr';
import {ThiraAmrLocation} from '../../component/amr/index';
import ThiraBanner from '../../component/contents/banner';
import axios from 'axios';
import {useIntl} from 'react-intl'; // 다국어
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';

function ThiraAmrAppDetail() {
  useEffect(() => {
    AmrJs.lazyLoad();
  });

  const navigate = useNavigate();
  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어
  // 다국어 세팅
  const {t} = useTranslation('amr');

  const [apiData, setApiData] = useState(null);
  const location = useLocation();
  const sch = location.search;
  const params = new URLSearchParams(sch);
  const board_id = params.get('board_id');
  const contents_id = params.get('contents_id');

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/board/${board_id}/contents/${contents_id}/vicinity?lang=${i18next.language}`)
      .then(response => {
        setApiData(response.data);
      })
      .catch(() => {
        console.log('Error!');
      });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [board_id, contents_id]);

  if (!apiData) return null; //로딩중...

  if (!apiData.contents) {
    alert('This post does not exist.');
    navigate(-1);
    return null;
  }

  const bannerList = apiData.vicinity.map(item => {
    return {
      url: `?board_id=${item.board_id}&contents_id=${item.id}`,
      background: `/storage/${item.thumb_img}`,
      text: item.title,
    };
  });
  // 배너 컴포넌트 데이터
  const bannerData = {
    bannerTitle: t('case.banner'),
    bannerList,
  };
  return (
    <div className="ThiraAmrAppDetail">
      <div className="inner-width">
        <ThiraAmrLocation name={t('case.title')} link="CaseStudies"></ThiraAmrLocation>
        <ThiraTitle title={t('case.title')}></ThiraTitle>
      </div>

      <hr className="ThiraBorder" />

      <div className="inner-width">
        <h3 className="ThiraAmrAppDetail__title">{apiData?.contents.title}</h3>
        <div className="editor" dangerouslySetInnerHTML={{__html: apiData.contents.contents}}></div>
        {apiData.contentsFile.length > 0 && (
          <table className="ThiraAmrAppDetail__file">
            <tbody>
              <tr>
                <th>Attached file</th>
                <td>
                  <div className="files">
                    {apiData.contentsFile.map((f, k) => (
                      <Link to={f.download_url} key={`att_${board_id}${contents_id}${k}`}>
                        {f.org_name}
                      </Link>
                    ))}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        <Link to={`/${lang}/amr/CaseStudies`} className="btn-list">
          {t('case.list')}
        </Link>
      </div>
      <br />
      <br />
      <ThiraBanner data={bannerData} />
    </div>
  );
}

export default ThiraAmrAppDetail;
