import {Link} from 'react-router-dom';
// Import Swiper
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {Navigation} from 'swiper';

function ThiraBanner({data}) {
  const {bannerTitle, bannerList} = data || '';

  return (
    <div className="ThiraBanner">
      <div className="inner-width">
        <p className="ThiraBanner__title">{bannerTitle}</p>
        <div className="swiper-wrap">
          <Swiper speed={600} watchOverflow={true} spaceBetween={30} slidesPerView={'3'} slidesPerGroup={'3'} navigation={true} modules={[Navigation]} className="banner-swiper pc-flex">
            {bannerList?.map((item, index) => {
              return (
                <SwiperSlide key={'bannerList' + index}>
                  <Link to={item.url} className="ThiraBanner__link">
                    <img src={item.background} alt="배경 이미지" className="ThiraBanner__bg" />
                    <p className="ThiraBanner__txt">{item.text}</p>
                  </Link>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
      <div className="swiper-wrap">
        <Swiper
          speed={600}
          parallax={true}
          watchOverflow={true}
          spaceBetween={30}
          slidesPerView={'1.4'}
          navigation={{
            nextEl: '.banner-swiper-button-next',
            prevEl: '.banner-swiper-button-prev',
          }}
          modules={[Navigation]}
          className="banner-swiper mo-flex"
        >
          {bannerList?.map((item, index) => {
            return (
              <SwiperSlide key={'bannerList' + index}>
                <Link to={item.url} className="ThiraBanner__link">
                  <img src={item.background} alt="배경 이미지" className="ThiraBanner__bg" />
                  <p className="ThiraBanner__txt">{item.text}</p>
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}

export default ThiraBanner;
