import {Link, useLocation, useParams} from 'react-router-dom';
import {Children, useEffect, useState} from 'react';
import Modal from '../../util/Modal';
import {useIntl} from 'react-intl'; // 다국어
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';
import Go from './go';
// img
import LogoPng from '../../assets/images/common/logo.png';
import LogoColorPng from '../../assets/images/common/logo_color.png';
import ThiraHeaderMobileSub from './headerMobileSub';
import OpenSvg from '../../assets/images/common/ico_open_white.svg';

function ThiraHeader({showBanner}) {
  const {pathname} = useLocation();
  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어
  // 다국어 세팅
  const {t} = useTranslation('header');

  // 국영문 변경 이벤트
  const handleLanguageChange = e => {
    const selectedLanguage = e.target.value;
    localStorage.setItem('lang', selectedLanguage); //api통신용으로 세팅한건데, i18next을 사용하게되어 api에서는 더이상 사용안함.
    i18next.changeLanguage(selectedLanguage);

    const currentPath = window.location.pathname;
    const currentParam = window.location.search;

    let newPath;
    // 다른 언어로 변경한 경우, 언어 부분을 변경한 새로운 URL 생성
    newPath = currentPath.replace(/\/(ko|en)(?=\/|$)/, `/${selectedLanguage}`);

    // 파라미터가 있는경우 파라미터 추가
    if (currentParam !== undefined) {
      newPath = newPath + currentParam;
    }
    // 기본 언어가 없을 경우에는 en을 추가
    if (!newPath.includes('/ko') && !newPath.includes('/en')) {
      window.location.href = `${newPath}${selectedLanguage}`;
      // 고객 서비스 디테일 페이지에서 국영문 변경시, 목록 페이지로 이동
    } else if (newPath.includes('detail') && newPath.includes('resource')) {
      newPath = newPath.substring(0, newPath.indexOf('/detail'));
      window.location.href = newPath;
      // 사례연구 디테일 페이지에서 국영문 변경시, 목록 페이지로 이동
    } else if (newPath.includes('detail') && newPath.includes('CaseStudies')) {
      newPath = newPath.substring(0, newPath.indexOf('/detail'));
      window.location.href = newPath;
    } else {
      // 생성한 새로운 URL로 페이지 이동
      window.location.href = newPath;
    }
  };

  // 헤더 스크롤 이벤트
  const [isActiveScroll, setIsActiveScroll] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.pageYOffset > 0 ? setIsActiveScroll(true) : setIsActiveScroll(false);
    });
  }, [isActiveScroll]);

  const HeaderStatus = () => {
    if (menuHover === true) {
      return 'ThiraHeader menu-active scroll-down';
    } else if (isActiveScroll > 0) {
      return 'ThiraHeader scroll-down';
    } else {
      return 'ThiraHeader';
    }
  };

  // 메뉴 hover
  const [menuHover, setMenuHover] = useState(false);

  // 모바일 서브메뉴 오픈
  const [mbMenuOpen, setMbMenuOpen] = useState(false);
  const onClickOpen = () => {
    setMbMenuOpen(true);
    Modal.modalOpen();
  };
  const data = {
    isState: {
      state: mbMenuOpen,
      setState: setMbMenuOpen,
    },
  };

  // 메뉴 데이터
  const menuList = [
    {
      id: 0,
      defaultUrl: `${lang}/amr/`,
      LnbMenuList: [`${lang}/amr/introduction`, `${lang}/amr/fms`, `${lang}/amr/solutions`, `${lang}/amr/CaseStudies`, `${lang}/amr/ROI`],
    },
    {
      id: 1,
      defaultUrl: `${lang}/industry/`,
      LnbMenuList: [`${lang}/industry/manufacture`, `${lang}/industry/logistics`],
    },
    {
      id: 2,
      defaultUrl: `${lang}/solutions/`,
      LnbMenuList: [`${lang}/solutions/AntiSlip`, `${lang}/solutions/Obstacle`, `${lang}/solutions/Elevator`, `${lang}/solutions/Logistics`, `${lang}/solutions/Following`, `${lang}/solutions/Safety`],
    },
    {
      id: 3,
      defaultUrl: `${lang}/product/`,
      LnbMenuList: [`${lang}/product/models/T-Series`, `${lang}/product/models/L-Series`, `${lang}/product/models/chargingSystem`, `${lang}/product/models/Acc`],
    },
    {
      id: 4,
      defaultUrl: `${lang}/resource/`,
      LnbMenuList: [`${lang}/resource/board`, `${lang}/resource/newsBlog`, `${lang}/resource/faq`],
    },
    {
      id: 5,
      defaultUrl: `${lang}/about/`,
      LnbMenuList: [`${lang}/about/OurStory`, `${lang}/about/Location`, `${lang}/about/partner`, `${lang}/about/certification`],
    },
  ];

  return (
    <header className={HeaderStatus()}>
      <div className="inner-width w-1520 header-inner">
        <h1 className="ThiraHeader__h1">
          <Link to={`/${lang}`}>
            <img src={isActiveScroll || menuHover ? LogoColorPng : LogoPng} alt="THIRA ROBOTICS " />
          </Link>
        </h1>
        <button type="button" className="hamburger-button" onClick={onClickOpen}>
          <img src={OpenSvg} alt="메뉴 열기" />
        </button>
        <div className="nav-wrap pc-flex">
          <nav className="ThiraHeader__menu" onMouseEnter={() => setMenuHover(true)} onMouseLeave={() => setMenuHover(false)}>
            <ul className="gnb">
              {menuList.map((list, idx) => {
                return (
                  <li key={'menuList' + idx}>
                    <p className={pathname.includes(list.defaultUrl) ? 'active gnb-menu' : 'gnb-menu'}>{t(`gnbMenuName.${idx}.title`)}</p>
                    <ul className="lnb">
                      {list.LnbMenuList.map((url, index) => {
                        return (
                          <li key={'lnbMenuList' + index}>
                            <Link to={url}>{t(`gnbMenuName.${idx}.subMenu.${index}`)}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
            </ul>
            {/* )} */}
            <div className="gnb-bg"></div>
          </nav>
          <Link to={`/${lang}/inquiry`} className="contact-button">
            {t('Contact_Us')}
          </Link>
        </div>
        <div className="t-lang br-pc">
          <select
            className="t-sel"
            name=""
            id=""
            // onChange={e => {
            //   const selectedLanguage = e.target.value;
            //   localStorage.setItem('lang', selectedLanguage);
            //   i18next.changeLanguage(selectedLanguage);

            //   // 현재 페이지 URL에서 언어 부분 변경하여 새로운 URL 생성
            //   const currentPath = window.location.pathname;
            //   const newPath = currentPath.replace(/\/(ko|en)\//, `/${selectedLanguage}/`);

            //   // 생성한 새로운 URL로 페이지 이동
            //   window.location.href = newPath;
            // }}
            onChange={handleLanguageChange}
            value={i18next.language}
          >
            <option value="en">ENG</option>
            <option value="ko">KOR</option>
          </select>
        </div>
      </div>
      {mbMenuOpen && <ThiraHeaderMobileSub data={data} />}
    </header>
  );
}

export default ThiraHeader;
