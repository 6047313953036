import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {ThiraTitle} from '../../component/common/index';
import {business, country} from '../common/SelectValue';
import axios from 'axios';
import {useIntl} from 'react-intl'; // 다국어
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';
import LineWrap from '../../component/common/lineWrap';

function InquiryForm() {
  const navigate = useNavigate(); //페이지 이동

  // 다국어 세팅
  const intl = useIntl();
  const LANG = intl.messages.INQUIRY.FORM;

  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어
  // 다국어 세팅
  const {t} = useTranslation('inquiry');

  const linkList = [
    {
      title: t('FORM.link.linkList.0'),
      url: `/${lang}/product/models`,
    },
    {
      title: t('FORM.link.linkList.1'),
      url: `/${lang}/amr/ROI`,
    },
  ];

  // select 박스 border디자인 분기처리
  // const [countrySelected, setCountrySelected] = useState(false);
  // const [businessSelected, setBusinessSelected] = useState(false);
  const [frData, setFrData] = useState({
    fullname: '',
    email: '',
    country: '',
    company: '',
    business: business[0],
    phone: '',
    inquiry: '',
    newsletter: false,
    privacyPolicy: false,
    advertising: false,
  });
  const [ROIData] = useState(() => {
    const d = JSON.parse(localStorage.getItem('ROIData'));
    localStorage.removeItem('ROIData'); //읽고 바로 제거
    return d;
  });

  const handleChange = e => {
    setFrData({
      ...frData,
      [e.target.name]: ['privacyPolicy', 'advertising'].includes(e.target.name) ? e.target.checked : e.target.value,
    });
  };
  const handleSubmit = e => {
    e.preventDefault();
    const data = {...frData, ROIData};
    axios
      .post(`${process.env.REACT_APP_API}/mail`, data, {
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then(response => {
        console.log('mail result', response.data);
        navigate(`complete`);
      })
      .catch(response => {
        console.log('Error!', response);
      });
  };
  return (
    <section className="inquiryForm">
      <div className="inner-width">
        <div className="ThiraInquiry__text-container" data-aos="fade-left">
          <ThiraTitle title={<LineWrap text={t('FORM.title')} />} />
          <p className="sub-title">{<LineWrap text={t('FORM.subTitle')} />}</p>
          {/* <p className="util-title pc-block">{LANG.link.title}</p> */}
          <div className="util-link-list pc-flex">
            {linkList.map((item, index) => (
              <Link to={item.url} key={'linkList' + index}>
                {item.title}
              </Link>
            ))}
          </div>
        </div>
        <fieldset className="ThiraInquiry__form-container">
          <legend className="blind">inquiry Form</legend>
          <form onSubmit={handleSubmit}>
            {/* COMPANY */}
            <div className="input-wrap">
              <label htmlFor="user-company">
                {t('FORM.formData.Company')}
                <span className="required">*</span>
              </label>
              <input id="user-company" type="text" placeholder={t('FORM.formData.Company')} value={frData.company} onChange={handleChange} name="company" required />
              {/* <p className="error-msg">This field is required.</p> */}
            </div>
            {/* FULL NAME */}
            <div className="input-wrap">
              <label htmlFor="user-name">
                {t('FORM.formData.Full_Name')}
                <span className="required">*</span>
              </label>
              <input id="user-name" type="text" placeholder={t('FORM.formData.Full_Name')} value={frData.fullname} onChange={handleChange} name="fullname" required />
              {/* <p className="error-msg">This field is required.</p> */}
            </div>
            {/* EMAIL */}
            <div className="input-wrap">
              <label htmlFor="user-email">
                {t('FORM.formData.Email')}
                <span className="required">*</span>
              </label>
              <input id="user-email" type="email" placeholder={t('FORM.formData.Email')} value={frData.email} onChange={handleChange} name="email" required />
              {/* <p className="error-msg">This field is required.</p> */}
            </div>
            {/* PHONE NUMBER */}
            <div className="input-wrap">
              <label htmlFor="user-number">
                {t('FORM.formData.Phone_Number')}
                <span className="input-option">&#40;{t('FORM.option')}&#41;</span>
              </label>
              <input id="user-number" type="text" value={frData.phone} onChange={handleChange} name="phone" placeholder={t('FORM.formData.Phone_Number')} inputMode="numeric" />
            </div>
            {/* BUSINESS */}
            <div className="input-wrap">
              <label htmlFor="user-business">{t('FORM.formData.Business')}</label>
              <select id="user-business" value={frData.business} onChange={handleChange} name="business" required>
                {business.map(v => (
                  <option value={v} key={`business_${v}`}>
                    {v}
                  </option>
                ))}
              </select>
              {/* <p className="error-msg">This field is required.</p> */}
            </div>
            {/* COUNTRY */}
            <div className="input-wrap">
              <label htmlFor="user-country">{t('FORM.formData.Country')}</label>
              <input id="user-country" type="text" placeholder={t('FORM.formData.Country')} value={frData.country} onChange={handleChange} name="country" />
              {/* <select id="user-country" value={frData.country} onChange={handleChange} name="country" required>
                {country.map(v => (
                  <option value={v} key={`country_${v}`}>
                    {v}
                  </option>
                ))}
              </select> */}
              {/* <p className="error-msg">This field is required.</p> */}
            </div>
            {/* INQUIRY */}
            <div className="input-wrap">
              <label htmlFor="user-inquiry">
                {t('FORM.formData.Inquiry')}
                <span className="input-option">&#40;{t('FORM.option')}&#41;</span>
              </label>
              <textarea name="inquiry" rows={3} id="user-inquiry" placeholder={t('FORM.formData.Inquiry')} value={frData.inquiry} onChange={handleChange}></textarea>
            </div>
            <div className="input-wrap ThiraInquiry__check-input">
              {/* <input id="agree" type="checkbox" name="newsletter" checked={frData.newsletter} onChange={handleChange} />
              <label htmlFor="agree">
                <span className="check-img"></span>
                {LANG.terms[0]}
              </label> */}
              <input id="policy" type="checkbox" name="privacyPolicy" checked={frData.privacyPolicy} onChange={handleChange} required />
              {/* required 를 추가한 이유 아래에 설명 */}
              <label htmlFor="policy">
                <span className="check-img"></span>
                {t('FORM.terms.0')}
              </label>
              <input id="policy2" type="checkbox" name="advertising" checked={frData.advertising} onChange={handleChange} required />
              {/* required 를 추가한 이유 아래에 설명 */}
              <label htmlFor="policy2">
                <span className="check-img"></span>
                {t('FORM.terms.1')}
              </label>
              {/* 위 두 checkbox의 display none이라 required를 추가하면 크롬에서 An invalid form control with name='privacyPolicy' is not focusable.라는 경고가 뜹니다, 단 필수항목미선택 시 달리 안내해주는 정책이없어서 어쩔수없이 추가해놓았습니다. */}
            </div>
            {/* <p className="terms-text">{LANG.terms[1]}</p> */}
            <button className="submit-button" type="submit">
              {t('FORM.submit')}
            </button>
          </form>
        </fieldset>
        <p className="util-title mo-block">{t('FORM.link.title')}</p>
        <div className="util-link-list mo-flex">
          {linkList.map((item, index) => (
            <Link to={item.url} key={'linkList' + index}>
              {item.title}
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}

export default InquiryForm;
