import React, {useEffect, useState} from 'react';
import * as ProductJs from '../../assets/js/product';
import ThiraProductLocation from '../../component/product/location';
import ThumbNailModal from '../modal/ThumbNailModal';
import Modal from '../../util/Modal';
import {useTranslation} from 'react-i18next';
import LineWrap from '../common/lineWrap';

// IMG
import T300_Conveyor_1 from '../../assets/images/product/Acc/T300_Conveyor_B/T-300_ConveyorB_01.png';
import T300_Conveyor_2 from '../../assets/images/product/Acc/T300_Conveyor_B/T-300_ConveyorB_02.png';
import T300_Conveyor_3 from '../../assets/images/product/Acc/T300_Conveyor_B/T-300_ConveyorB_03.png';
import T300_Conveyor_4 from '../../assets/images/product/Acc/T300_Conveyor_B/T-300_ConveyorB_04.png';
import T300_Conveyor_5 from '../../assets/images/product/Acc/T300_Conveyor_B/T-300_ConveyorB_05.png';
import T300_Conveyor_6 from '../../assets/images/product/Acc/T300_Conveyor_B/T-300_ConveyorB_06.png';

function T300ConveyorB(props) {
  const {t} = useTranslation('product');
  useEffect(() => {
    ProductJs.showTitle();
    ProductJs.showThumbs();
  });

  // 팝업 열기
  const [isActiveModal, setIsActiveModal] = useState(false);
  const onClickModalOpen = () => {
    setIsActiveModal(true);
    Modal.modalOpen();
  };

  const ContentImgList = [
    {img: T300_Conveyor_1, name: 'T300_Conveyor_1'},
    {img: T300_Conveyor_2, name: 'T300_Conveyor_2'},
    {img: T300_Conveyor_3, name: 'T300_Conveyor_3'},
    {img: T300_Conveyor_4, name: 'T300_Conveyor_4'},
    {img: T300_Conveyor_5, name: 'T300_Conveyor_5'},
    {img: T300_Conveyor_6, name: 'T300_Conveyor_6'},
  ];

  const ModalData = {
    isState: {
      state: isActiveModal,
      setState: setIsActiveModal,
    },
    SlideContent: ContentImgList,
  };

  return (
    <React.Fragment>
      <ThumbNailModal data={ModalData} />
      <ThiraProductLocation default="T300_conveyor_B"></ThiraProductLocation>

      {/* PC */}
      <div className="ThiraProductDetail__top pc-flex">
        <div className="ThiraProductDetail__bigThum">
          <img src={T300_Conveyor_1} alt="썸네일" />
        </div>
        <div className="ThiraProductDetail__topInf">
          <h2 className="ThiraProductDetail__title">{props.name}</h2>
          <p className="ThiraProductDetail__desc">
            <LineWrap text={t('t300ConveyorB.productDetail.desc')} />
          </p>

          <div className="ThiraProductDetail__smallThum">
            {ContentImgList?.filter((item, index) => index < 4).map((item, index) => {
              return (
                <button
                  key={'contentImg' + index}
                  data-name={item.name}
                  onClick={e => {
                    ProductJs.showPopup(e.target.closest('button'));
                  }}
                  className={index === 0 ? 'border' : undefined}
                >
                  <img src={item.img} alt={item.name} />
                </button>
              );
            })}
            {/* 요소 5개 초과/이하 분기처리 -> 이상일 시 모달 연결 */}
            {ContentImgList?.filter((item, index) => index === 4).map((item, index) => {
              return (
                <React.Fragment>
                  {ContentImgList?.length > 5 ? (
                    <button className="more" key={'contentImg' + index} data-name={item.name} onClick={onClickModalOpen}>
                      <p className="more__txt">+{ContentImgList.length - 5}</p>
                      <img src={item.img} alt={item.name} />
                    </button>
                  ) : (
                    <button
                      key={'contentImg' + index}
                      data-name={item.name}
                      onClick={e => {
                        ProductJs.showPopup(e.target.closest('button'));
                      }}
                    >
                      <img src={item.img} alt={item.name} />
                    </button>
                  )}
                </React.Fragment>
              );
            })}
          </div>
          {/* <button
            className="ThiraProductDetail__btn"
            onClick={e => {
              ProductJs.viewDetail(e.target.closest('button'));
            }}
          >
            View Detail Page
          </button> */}
        </div>
      </div>

      {/* MO */}
      <div className="ThiraProductDetail__top mo-block">
        <h2 className="ThiraProductDetail__title">{props.name}</h2>
        <p className="ThiraProductDetail__desc">
          <LineWrap text={t('t300ConveyorB.productDetail.desc')} />
        </p>
        <div className="ThiraProductDetail__bigThum">
          <img src={T300_Conveyor_1} alt="썸네일" />
        </div>
        <div className="ThiraProductDetail__smallThum">
          {ContentImgList?.filter((item, index) => index < 4).map((item, index) => {
            return (
              <button
                key={'contentImg' + index}
                data-name={item.name}
                onClick={e => {
                  ProductJs.showPopup(e.target.closest('button'));
                }}
                className={index === 0 ? 'border' : undefined}
              >
                <img src={item.img} alt={item.name} />
              </button>
            );
          })}
          {/* 요소 5개 초과/이하 분기처리 -> 이상일 시 모달 연결 */}
          {ContentImgList?.filter((item, index) => index === 4).map((item, index) => {
            return (
              <React.Fragment>
                {ContentImgList?.length > 5 ? (
                  <button className="more" key={'contentImg' + index} data-name={item.name} onClick={onClickModalOpen}>
                    <p className="more__txt">+{ContentImgList.length - 5}</p>
                    <img src={item.img} alt={item.name} />
                  </button>
                ) : (
                  <button
                    key={'contentImg' + index}
                    data-name={item.name}
                    onClick={e => {
                      ProductJs.showPopup(e.target.closest('button'));
                    }}
                  >
                    <img src={item.img} alt={item.name} />
                  </button>
                )}
              </React.Fragment>
            );
          })}
        </div>
        {/* <p className="ThiraProductDetail__desc">The T200 can load up to 200kg and is made in a minimum size for use in a small space.</p> */}
      </div>

      <div className="ThiraProductDetail__cont active">
        {/* <h3
          className="ThiraProductDetail__subtitle"
          onClick={e => {
            ProductJs.toggleArrow(e.target.closest('.ThiraProductDetail__subtitle'));
          }}
        >
          Dimensions <button className="arr">아래 화살표</button>
        </h3>
        <ul className="ThiraProductDetail__list">
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Length</p>
            <p className="txt-02">760 mm (810 mm with bumper)<br />29.2 in (31.9 in with bumper)</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Width</p>
            <p className="txt-02">550 mm ( 600mm with bumper)<br />21.7 in (23.6 in with bumper)</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Height</p>
            <p className="txt-02">340 mm | 13.4 in</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Weight</p>
            <p className="txt-02">133 Kg | 293 lbs</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Ground Clearance</p>
            <p className="txt-02">30 mm | 1.2 in</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Load Surface</p>
            <p className="txt-02">760 mm x 550 mm<br />29.2 in x 21.7 in</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Dimensions for Mounting Top Modules</p>
            <p className="txt-02">Robot Footprint</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Maximum payload</p>
            <p className="txt-02">200 kg</p>
          </li>
        </ul> */}
      </div>

      <div className="ThiraProductDetail__cont">
        {/* <h3
          className="ThiraProductDetail__subtitle"
          onClick={e => {
            ProductJs.toggleArrow(e.target.closest('.ThiraProductDetail__subtitle'));
          }}
        >
          Speed and Performance <button className="arr">아래 화살표</button>
        </h3>
        <ul className="ThiraProductDetail__list">
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Maximum speed</p>
            <p className="txt-02">1.4 m/s | 3.1mph </p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Pivoting width</p>
            <p className="txt-02">942 mm | 37.1 in</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Minimum width : pivoting</p>
            <p className="txt-02">1,100 mm</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Operational doorway width</p>
            <p className="txt-02">630 mm | 24.8 in</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">90 degree pivoting width</p>
            <p className="txt-02">982 mm | 38.7 in</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Operational corridor width</p>
            <p className="txt-02">750 mm | 29.3 in</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Operational corridor width for two robots passing</p>
            <p className="txt-02">1,500 mm | 59.1 in</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Distance with chargers</p>
            <p className="txt-02">1,400 mm</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Minimum distance between chargers</p>
            <p className="txt-02">500 mm | 19.7 in</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Docking Accurancy</p>
            <p className="txt-02">±10</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Docking type</p>
            <p className="txt-02">Forward docking, Reverse docking</p>
          </li>
        </ul> */}
      </div>

      <div className="ThiraProductDetail__cont">
        {/* <h3
          className="ThiraProductDetail__subtitle"
          onClick={e => {
            ProductJs.toggleArrow(e.target.closest('.ThiraProductDetail__subtitle'));
          }}
        >
          Battery and charging <button className="arr">아래 화살표</button>
        </h3>
        <ul className="ThiraProductDetail__list">
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Charging current</p>
            <p className="txt-02">17 A</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Charging time</p>
            <p className="txt-02">2 hour, 10% to 90% </p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Charging current (cable charge)</p>
            <p className="txt-02">17 A</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Charging time (cable Charge)</p>
            <p className="txt-02">
              2 hours, 10% to 90%
              <br />
              (17A charger)
            </p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Active operation time between charging</p>
            <p className="txt-02">8 hours</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Full charging cycles </p>
            <p className="txt-02">1000</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Voltage</p>
            <p className="txt-02">24 V</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Capacity</p>
            <p className="txt-02">1 kWh (40.8 Ah at 25.2 V)</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Battery type </p>
            <p className="txt-02">Li-ion, 25.2 V, 40.8 Ah</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Weight</p>
            <p className="txt-02">6.8 kg | 15 lbs</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Standby time</p>
            <p className="txt-02">10 hours</p>
          </li>
        </ul> */}
      </div>

      <div className="ThiraProductDetail__cont">
        {/* <h3
          className="ThiraProductDetail__subtitle"
          onClick={e => {
            ProductJs.toggleArrow(e.target.closest('.ThiraProductDetail__subtitle'));
          }}
        >
          Environment <button className="arr">아래 화살표</button>
        </h3>
        <ul className="ThiraProductDetail__list">
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Ambient temperature (operation)</p>
            <p className="txt-02">+5°C to 40°C</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Ambient temperature (storage)</p>
            <p className="txt-02">-10°C to 60°C</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Humidity</p>
            <p className="txt-02">10-95% non-condensing</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Environment</p>
            <p className="txt-02">For indoor use only</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">IP Class</p>
            <p className="txt-02">IP21</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Floor conditions</p>
            <p className="txt-02">Capabilty to drive on rough, water, oil spil, offset </p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Compliance</p>
            <p className="txt-02">CE, FCC approval pending</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Elevator boarding standards</p>
            <p className="txt-02">KS B 7317 (Korean Standard)</p>
          </li>
        </ul> */}
      </div>

      <div className="ThiraProductDetail__cont">
        {/* <h3
          className="ThiraProductDetail__subtitle"
          onClick={e => {
            ProductJs.toggleArrow(e.target.closest('.ThiraProductDetail__subtitle'));
          }}
        >
          Safety <button className="arr">아래 화살표</button>
        </h3>
        <ul className="ThiraProductDetail__list">
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Triple Safety System</p>
            <p className="txt-02">Provides robust protection with three layers of safety: first from the Lidar, second from the camera, and third from the bumper system.</p>
          </li>
        </ul> */}
      </div>

      <div className="ThiraProductDetail__cont">
        {/* <h3
          className="ThiraProductDetail__subtitle"
          onClick={e => {
            ProductJs.toggleArrow(e.target.closest('.ThiraProductDetail__subtitle'));
          }}
        >
          Communication <button className="arr">아래 화살표</button>
        </h3>
        <ul className="ThiraProductDetail__list">
          <li className="ThiraProductDetail__item">
            <p className="txt-01">I/O connections</p>
            <p className="txt-02">2 digital inputs, 2 digital outputs, 1 Ethernet port, 1 USB (A type, ver 3.0), 1 HDMI, 1 relay output</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">WiFi</p>
            <p className="txt-02">
              Router: 2.4 GHz 802.11 g/n, 5 GHz 802.11 a/n/ac. <br />
              <br />
              Moxa module (industrial standard): 802.11 a/b/g/n/ac.
            </p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">WiFi connection</p>
            <p className="txt-02">Router: 2.4 GHz and 5 GHz. WiFi adapter: 2.4 GHz and 5 GHz, 2 internal antennas.</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Power for top modules</p>
            <p className="txt-02">PP45 con. 24V / 40A BAT pwr, JST con. 12V / 3A LCD pwr, JST con. 24V / 3A </p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Communication protocol</p>
            <p className="txt-02">REST, ROS 1, MQTT</p>
          </li>
        </ul> */}
      </div>

      <div className="ThiraProductDetail__cont">
        {/* <h3
          className="ThiraProductDetail__subtitle"
          onClick={e => {
            ProductJs.toggleArrow(e.target.closest('.ThiraProductDetail__subtitle'));
          }}
        >
          Sensors <button className="arr">아래 화살표</button>
        </h3>
        <ul className="ThiraProductDetail__list">
          <li className="ThiraProductDetail__item">
            <p className="txt-01"> Safety Laser Scanner</p>
            <p className="txt-02">
              2pcs, SICK nanoScan3.
              <br />
              360° visual protection around robot
            </p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">3D camera</p>
            <p className="txt-02">
              2 psc.: Intel RealSense D435. <br />
              <br />
              FoV ( 87° (H) x 58° (V): Detects objects 616 mm high at a distance of 950 mm in front of the robot. <br />
              <br />
              87° total horizontal view. <br />
              <br />
              Ground view, minimum distance from robot: 350 mm
            </p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Proximity sensors</p>
            <p className="txt-02">Option </p>
          </li>
        </ul> */}
      </div>

      <div className="ThiraProductDetail__cont">
        {/* <h3
          className="ThiraProductDetail__subtitle"
          onClick={e => {
            ProductJs.toggleArrow(e.target.closest('.ThiraProductDetail__subtitle'));
          }}
        >
          Lights and Audio <button className="arr">아래 화살표</button>
        </h3>
        <ul className="ThiraProductDetail__list">
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Audio</p>
            <p className="txt-02">Melody Buzzer</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">Signal lights</p>
            <p className="txt-02">Indicator lights on two sides</p>
          </li>
        </ul> */}
      </div>
    </React.Fragment>
  );
}

export default T300ConveyorB;
