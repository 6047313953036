import {Link, useNavigate} from 'react-router-dom';
import {useIntl} from 'react-intl'; // 다국어
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';
function ThiraSolutionsLocation(props) {
  const intl = useIntl();
  const LANG = intl.messages.SOLUTION;
  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어
  // 다국어 세팅
  const {t} = useTranslation('solutions');

  return (
    <div className="ThiraLocation">
      <Link className="ThiraLocation__btn" to={`/${lang}`}>
        {t('navigation.home')}
      </Link>
      <span className="icon"></span>
      <Link className="ThiraLocation__btn" to={`/${lang}/solutions/AntiSlip`}>
        {t('navigation.solution')}
      </Link>
      <span className="icon"></span>
      <select
        name="sel_page"
        className="ThiraLocation__sel"
        defaultValue={props.default}
        onChange={e => {
          const sel_val = e.target.closest('select').value;
          switch (sel_val) {
            case 'AntiSlip':
              window.location.href = 'AntiSlip';
              break;
            case 'Uneven surface driving':
              window.location.href = 'Obstacle';
              break;
            case 'Overcoming steps, Taking elevators':
              window.location.href = 'Elevator';
              break;
            case 'Logistics automation':
              window.location.href = 'Logistics';
              break;
            case 'Following function':
              window.location.href = 'Following';
              break;
            case 'Triple Safety System':
              window.location.href = 'Safety';
              break;
            default:
              window.location.href = 'AntiSlip';
              break;
          }
        }}
      >
        <option value="AntiSlip">{t('navigation.optionList.0.name')}</option>
        <option value="Uneven surface driving">{t('navigation.optionList.1.name')}</option>
        <option value="Overcoming steps, Taking elevators">{t('navigation.optionList.2.name')}</option>
        <option value="Logistics automation">{t('navigation.optionList.3.name')}</option>
        <option value="Following function">{t('navigation.optionList.4.name')}</option>
        <option value="Triple Safety System">{t('navigation.optionList.5.name')}</option>
      </select>
    </div>
  );
}

export default ThiraSolutionsLocation;
