import React from 'react';
import {Link} from 'react-router-dom';
import {useState, useEffect} from 'react';
import axios from 'axios';
import i18next from '../../i18n';

function ThiraTopBanner({onClose}) {
  const defaultLang = localStorage.getItem('lang');

  const [apiData, setApiData] = useState(null);
  const board_id = 6;
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/board/${board_id}/top_banner?lang=${i18next.language}`)
      .then(response => {
        setApiData(response.data);
      })
      .catch(response => {
        console.log('top_banner Error!');
      });
  }, []);
  //   테스트데이터
  // const stripTestKr = [{title: '7월 독일 슈트트가르트 전시시작!!', link: 'https://www.thirarobotics.com/about/OurStory'}];
  // const stripTest = [{title: 'July Start of exhibition in Stuttgart, Germany!!', link: 'https://www.thirarobotics.com/solutions/AntiSlip'}];
  return (
    apiData && 
    <React.Fragment>
      <aside className={`ThiraStripBanner ${onClose ? '' : 'closed'}`}>
        {apiData.link 
          ? <Link className="ThiraStripBanner__title" to={apiData.link} target="_blank" rel="noopener noreferrer">
              {apiData.title}
            </Link>
          : apiData.title
        }
        <button className="ThiraStripBanner__close" onClick={onClose}>
          닫기
        </button>
      </aside>
    </React.Fragment>
  );
}

export default ThiraTopBanner;
