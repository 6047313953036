import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import i18n from './i18n'; // i18n 초기화 파일
import {ThiraHeader, ThiraFooter, ThiraFloat, ThiraTopBanner} from './component/common/index';
import ThiraPopup from './component/common/popup';
import ThiraCookie from './component/common/cookie';
import ScrollTop from './util/ScrollTop';
import AOS from 'aos';
import ThiraRouter from './router/index';
import 'swiper/css';
import 'aos/dist/aos.css';
import './assets/css/_common.scss';
import {IntlProvider} from 'react-intl'; // 다국어
import en from './locale/enus.json'; // 영어
import ko from './locale/ko.json'; // 한국어

function App() {
  useEffect(() => {
    AOS.init({
      once: false,
      duration: 500,
    });
  });

  const today = (() => {
    const today = new Date();

    const year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2);
    const day = ('0' + today.getDate()).slice(-2);

    const dateString = year + '-' + month + '-' + day;
    return dateString;
  })();
  const tbc = localStorage.getItem('top_banner_close');
  const [isClosed, setIsClosed] = useState(tbc && today <= tbc);

  const handleCloseBanner = () => {
    setIsClosed(true);
    localStorage.setItem('top_banner_close', today);
  };

  // 언어 관련 설정
  const defaultLang = localStorage.getItem('lang') || 'en';
  let messages;
  if (defaultLang === 'kor') {
    messages = ko;
  } else {
    messages = en;
  }
  // console.log(en);
  console.log(defaultLang);
  // i18n.changeLanguage(defaultLang);

  return (
    <IntlProvider locale={defaultLang} messages={messages}>
      <BrowserRouter>
        <ScrollTop />
        <div className="App">
          {/* 탑 띠 배너 */}
          {!isClosed && <ThiraTopBanner onClose={handleCloseBanner}></ThiraTopBanner>}
          {/* 팝업 */}
          <ThiraPopup></ThiraPopup>
          {/* 헤더 */}
          <ThiraHeader showBanner={!isClosed}></ThiraHeader>

          {/* 콘텐츠 */}
          <main className="t-content">
            <ThiraRouter></ThiraRouter>
            {/* 플로트 버튼 */}
            <ThiraFloat></ThiraFloat>
          </main>

          {/* 푸터 */}
          <ThiraFooter></ThiraFooter>
          {/* 쿠키정책 */}
          {/* <ThiraCookie></ThiraCookie> */}
        </div>
      </BrowserRouter>
    </IntlProvider>
  );
}

export default App;
