// import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";

function ThiraNotFound() {
  return (
    <div className="ThiraNotFound">
      <div className="inner-width">페이지를 찾을 수 없습니다.</div>
    </div>
  );
}

export default ThiraNotFound;
