import {Outlet} from 'react-router-dom';
import '../../assets/css/services.scss';

function ThiraService() {
  return (
    <div className="ThiraService">
      <Outlet />
    </div>
  );
}

export default ThiraService;
