import XIconSvg from '../../assets/images/main/x_icon.svg';
import CheckIconGraySvg from '../../assets/images/main/check_icon_gray.svg';
import CheckIconWhiteSvg from '../../assets/images/main/check_icon_white.svg';
import {useTranslation, Trans} from 'react-i18next';
import i18next from '../../i18n';

function ThiraTable() {
  // 기본 언어
  const defaultLang = localStorage.getItem('lang');

  // 다국어 세팅
  const {t} = useTranslation('about');
  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어

  return (
    <div className="ThiraTable" data-aos="zoom-in">
      {/* thead */}
      <div className="thead th"></div>
      <div className="thead">A</div>
      <div className="thead">B</div>
      <div className="thead">C</div>
      <div className="thead">
        THIRA
        <br className="br-mo" /> ROBOTICS
      </div>
      {/* line-1 */}
      <div className="th">
        {/* 
          텍스트 위치 : PARTNER > grid > table > 첫번째 객체 > title
          페이지 내 위치 : 회사소개  > 파트너소개 > 티라 선택 이유 테이블 첫번째 내용
        */}
        {/* json 데이터에 br태그가 들어있어서 변환 작업을 진행 */}
        <Trans i18nKey={t('PARTNER.grid.table.0.title')} components={{br: <br />}} />
      </div>
      <div className="tbody">
        <figure>
          <img src={CheckIconGraySvg} alt="check" />
        </figure>
      </div>
      <div className="tbody">
        <figure>
          <img src={CheckIconGraySvg} alt="check" />
        </figure>
      </div>
      <div className="tbody">
        <figure>
          <img src={CheckIconGraySvg} alt="check" />
        </figure>
      </div>
      <div className="tbody gray-bg">
        <figure>
          <img src={CheckIconWhiteSvg} alt="check" />
        </figure>
      </div>
      {/* line-2 */}
      <div className="th">
        {/* 
          텍스트 위치 : PARTNER > grid > table > 두번째 객체 > title
          페이지 내 위치 : 회사소개  > 파트너소개 > 티라 선택 이유 테이블 두번째 내용
        */}
        <Trans i18nKey={t('PARTNER.grid.table.1.title')} components={{br: <br />}} />
      </div>
      <div className="tbody">
        <figure>
          <img src={XIconSvg} alt="uncheck" />
        </figure>
      </div>
      <div className="tbody">
        <figure>
          <img src={XIconSvg} alt="uncheck" />
        </figure>
      </div>
      <div className="tbody">
        <figure>
          <img src={XIconSvg} alt="uncheck" />
        </figure>
      </div>
      <div className="tbody gray-bg">
        <figure>
          <img src={CheckIconWhiteSvg} alt="check" />
        </figure>
      </div>
      {/* line-3 */}
      <div className="th">
        {/* 
          텍스트 위치 : PARTNER > grid > table > 세번째 객체 > title
          페이지 내 위치 : 회사소개  > 파트너소개 > 티라 선택 이유 테이블 세번째 내용
        */}
        <Trans i18nKey={t('PARTNER.grid.table.2.title')} components={{br: <br />}} />
      </div>
      <div className="tbody">
        <figure>
          <img src={CheckIconGraySvg} alt="check" />
        </figure>
      </div>
      <div className="tbody">
        <figure>
          <img src={CheckIconGraySvg} alt="check" />
        </figure>
      </div>
      <div className="tbody">
        <figure>
          <img src={XIconSvg} alt="uncheck" />
        </figure>
      </div>
      <div className="tbody gray-bg">
        <figure>
          <img src={CheckIconWhiteSvg} alt="check" />
        </figure>
      </div>
      {/* line-4 */}
      <div className="th">
        {/* 
          텍스트 위치 : PARTNER > grid > table > 네번째 객체 > title
          페이지 내 위치 : 회사소개  > 파트너소개 > 티라 선택 이유 테이블 네번째 내용
        */}
        <Trans i18nKey={t('PARTNER.grid.table.3.title')} components={{br: <br />}} />
      </div>
      <div className="tbody">
        <figure>
          <img src={CheckIconGraySvg} alt="check" />
        </figure>
      </div>
      <div className="tbody">
        <figure>
          <img src={CheckIconGraySvg} alt="check" />
        </figure>
      </div>
      <div className="tbody">
        <figure>
          <img src={CheckIconGraySvg} alt="check" />
        </figure>
      </div>
      <div className="tbody gray-bg">
        <figure>
          <img src={CheckIconWhiteSvg} alt="check" />
        </figure>
      </div>
      {/* line-5 */}
      <div className="th">
        {/* 
          텍스트 위치 : PARTNER > grid > table > 다섯번째 객체 > title
          페이지 내 위치 : 회사소개  > 파트너소개 > 티라 선택 이유 테이블 다섯번째 내용
        */}
        <Trans i18nKey={t('PARTNER.grid.table.4.title')} components={{br: <br />}} />
      </div>
      <div className="tbody">
        <figure>
          <img src={CheckIconGraySvg} alt="check" />
        </figure>
      </div>
      <div className="tbody">
        <figure>
          <img src={XIconSvg} alt="uncheck" />
        </figure>
      </div>
      <div className="tbody">
        <figure>
          <img src={XIconSvg} alt="uncheck" />
        </figure>
      </div>
      <div className="tbody gray-bg">
        <figure>
          <img src={CheckIconWhiteSvg} alt="check" />
        </figure>
      </div>
      {/* line-6 */}
      <div className="th">
        {/* 
          텍스트 위치 : PARTNER > grid > table > 여섯번째 객체 > title
          페이지 내 위치 : 회사소개  > 파트너소개 > 티라 선택 이유 테이블 여섯번째 내용
        */}
        <Trans i18nKey={t('PARTNER.grid.table.5.title')} components={{br: <br />}} />
      </div>
      <div className="tbody">High</div>
      <div className="tbody">High</div>
      <div className="tbody">High</div>
      <div className="tbody gray-bg">High</div>
      {/* line-7 */}
      <div className="th">
        {/* 
          텍스트 위치 : PARTNER > grid > table > 일곱번째 객체 > title
          페이지 내 위치 : 회사소개  > 파트너소개 > 티라 선택 이유 테이블 일곱번째 내용
        */}
        <Trans i18nKey={t('PARTNER.grid.table.6.title')} components={{br: <br />}} />
      </div>
      <div className="tbody">High</div>
      <div className="tbody">Medium</div>
      <div className="tbody">Low</div>
      <div className="tbody gray-bg">High</div>
      {/* line-8 */}
      <div className="th">
        {/* 
          텍스트 위치 : PARTNER > grid > table > 여덟번째 객체 > title
          페이지 내 위치 : 회사소개  > 파트너소개 > 티라 선택 이유 테이블 여덟번째 내용
        */}
        <Trans i18nKey={t('PARTNER.grid.table.7.title')} components={{br: <br />}} />
      </div>
      <div className="tbody">High</div>
      <div className="tbody">High</div>
      <div className="tbody">Medium</div>
      <div className="tbody gray-bg">High</div>
    </div>
  );
}

export default ThiraTable;
