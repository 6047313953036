import { useNavigate } from 'react-router-dom';
// img
import ArrowRightSvg from '../../assets/images/common/ico_arrow_right_white.svg';

function ThiraPagination(props) {
  const navigate = useNavigate(); // 페이지 이동
  const { paging, paramsRef } = props

  const handlePaging = (page) => {
    if (page == paging.cp) return
    paramsRef.current.set('page', page)
    navigate('?' + paramsRef.current.toString())
  }
  return (
    <div className="ThiraPagination">
      {/* DEV :: 클릭시 button에 active 클래스 추가 + 나중에 prev-button 활성화 */}
      {paging?.cb > 1 &&
        <button type="button" onClick={() => handlePaging(paging?.prv_page)} className="prev-button">
          <img src={ArrowRightSvg} alt="이전" />
        </button>
      }
      <div className="ThiraPagination__button-wrap">
        {paging?.page.map((p) =>
          <button onClick={() => handlePaging(p)} key={`paing_${p}`} type="button" className={p === paging.cp ? 'active' : ''} >{p}</button>
        )}
      </div>
      {paging?.cb < paging?.tb &&
        <button onClick={() => handlePaging(paging?.nxt_page)} type="button">
          <img src={ArrowRightSvg} alt="다음" />
        </button>
      }
    </div>
  );
}

export default ThiraPagination;
