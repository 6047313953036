import {useEffect, useState} from 'react';
import {NavLink, Link, useNavigate, useLocation, useParams} from 'react-router-dom';
import {ThiraTitle} from '../../component/common/index';
import {business, country} from '../common/SelectValue';
import axios from 'axios';
import {useIntl} from 'react-intl'; // 다국어
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';
import {animateScroll as scroll} from 'react-scroll'; // 네비 스크롤 위해서 추가
// IMG
import T200 from '../../assets/images/product/Tseries/T200/T-200_01.png';
import T300 from '../../assets/images/product/Tseries/T300/T-300_01.png';
import T600 from '../../assets/images/product/Tseries/T600/T-600_01.png';
import T1000 from '../../assets/images/product/Tseries/T1000/T-1000_01.png';
import T1000F from '../../assets/images/product/Tseries/T1000F/T-1000F_01.png';
import L200 from '../../assets/images/product/Lseries/L200/L-200_01.png';
import Station from '../../assets/images/product/station/station_01.png';
import T300_Arm from '../../assets/images/product/Acc/T300_Arm/T-300_Robotarm_01.png';
import T300_Conveyor_A from '../../assets/images/product/Acc/T300_Conveyor_A/T-300_ConveyorA_01.png';
import T300_Conveyor_B from '../../assets/images/product/Acc/T300_Conveyor_B/T-300_ConveyorB_01.png';
import T300_Lift from '../../assets/images/product/Acc/T300_Lift/T-300_Liftstandard_01.png';
import T1000_Conveyor from '../../assets/images/product/Acc/T1000_Conveyor/T-1000_Conveyor_01.png';
import L200_Lidar from '../../assets/images/product/Acc/L200_Lidar/L-200_Lidar_01.png';
import InquiryBgImg from '../../assets/images/product/inquiry_bg.png';

function ThiraProductModels() {
  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어
  // 다국어 세팅
  const {t} = useTranslation('product');

  const navigate = useNavigate(); //페이지 이동
  const params = useParams();

  function showThumbs() {
    document.querySelectorAll('.ThiraProductList').forEach(el => {
      el.querySelectorAll('.ThiraProductItem').forEach((el, index) => {
        setTimeout(() => {
          el.classList.add('active');
        }, 200 * index);
      });
    });
  }

  // const [active, setActive] = useState(0);

  const location = useLocation();

  // 누르고 들어온 제품 상세 위치에 따라 스크롤 하기 위해서 추가
  const scrollToSection = sectionId => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      scroll.scrollTo(sectionElement.offsetTop - 300, {
        duration: 200,
        smooth: true,
      });
    }
  };

  useEffect(() => {
    showThumbs();

    const $models = document.querySelectorAll('.ThiraProductModels .ThiraTitle');
    // 누르고 들어온 제품 상세 위치에 따라 스크롤 하기 위해서 추가
    const sectionValue = location.pathname.split('/').pop();
    console.log(sectionValue); // Section01
    scrollToSection(sectionValue);
  }, [location.pathname]);

  // select 박스 border디자인 분기처리
  const [countrySelected, setCountrySelected] = useState(false);
  const [businessSelected, setBusinessSelected] = useState(false);
  const [frData, setFrData] = useState({
    fullname: '',
    email: '',
    country: country[0],
    company: '',
    business: business[0],
    phone: '',
    inquiry: '',
    newsletter: false,
    privacyPolicy: false,
    advertising: false,
  });
  const handleChange = e => {
    setFrData({
      ...frData,
      [e.target.name]: ['privacyPolicy', 'advertising'].includes(e.target.name) ? e.target.checked : e.target.value,
    });
  };
  const handleSubmit = e => {
    e.preventDefault();
    console.log('전송할데이터', JSON.stringify(frData));
    axios
      .post(`${process.env.REACT_APP_API}/mail`, frData, {
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then(response => {
        console.log('mail result', response.data);
        navigate(`/inquiry/complete`);
      })
      .catch(response => {
        console.log('Error!', response);
      });
  };
  return (
    <div className="ThiraProductModels">
      <div className="inner-width">
        <div className="ThiraLocation">
          <Link className="ThiraLocation__btn" to={`/${lang}`}>
            {t('navigation.home')}
          </Link>
          <span className="icon"></span>
          <Link className="ThiraLocation__btn" to={`/${lang}/product/models`}>
            {t('navigation.desc')}
          </Link>
          <span className="icon"></span>
          <Link className="ThiraLocation__btn" to={`/${lang}/product/models`}>
            {t('navigation.model')}
          </Link>
        </div>
        {/* T Series */}
        <ThiraTitle title={t('section.0.title')}></ThiraTitle>
        <div className="ThiraProductList" id="T-Series">
          <NavLink to={`/${lang}/product/detail/T200`} className="ThiraProductItem">
            <div className="ThiraProductItem__thum">
              <img src={T200} alt="T200 썸네일" />
            </div>
            <p className="ThiraProductItem__name">T200</p>
          </NavLink>
          <NavLink to={`/${lang}/product/detail/T300`} className="ThiraProductItem">
            <div className="ThiraProductItem__thum">
              <img src={T300} alt="T300 썸네일" />
            </div>
            <p className="ThiraProductItem__name">T300</p>
          </NavLink>
          <NavLink to={`/${lang}/product/detail/T600`} className="ThiraProductItem /*coming_soon*/">
            <div className="ThiraProductItem__thum">
              <img src={T600} alt="T600 썸네일" />
            </div>
            <p className="ThiraProductItem__name">T600</p>
          </NavLink>
          <NavLink to={`/${lang}/product/detail/T1000`} className="ThiraProductItem">
            <div className="ThiraProductItem__thum">
              <img src={T1000} alt="T1000 썸네일" />
            </div>
            <p className="ThiraProductItem__name">T1000</p>
          </NavLink>
          <NavLink to={`/${lang}/product/detail/T1000F`} className="ThiraProductItem">
            <div className="ThiraProductItem__thum">
              <img src={T1000F} alt="T1000F 썸네일" />
            </div>
            <p className="ThiraProductItem__name f-icon">T1000</p>
          </NavLink>
        </div>
        {/* L Series */}
        <ThiraTitle title={t('section.1.title')}></ThiraTitle>
        <div className="ThiraProductList" id="L-Series">
          <NavLink to={`/${lang}/product/detail/L200`} className="ThiraProductItem">
            <div className="ThiraProductItem__thum">
              <img src={L200} alt="L200 썸네일" />
            </div>
            <p className="ThiraProductItem__name">L200</p>
          </NavLink>
        </div>
        {/* Charging system */}
        <ThiraTitle title={t('section.2.title')}></ThiraTitle>
        <div className="ThiraProductList" id="chargingSystem">
          <NavLink to={`/${lang}/product/detail/chargingSystem`} className="ThiraProductItem">
            <div className="ThiraProductItem__thum">
              <img src={Station} alt="station 썸네일" />
            </div>
            <p className="ThiraProductItem__name">Charging system</p>
          </NavLink>
        </div>
        {/* Accessories */}
        <ThiraTitle title={t('section.3.title')}></ThiraTitle>
        <div className="ThiraProductList" id="Acc">
          <NavLink to={`/${lang}/product/detail/T300_arm`} className="ThiraProductItem">
            <div className="ThiraProductItem__thum">
              <img src={T300_Arm} alt=" T300 Arm 썸네일" />
            </div>
            <p className="ThiraProductItem__name">T300 Arm</p>
          </NavLink>
          <NavLink to={`/${lang}/product/detail/T300_conveyor_A`} className="ThiraProductItem">
            <div className="ThiraProductItem__thum">
              <img src={T300_Conveyor_A} alt="T300 Conveyor A 썸네일" />
            </div>
            <p className="ThiraProductItem__name">T300 Conveyor A</p>
          </NavLink>
          <NavLink to={`/${lang}/product/detail/T300_conveyor_B`} className="ThiraProductItem">
            <div className="ThiraProductItem__thum">
              <img src={T300_Conveyor_B} alt="T300 Conveyor B 썸네일" />
            </div>
            <p className="ThiraProductItem__name">T300 Conveyor B</p>
          </NavLink>

          <NavLink to={`/${lang}/product/detail/T300_lift`} className="ThiraProductItem">
            <div className="ThiraProductItem__thum">
              <img src={T300_Lift} alt="T300 Lift 썸네일" />
            </div>
            <p className="ThiraProductItem__name">T300 Lift</p>
          </NavLink>
          <NavLink to={`/${lang}/product/detail/T1000_conveyor`} className="ThiraProductItem">
            <div className="ThiraProductItem__thum">
              <img src={T1000_Conveyor} alt=" T1000 Conveyor" />
            </div>
            <p className="ThiraProductItem__name">T1000 Conveyor</p>
          </NavLink>
          <NavLink to={`/${lang}/product/detail/L200_lidar`} className="ThiraProductItem">
            <div className="ThiraProductItem__thum">
              <img src={L200_Lidar} alt="L200 Lidar 썸네일" />
            </div>
            <p className="ThiraProductItem__name">L200 Lidar</p>
          </NavLink>
        </div>
      </div>
      {/* Input */}
      <section className="ThiraInquiry">
        <div className="bg-img-wrap">
          <img src={InquiryBgImg} alt="배경" />
        </div>
        <div className="inner-width">
          <div className="ThiraInquiry__text-container" data-aos="fade-left">
            <ThiraTitle title={t('inquiry.form.title')} />
            <p className="sub-title">{t('inquiry.form.subTitle')}</p>
          </div>
          <fieldset className="ThiraInquiry__form-container">
            <legend className="blind">inquiry Form</legend>
            <form onSubmit={handleSubmit}>
              {/* COMPANY */}
              <div className="input-wrap">
                <label htmlFor="user-company">
                  {t('inquiry.form.formData.Company')}
                  <span className="required">*</span>
                </label>
                <input id="user-company" type="text" placeholder={t('inquiry.form.formData.Company')} value={frData.company} onChange={handleChange} name="company" required />
                {/* <p className="error-msg">This field is required.</p> */}
              </div>
              {/* FULL NAME */}
              <div className="input-wrap">
                <label htmlFor="user-name">
                  {t('inquiry.form.formData.Full_Name')}
                  <span className="required">*</span>
                </label>
                <input id="user-name" type="text" placeholder={t('inquiry.form.formData.Full_Name')} value={frData.fullname} onChange={handleChange} name="fullname" required />
                {/* <p className="error-msg">This field is required.</p> */}
              </div>
              {/* EMAIL */}
              <div className="input-wrap">
                <label htmlFor="user-email">
                  {t('inquiry.form.formData.Email')}
                  <span className="required">*</span>
                </label>
                <input id="user-email" type="email" placeholder={t('inquiry.form.formData.Email')} value={frData.email} onChange={handleChange} name="email" required />
                {/* <p className="error-msg">This field is required.</p> */}
              </div>
              {/* PHONE NUMBER */}
              <div className="input-wrap">
                <label htmlFor="user-number">
                  {t('inquiry.form.formData.Phone_Number')}
                  <span className="input-option">&#40;{t('inquiry.form.option')}&#41;</span>
                </label>
                <input id="user-number" type="text" value={frData.phone} onChange={handleChange} name="phone" placeholder={t('inquiry.form.formData.Phone_Number')} />
              </div>
              {/* BUSINESS */}
              <div className="input-wrap">
                <label htmlFor="user-business">
                  {t('inquiry.form.formData.Business')}
                  {/* <span className="required">*</span> */}
                </label>
                <select id="user-business" value={frData.business} onChange={handleChange} name="business" required>
                  {business.map(v => (
                    <option value={v} key={`business_${v}`}>
                      {v}
                    </option>
                  ))}
                </select>
                {/* <p className="error-msg">This field is required.</p> */}
              </div>
              {/* COUNTRY */}
              <div className="input-wrap">
                <label htmlFor="user-country">{t('inquiry.form.formData.Country')}</label>
                <input id="user-country" type="text" placeholder={t('inquiry.form.formData.Country')} value={frData.country} onChange={handleChange} name="country" />
                {/* <select id="user-country" value={frData.country} onChange={handleChange} name="country" required>
                {country.map(v => (
                  <option value={v} key={`country_${v}`}>
                    {v}
                  </option>
                ))}
              </select> */}
                {/* <p className="error-msg">This field is required.</p> */}
              </div>
              {/* INQUIRY */}
              <div className="input-wrap">
                <label htmlFor="user-inquiry">
                  {t('inquiry.form.formData.Inquiry')}
                  <span className="input-option">&#40;{t('inquiry.form.option')}&#41;</span>
                </label>
                <textarea name="inquiry" id="user-inquiry" placeholder={t('inquiry.form.formData.Inquiry')} value={frData.inquiry} onChange={handleChange}></textarea>
              </div>
              <div className="input-wrap ThiraInquiry__check-input">
                <input id="policy" type="checkbox" name="privacyPolicy" checked={frData.privacyPolicy} onChange={handleChange} required />
                <label htmlFor="policy">
                  <span className="check-img"></span>
                  {t('inquiry.form.terms.0')}
                </label>
                <input id="policy2" type="checkbox" name="advertising" checked={frData.advertising} onChange={handleChange} required />
                <label htmlFor="policy2">
                  <span className="check-img"></span>
                  {t('inquiry.form.terms.1')}
                </label>
                {/* 위 두 checkbox의 display none이라 required를 추가하면 크롬에서 An invalid form control with name='privacyPolicy' is not focusable.라는 경고가 뜹니다, 단 필수항목미선택 시 달리 안내해주는 정책이없어서 어쩔수없이 추가해놓았습니다. */}
              </div>
              {/* <div className="input-wrap ThiraInquiry__check-input">
                <input id="agree" type="checkbox" name="newsletter" checked={frData.newsletter} onChange={handleChange} />
                <label htmlFor="agree">
                  <span className="check-img"></span> {defaultLang === 'kor' ? '티라로보틱스로부터 최신 뉴스레터를 받겠습니다.' : 'I will receive the latest newsletter from T-Robotics.'}
                </label>
              </div>
              <p className="terms-text">I have read, understand and agree with THIRA Privacy Policy and Terms of Use.</p>
               */}
              <button className="submit-button" type="submit">
                {t('inquiry.form.submit')}
              </button>
            </form>
          </fieldset>
        </div>
      </section>
    </div>
  );
}

export default ThiraProductModels;
