import React, {useEffect, useState} from 'react';
import * as ProductJs from '../../assets/js/product';
import ThiraProductLocation from '../../component/product/location';
import ThumbNailModal from '../modal/ThumbNailModal';
import Modal from '../../util/Modal';
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';

// IMG
import T1000F_1 from '../../assets/images/product/Tseries/T1000F/T-1000F_01.png';
import T1000F_2 from '../../assets/images/product/Tseries/T1000F/T-1000F_02.png';
import T1000F_3 from '../../assets/images/product/Tseries/T1000F/T-1000F_03.png';
import T1000F_4 from '../../assets/images/product/Tseries/T1000F/T-1000F_04.png';
import T1000F_5 from '../../assets/images/product/Tseries/T1000F/T-1000F_05.png';
import T1000F_6 from '../../assets/images/product/Tseries/T1000F/T-1000F_06.png';
import T1000_7 from '../../assets/images/product/Tseries/T1000F/T-1000F_07.png';
import T1000_8 from '../../assets/images/product/Tseries/T1000F/T-1000F_08.png';
import T1000_9 from '../../assets/images/product/Tseries/T1000F/T-1000F_09.png';
import T1000_10 from '../../assets/images/product/Tseries/T1000F/T-1000F_10.png';
import DetailPng from '../../assets/images/product/Tseries/T1000F/T-1000F_detail.png';
import DetailMobPng from '../../assets/images/product/Tseries/T1000F/T-1000F_detail_mob.png';
import Video from '../../assets/images/product/Tseries/T1000F/T1000F_video.mp4';
import LineWrap from '../common/lineWrap';

function ThiraProductT1000F(props) {
  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어
  // 다국어 세팅
  const {t} = useTranslation('product');

  useEffect(() => {
    ProductJs.showTitle();
    ProductJs.showThumbs();
  });

  // 팝업 열기
  const [isActiveModal, setIsActiveModal] = useState(false);
  const onClickModalOpen = () => {
    setIsActiveModal(true);
    Modal.modalOpen();
  };

  const ContentImgList = [
    {img: T1000F_1, name: 'T1000F_1'},
    {img: T1000F_2, name: 'T1000F_2'},
    {img: T1000F_3, name: 'T1000F_3'},
    {img: T1000F_4, name: 'T1000F_4'},
    {img: T1000F_5, name: 'T1000F_5'},
    {img: T1000F_6, name: 'T1000F_6'},
    {img: T1000_7, name: 'T1000_7'},
    {img: T1000_8, name: 'T1000_8'},
    {img: T1000_9, name: 'T1000_9'},
    {img: T1000_10, name: 'T1000_10'},
  ];

  const ModalData = {
    isState: {
      state: isActiveModal,
      setState: setIsActiveModal,
    },
    SlideContent: ContentImgList,
  };

  return (
    <React.Fragment>
      <ThumbNailModal data={ModalData} />
      <ThiraProductLocation default="T1000F"></ThiraProductLocation>

      {/* PC */}
      <div className="ThiraProductDetail__top pc-flex">
        <div className="ThiraProductDetail__bigThum">
          <img src={T1000F_1} alt="썸네일" />
        </div>
        <div className="ThiraProductDetail__topInf">
          {/* <h2 className="ThiraProductDetail__title f-icon">{props.name}</h2> */}
          <h2 className="ThiraProductDetail__title">{props.name}</h2>
          <p className="ThiraProductDetail__desc">{t('t1000F.productDetail.desc')}</p>

          <div className="ThiraProductDetail__smallThum">
            {ContentImgList?.filter((item, index) => index < 4).map((item, index) => {
              return (
                <button
                  key={'contentImg' + index}
                  data-name={item.name}
                  onClick={e => {
                    ProductJs.showPopup(e.target.closest('button'));
                  }}
                  className={index === 0 ? 'border' : undefined}
                >
                  <img src={item.img} alt={item.name} />
                </button>
              );
            })}
            {/* 요소 5개 초과/이하 분기처리 -> 이상일 시 모달 연결 */}
            {ContentImgList?.filter((item, index) => index === 4).map((item, index) => {
              return (
                <React.Fragment>
                  {ContentImgList?.length > 5 ? (
                    <button className="more" key={'contentImg' + index} data-name={item.name} onClick={onClickModalOpen}>
                      <p className="more__txt">+{ContentImgList.length - 5}</p>
                      <img src={item.img} alt={item.name} />
                    </button>
                  ) : (
                    <button
                      key={'contentImg' + index}
                      data-name={item.name}
                      onClick={e => {
                        ProductJs.showPopup(e.target.closest('button'));
                      }}
                    >
                      <img src={item.img} alt={item.name} />
                    </button>
                  )}
                </React.Fragment>
              );
            })}
          </div>
          {/* <button
            className="ThiraProductDetail__btn"
            onClick={e => {
              ProductJs.viewDetail(e.target.closest('button'));
            }}
          >
            View Detail Page
          </button> */}
        </div>
      </div>

      {/* MO */}
      <div className="ThiraProductDetail__top mo-block">
        <h2 className="ThiraProductDetail__title">{props.name}</h2>
        <div className="ThiraProductDetail__bigThum">
          <img src={T1000F_1} alt="썸네일" />
        </div>
        <div className="ThiraProductDetail__smallThum">
          {ContentImgList?.filter((item, index) => index < 4).map((item, index) => {
            return (
              <button
                key={'contentImg' + index}
                data-name={item.name}
                onClick={e => {
                  ProductJs.showPopup(e.target.closest('button'));
                }}
                className={index === 0 ? 'border' : undefined}
              >
                <img src={item.img} alt={item.name} />
              </button>
            );
          })}
          {/* 요소 5개 초과/이하 분기처리 -> 이상일 시 모달 연결 */}
          {ContentImgList?.filter((item, index) => index === 4).map((item, index) => {
            return (
              <React.Fragment>
                {ContentImgList?.length > 5 ? (
                  <button className="more" key={'contentImg' + index} data-name={item.name} onClick={onClickModalOpen}>
                    <p className="more__txt">+{ContentImgList.length - 5}</p>
                    <img src={item.img} alt={item.name} />
                  </button>
                ) : (
                  <button
                    key={'contentImg' + index}
                    data-name={item.name}
                    onClick={e => {
                      ProductJs.showPopup(e.target.closest('button'));
                    }}
                  >
                    <img src={item.img} alt={item.name} />
                  </button>
                )}
              </React.Fragment>
            );
          })}
        </div>
        <p className="ThiraProductDetail__desc">{t('t1000F.productDetail.desc')}</p>
      </div>

      {/* Specifications */}
      <div className="ThiraProductDetail__specification detail">
        <h3>{t('t1000F.specifications.title')}</h3>
        <figure>
          <img className="pc-block" src={DetailPng} alt="상세보기" />
          <img className="mo-block" src={DetailMobPng} alt="상세보기" />
        </figure>
      </div>

      {/* VIDEO */}
      {/* <div className="ThiraProductDetail__specification">
        <h3>Specification video</h3>
        <figure>
          <video src={Video} playsInline controls></video>
        </figure>
      </div> */}

      <div className="ThiraProductDetail__cont active">
        <h3
          className="ThiraProductDetail__subtitle"
          onClick={e => {
            ProductJs.toggleArrow(e.target.closest('.ThiraProductDetail__subtitle'));
          }}
        >
          {t('t1000F.specifications.sub.0.title')} <button className="arr">아래 화살표</button>
        </h3>
        <div className="ThiraProductDetail__toggle">
          <ul className="ThiraProductDetail__list">
            <li className="ThiraProductDetail__item">
              <p className="txt-01">{t('t1000F.specifications.sub.0.item.0.itemDiv')}</p>
              <p className="txt-02">
                <LineWrap text={t('t1000F.specifications.sub.0.item.0.itemTxt')} />
              </p>
            </li>
            <li className="ThiraProductDetail__item">
              <p className="txt-01">{t('t1000F.specifications.sub.0.item.1.itemDiv')}</p>
              <p className="txt-02">
                <LineWrap text={t('t1000F.specifications.sub.0.item.1.itemTxt')} />
              </p>
            </li>
            <li className="ThiraProductDetail__item">
              <p className="txt-01">{t('t1000F.specifications.sub.0.item.2.itemDiv')}</p>
              {/* <p className="txt-02">{t('t1000F.specifications.sub.0.item.2.itemTxt')}</p> */}
              <p className="txt-02">
                <LineWrap text={t('t1000F.specifications.sub.0.item.2.itemTxt')} />
              </p>
            </li>
            <li className="ThiraProductDetail__item">
              <p className="txt-01">{t('t1000F.specifications.sub.0.item.3.itemDiv')}</p>
              <p className="txt-02">{t('t1000F.specifications.sub.0.item.3.itemTxt')}</p>
            </li>
            <li className="ThiraProductDetail__item">
              <p className="txt-01">{t('t1000F.specifications.sub.0.item.4.itemDiv')}</p>
              <p className="txt-02">{t('t1000F.specifications.sub.0.item.4.itemTxt')}</p>
            </li>
            <li className="ThiraProductDetail__item">
              <p className="txt-01">{t('t1000F.specifications.sub.0.item.5.itemDiv')}</p>
              <p className="txt-02">
                <LineWrap text={t('t1000F.specifications.sub.0.item.5.itemTxt')} />
              </p>
            </li>
            <li className="ThiraProductDetail__item">
              <p className="txt-01">{t('t1000F.specifications.sub.0.item.6.itemDiv')}</p>
              <p className="txt-02">{t('t1000F.specifications.sub.0.item.6.itemTxt')}</p>
            </li>
          </ul>
        </div>
      </div>

      <div className="ThiraProductDetail__cont">
        <h3
          className="ThiraProductDetail__subtitle"
          onClick={e => {
            ProductJs.toggleArrow(e.target.closest('.ThiraProductDetail__subtitle'));
          }}
        >
          {t('t1000F.specifications.sub.1.title')} <button className="arr">아래 화살표</button>
        </h3>
        <ul className="ThiraProductDetail__list">
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.1.item.0.itemDiv')}</p>
            <p className="txt-02">
              <LineWrap text={t('t1000F.specifications.sub.1.item.0.itemTxt')} />
            </p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.1.item.1.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.1.item.1.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.1.item.2.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.1.item.2.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.1.item.3.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.1.item.3.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.1.item.4.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.1.item.4.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.1.item.5.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.1.item.5.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.1.item.6.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.1.item.6.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.1.item.7.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.1.item.7.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.1.item.8.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.1.item.8.itemTxt')}</p>
          </li>
        </ul>
      </div>

      <div className="ThiraProductDetail__cont">
        <h3
          className="ThiraProductDetail__subtitle"
          onClick={e => {
            ProductJs.toggleArrow(e.target.closest('.ThiraProductDetail__subtitle'));
          }}
        >
          {t('t1000F.specifications.sub.2.title')} <button className="arr">아래 화살표</button>
        </h3>
        <ul className="ThiraProductDetail__list">
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.2.item.0.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.2.item.0.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.2.item.1.itemDiv')}</p>
            <p className="txt-02">
              <LineWrap text={t('t1000F.specifications.sub.2.item.1.itemTxt')} />
            </p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.2.item.2.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.2.item.2.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.2.item.3.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.2.item.3.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.2.item.4.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.2.item.4.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.2.item.5.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.2.item.5.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.2.item.6.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.2.item.6.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.2.item.7.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.2.item.7.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.2.item.8.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.2.item.8.itemTxt')}</p>
          </li>
        </ul>
      </div>

      <div className="ThiraProductDetail__cont">
        <h3
          className="ThiraProductDetail__subtitle"
          onClick={e => {
            ProductJs.toggleArrow(e.target.closest('.ThiraProductDetail__subtitle'));
          }}
        >
          {t('t1000F.specifications.sub.3.title')} <button className="arr">아래 화살표</button>
        </h3>
        <ul className="ThiraProductDetail__list">
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.3.item.0.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.3.item.0.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.3.item.1.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.3.item.1.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.3.item.2.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.3.item.2.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.3.item.3.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.3.item.3.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.3.item.4.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.3.item.4.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.3.item.5.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.3.item.5.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.3.item.6.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.3.item.6.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.3.item.7.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.3.item.7.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.3.item.8.itemDiv')}</p>
            <p className="txt-02">
              <LineWrap text={t('t1000F.specifications.sub.3.item.8.itemTxt')} />
            </p>
          </li>
        </ul>
      </div>

      <div className="ThiraProductDetail__cont">
        <h3
          className="ThiraProductDetail__subtitle"
          onClick={e => {
            ProductJs.toggleArrow(e.target.closest('.ThiraProductDetail__subtitle'));
          }}
        >
          {t('t1000F.specifications.sub.4.title')} <button className="arr">아래 화살표</button>
        </h3>
        <ul className="ThiraProductDetail__list">
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.4.item.0.itemDiv')}</p>
            <p className="txt-02">
              <LineWrap text={t('t1000F.specifications.sub.4.item.0.itemTxt')} />
            </p>
          </li>
        </ul>
      </div>

      <div className="ThiraProductDetail__cont">
        <h3
          className="ThiraProductDetail__subtitle"
          onClick={e => {
            ProductJs.toggleArrow(e.target.closest('.ThiraProductDetail__subtitle'));
          }}
        >
          {t('t1000F.specifications.sub.5.title')} <button className="arr">아래 화살표</button>
        </h3>
        <ul className="ThiraProductDetail__list">
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.5.item.0.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.5.item.0.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.5.item.1.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.5.item.1.itemTxt')}</p>
          </li>
        </ul>
      </div>

      <div className="ThiraProductDetail__cont">
        <h3
          className="ThiraProductDetail__subtitle"
          onClick={e => {
            ProductJs.toggleArrow(e.target.closest('.ThiraProductDetail__subtitle'));
          }}
        >
          {t('t1000F.specifications.sub.6.title')} <button className="arr">아래 화살표</button>
        </h3>
        <ul className="ThiraProductDetail__list">
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.6.item.0.itemDiv')}</p>
            <p className="txt-02">
              <LineWrap text={t('t1000F.specifications.sub.6.item.0.itemTxt')} />
            </p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.6.item.1.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.6.item.1.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.6.item.2.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.6.item.2.itemTxt')}</p>
          </li>
        </ul>
      </div>

      <div className="ThiraProductDetail__cont">
        <h3
          className="ThiraProductDetail__subtitle"
          onClick={e => {
            ProductJs.toggleArrow(e.target.closest('.ThiraProductDetail__subtitle'));
          }}
        >
          {t('t1000F.specifications.sub.7.title')} <button className="arr">아래 화살표</button>
        </h3>
        <ul className="ThiraProductDetail__list">
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.7.item.0.itemDiv')}</p>
            <p className="txt-02">
              <LineWrap text={t('t1000F.specifications.sub.7.item.0.itemTxt')} />
            </p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.7.item.1.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.7.item.1.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.7.item.2.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.7.item.2.itemTxt')}</p>
          </li>
        </ul>
      </div>

      <div className="ThiraProductDetail__cont">
        <h3
          className="ThiraProductDetail__subtitle"
          onClick={e => {
            ProductJs.toggleArrow(e.target.closest('.ThiraProductDetail__subtitle'));
          }}
        >
          {t('t1000F.specifications.sub.8.title')} <button className="arr">아래 화살표</button>
        </h3>
        <ul className="ThiraProductDetail__list">
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.8.item.0.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.8.item.0.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t1000F.specifications.sub.8.item.1.itemDiv')}</p>
            <p className="txt-02">{t('t1000F.specifications.sub.8.item.1.itemTxt')}</p>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}

export default ThiraProductT1000F;
