import {Link} from 'react-router-dom';

// img
import YoutubeSvg from '../../assets/images/common/sns_1.svg';
import LinkedInSvg from '../../assets/images/common/sns_2.svg';
import FaceBookSvg from '../../assets/images/common/sns_3.svg';
import InstagramSvg from '../../assets/images/common/sns_4.svg';
import TwitterSvg from '../../assets/images/common/sns_5.svg';

function ThiraSns() {
  const snsData = [
    {
      img: YoutubeSvg,
      name: 'Yotube',
      url: 'https://www.youtube.com/@thirarobotics',
    },
    {
      img: LinkedInSvg,
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/company/thirarobotics/',
    },
    {
      img: FaceBookSvg,
      name: 'FaceBook',
      url: 'https://www.facebook.com/THIRAROBOTICS/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0',
    },
    {
      img: InstagramSvg,
      name: 'Instagram',
      url: 'https://www.instagram.com/thirarobotics/',
    },
    {
      img: TwitterSvg,
      name: 'Twitter',
      // url: 'https://twitter.com/TRobtics', - 기존 주소
      // 23.11.25 트위터 주소 업체 요청에 따라 변경 완료
      url: 'https://twitter.com/THIRArobotics',
    },
  ];

  return (
    <ul className="ThiraSns">
      {snsData.map((item, index) => {
        return (
          <li key={'snsList' + index}>
            <Link to={item.url} className="btn-sns" target={'_blank'}>
              <img src={item.img} alt={item.name} />
            </Link>
          </li>
        );
      })}
    </ul>
  );
}

export default ThiraSns;
