import React from 'react';
import Modal from '../../util/Modal';

function DefaultModal({data}) {
  const {isState, comingSoon, content} = data || '';

  // 팝업 닫기
  const onClickClose = () => {
    isState.setState(false);
    Modal.modalClosed();
  };

  return (
    <React.Fragment>
      {isState.state && (
        <div className="default-modal">
          <div className="default-modal__bg" onClick={onClickClose}></div>
          <div className="default-modal__content">
            {/* 1. 커밍순 모달 */}
            {comingSoon ? (
              <React.Fragment>
                <p className="default-modal__text">COMING SOON...</p>
                <button className="default-modal__default_button" type="button" onClick={onClickClose}>
                  Close
                </button>
              </React.Fragment>
            ) : (
              // 2. 인증서 모달
              <React.Fragment>
                <hgroup>
                  <h3 style={{color: '#5C5C5C', fontSize: '30px', marginBottom: '10px', textAlign: 'center'}}>인증서 상세정보</h3>
                  <p style={{color: '#000000', fontSize: '45px', marginBottom: '65px', textAlign: 'center', fontWeight: 'bold'}}>{content.title}</p>
                </hgroup>
                <div className="certification-content" style={{color: '#000'}}>
                  {content.body}
                </div>
                <button className="default-modal__default_button" type="button" onClick={onClickClose}>
                  목록보기
                </button>
              </React.Fragment>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default DefaultModal;
