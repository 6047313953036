import React, {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
// swiper
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Autoplay, EffectFade, Navigation, Pagination, EffectCoverflow} from 'swiper';

// img
import AmrIcon01Svg from '../../assets/images/main/amr_01.svg';
import AmrIcon02Svg from '../../assets/images/main/amr_02.svg';
import AmrIcon03Svg from '../../assets/images/main/amr_03.svg';
import Animation01Gif from '../../assets/images/common/video_anti_slip.mp4';
import Animation02Gif from '../../assets/images/common/video_obstacle_driving.mp4';
import Animation03Gif from '../../assets/images/common/video_safety_function.mp4';
import Animation04Gif from '../../assets/images/common/video_elevators.mp4';
import Animation05Gif from '../../assets/images/common/video_automation.mp4';
import Animation06Gif from '../../assets/images/common/video_human.mp4';
import Animation01MobileGif from '../../assets/images/common/video_anti_slip_mobile.mp4';
import Animation02MobileGif from '../../assets/images/common/video_obstacle_driving_mobile.mp4';
import Animation03MobileGif from '../../assets/images/common/video_safety_function_mobile.mp4';
import Animation04MobileGif from '../../assets/images/common/video_elevators_mobile.mp4';
import Animation05MobileGif from '../../assets/images/common/video_automation_mobile.mp4';
import Animation06MobileGif from '../../assets/images/common/video_human_mobile.mp4';
import BannerBg01Jpg from '../../assets/images/main/banner_back_01.jpg';
import BannerBg02Jpg from '../../assets/images/main/banner_back_02.jpg';
import BannerBg03Jpg from '../../assets/images/main/banner_back_03.jpg';
import BannerBg04Jpg from '../../assets/images/main/banner_back_04.jpg';
import BannerBg05Jpg from '../../assets/images/main/banner_back_05.jpg';
import {Link} from 'react-router-dom';
import i18next from '../../i18n';

function ThiraMainAmr(props) {
  // 기본 언어
  const defaultLang = localStorage.getItem('lang');
  const {t} = useTranslation('main');
  const lang = i18next.language; // 현재 보여지는 언어

  const duration = '600';
  let AmrTop = useRef(null);
  useEffect(() => {
    let OffsetTop = AmrTop.current.offsetTop;
    window.addEventListener('scroll', () => {
      props.setAmrTop(OffsetTop);
    });

    return () => {
      window.removeEventListener('scroll', () => {
        props.setAmrTop(OffsetTop);
      });
    };
  });

  function handleResize() {
    props.setAmrTop(AmrTop.current.offsetTop);
  }

  function handleScroll() {
    let isActive = true;
    if (isActive) {
      if (window.scrollY >= AmrTop.current.offsetTop && window.scrollY < AmrTop.current.offsetTop + 50) {
        isActive = false;
      }
    }
  }

  useEffect(() => {
    props.setAmrTop(AmrTop.current.offsetTop);
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const AmrCandoData = [
    {
      img: AmrIcon01Svg,
      title: t('mainAmr.amrCandoData.0.title'),
      desc: t('mainAmr.amrCandoData.0.desc'),
      link: `/${lang}/amr/ROI`,
    },
    {
      img: AmrIcon02Svg,
      title: t('mainAmr.amrCandoData.1.title'),
      desc: t('mainAmr.amrCandoData.1.desc'),
      link: `/${lang}/amr/CaseStudies/detail?board_id=4&contents_id=` + (lang === 'ko' ? 37 : 36),
    },
    {
      img: AmrIcon03Svg,
      title: t('mainAmr.amrCandoData.2.title'),
      desc: t('mainAmr.amrCandoData.2.desc'),
      link: `/${lang}/amr/CaseStudies/detail?board_id=4&contents_id=` + (lang === 'ko' ? 39 : 38),
    },
  ];

  const bannerList = [
    {
      img: BannerBg01Jpg,
      title: t('mainAmr.bannerList.0.title'),
      desc: t('mainAmr.bannerList.0.desc'),
      link: `/${lang}/solutions/Safety`,
    },
    {
      img: BannerBg02Jpg,
      title: t('mainAmr.bannerList.1.title'),
      desc: t('mainAmr.bannerList.1.desc'),
      link: `/${lang}/amr/fms`,
    },
    {
      img: BannerBg03Jpg,
      title: t('mainAmr.bannerList.2.title'),
      desc: t('mainAmr.bannerList.2.desc'),
      link: `/${lang}/amr/solutions`,
    },
    {
      img: BannerBg04Jpg,
      title: t('mainAmr.bannerList.3.title'),
      desc: t('mainAmr.bannerList.3.desc'),
      link: `/${lang}/solutions/Following`,
    },
    // {
    //   img: BannerBg05Jpg,
    //   title: t('mainAmr.bannerList.4.title'),
    //   desc: t('mainAmr.bannerList.4.desc'),
    // },
  ];

  return (
    <section className="ThiraMainAmr" ref={AmrTop}>
      {/* <section className="ThiraMainAmr interstitialSection section fp-auto-height fp-scrollable"> */}
      <h2 className="blind">티라로보틱스의 기능 섹션</h2>
      <div className="inner-width">
        {/* AMRs Can Do? */}
        <article className="ThiraMainAmr__can-do-article">
          <h3 className="main-title" data-aos="fade-up">
            {t('mainAmr.amrCando.title1')}
            <br className="br-mo" /> {t('mainAmr.amrCando.title2')}
          </h3>
          {/* 1. pc list */}
          <ul className="ThiraMainAmr__can-do-content pc-flex ">
            {AmrCandoData.map((item, index) => {
              return (
                <li data-aos="fade-up" data-aos-duration={duration} data-aos-delay={300 * index} key={'AmrCandoList' + index}>
                  <Link to={item.link}>
                    <figure>
                      <img src={item.img} alt="아이콘" />
                    </figure>
                    <p className="content-title">{item.title}</p>
                    <p className="content-desc">{item.desc}</p>
                  </Link>
                </li>
              );
            })}
          </ul>
          {/* 1. mobile swiper*/}
          <div className="ThiraMainAmr__can-do-content mo-block">
            <Swiper
              effect={'coverflow'}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={2}
              coverflowEffect={{
                rotate: 0,
                stretch: -50,
                depth: 500,
              }}
              initialSlide={1}
              pagination={true}
              modules={[EffectCoverflow, Pagination]}
              className="Arm-swiper white-pagination"
            >
              {AmrCandoData.map((item, index) => {
                return (
                  <SwiperSlide key={'AmrCandoList' + index} className="">
                    <Link to={item.link}>
                      <figure>
                        <img src={item.img} alt="아이콘" />
                      </figure>
                      <p className="content-title">{item.title}</p>
                      <p className="content-desc">{item.desc}</p>
                    </Link>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </article>
        {/* THIRA Animation */}
        <article className="ThiraMainAmr__animation-article">
          <h3 className="ThiraMainAmr__sub-title" data-aos="fade-up">
            {t('mainAmr.armAnimation.txt1')}
            <br className="br-mo" /> {t('mainAmr.armAnimation.txt2')}
            <br className="br-pc" />
            {t('mainAmr.armAnimation.txt3')}
            <br className="br-mo" /> {t('mainAmr.armAnimation.txt4')}
          </h3>
          <div className="animation-swiper-wrap">
            <Swiper navigation={true} modules={[Navigation]} className="animation-swiper gray-navigation">
              <SwiperSlide>
                <p className="animation-title">{t('mainAmr.amrAnimationData.desc1')} </p>
                <figure className="animation-img-wrap">
                  <video className="pc-block" src={Animation01Gif} loop autoPlay muted playsInline></video>
                  <video className="mo-block" src={Animation01MobileGif} loop autoPlay muted playsInline></video>
                </figure>
              </SwiperSlide>

              <SwiperSlide>
                <p className="animation-title">{t('mainAmr.amrAnimationData.desc2')}</p>
                <figure className="animation-img-wrap">
                  <video className="pc-block" src={Animation02Gif} loop autoPlay muted playsInline></video>
                  <video className="mo-block" src={Animation02MobileGif} loop autoPlay muted playsInline></video>
                </figure>
              </SwiperSlide>

              <SwiperSlide>
                <p className="animation-title">{t('mainAmr.amrAnimationData.desc3')}</p>
                <figure className="animation-img-wrap">
                  <video className="pc-block" src={Animation03Gif} loop autoPlay muted playsInline></video>
                  <video className="mo-block" src={Animation03MobileGif} loop autoPlay muted playsInline></video>
                </figure>
              </SwiperSlide>

              <SwiperSlide>
                <p className="animation-title">{t('mainAmr.amrAnimationData.desc4')}</p>
                <figure className="animation-img-wrap">
                  <video className="pc-block" src={Animation04Gif} loop autoPlay muted playsInline></video>
                  <video className="mo-block" src={Animation04MobileGif} loop autoPlay muted playsInline></video>
                </figure>
              </SwiperSlide>
              <SwiperSlide>
                <p className="animation-title">{t('mainAmr.amrAnimationData.desc5')}</p>
                <figure className="animation-img-wrap">
                  <video className="pc-block" src={Animation05Gif} loop autoPlay muted playsInline></video>
                  <video className="mo-block" src={Animation05MobileGif} loop autoPlay muted playsInline></video>
                </figure>
              </SwiperSlide>
              <SwiperSlide>
                <p className="animation-title">{t('mainAmr.amrAnimationData.desc6')}</p>
                <figure className="animation-img-wrap">
                  <video className="pc-block" src={Animation06Gif} loop autoPlay muted playsInline></video>
                  <video className="mo-block" src={Animation06MobileGif} loop autoPlay muted playsInline></video>
                </figure>
              </SwiperSlide>
            </Swiper>
          </div>
        </article>
        {/* Technology Banner */}
        <article className="ThiraMainAmr__technology-article">
          <h3 className="ThiraMainAmr__sub-title" data-aos="fade-up">
            {t('mainAmr.techBanner')}
          </h3>
          <Swiper
            effect={'fade'}
            autoplay={{
              delay: 2500,
            }}
            pagination={{clickable: true}}
            modules={[Autoplay, EffectFade, Pagination]}
            className="technology-swiper"
          >
            {bannerList.map((item, index) => {
              return (
                <SwiperSlide key={'bannerList' + index}>
                  <Link to={item.link}>
                    <img src={item.img} alt="배경 이미지" />
                    <div className="technology-swiper-text" data-aos="fade-left">
                      <p>
                        <b>{item.title}</b>
                      </p>
                      <p>{item.desc}</p>
                    </div>
                  </Link>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </article>
      </div>
    </section>
  );
}

export default ThiraMainAmr;
