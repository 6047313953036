import PartnerSwiper from '../contents/partnerSwiper';
import {useTranslation} from 'react-i18next';

function ThiraMainPartner() {
  // 기본 언어
  const {t} = useTranslation('main');
  return (
    <section className="ThiraMainPartner">
      <div className="inner-width">
        <h3 className="main-title" data-aos="fade-up">
          {t('mainPartner.mainTitle')}
        </h3>
        <div className="ThiraMainPartner__swiper-wrap">
          <PartnerSwiper />
        </div>
      </div>
    </section>
  );
}

export default ThiraMainPartner;
