import {Outlet, useParams} from 'react-router-dom';
import '../../assets/css/solutions.scss';

function ThiraSolutions(props) {
  return (
    <div className="ThiraSolutions">
      <Outlet name={props.name} />
    </div>
  );
}

export default ThiraSolutions;
