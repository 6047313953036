import React, {useState, useEffect, useRef} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {Link} from 'react-router-dom';
import {ThiraTitle} from '../../component/common/index';
import ThiraPagination from '../../component/contents/pagination';
import axios from 'axios';
import {useIntl} from 'react-intl'; // 다국어
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';
// img
import SearchPng from '../../assets/images/common/ico_search.png';
import LineWrap from '../../component/common/lineWrap';

function ThiraBoard() {
  const navigate = useNavigate(); // 페이지 이동

  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어
  // 다국어 세팅
  const {t} = useTranslation('resource');

  const [apiData, setApiData] = useState(null);
  const location = useLocation();
  const sch = location.search;
  const paramsRef = useRef(new URLSearchParams(sch));
  sch == '' && (paramsRef.current = new URLSearchParams('pnum=10')); //초기값

  const board_id = 1;

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/board/${board_id}?lang=${i18next.language}&${paramsRef.current.toString()}`)
      .then(response => {
        setApiData(response.data);
      })
      .catch(response => {
        console.log('Error!');
      });
  }, [paramsRef.current.toString()]);

  const search_keyword = paramsRef.current.get('keyword') ?? '';
  const preKeyword = useRef(search_keyword);
  const [searchWord, setSearchWord] = useState(search_keyword);
  if (preKeyword.current != search_keyword) {
    //쿼리스트링의값과 동기화하기, 예: 대메뉴링크클릭시 초기화.
    setSearchWord(search_keyword);
    preKeyword.current = search_keyword;
  }

  // // tab 리스트
  // const tabList = ['Total', 'Notice', 'Event', /*'News',*/ 'Catalog', 'Video'];
  // // tab 리스트(국문)
  // const tabList_ko = ['전체', '공지', '이벤트', /*'News',*/ '카탈로그', '동영상'];
  // // 분류번호, 간단히 하드코딩
  const tabId = {
    Total: 0,
    Notice: 1,
    Event: 2,
    // News: 3,
    Catalog: 4,
    Video: 5,
  };
  // // 분류번호, 간단히 하드코딩
  const tabIdKo = {
    전체: 0,
    공지: 1,
    이벤트: 2,
    // News: 3,
    카탈로그: 4,
    동영상: 5,
  };
  const tabList = t('BOARD.tabList', {returnObjects: true});
  // const tabId = t('BOARD.tabId', {returnObjects: true});
  // const [tab, setTab] = useState(0);
  const tab = paramsRef.current.get('board_category_id') ?? '0'; //tabId[Total]의 값

  const handleClickTab = item => {
    //초기화
    setSearchWord('');
    setSelected('0');
    paramsRef.current = new URLSearchParams('pnum=10');
    // 국문 영문 구분
    if (lang === 'ko') {
      paramsRef.current.set('board_category_id', tabIdKo[item]);
    } else {
      paramsRef.current.set('board_category_id', tabId[item]);
    }
    // paramsRef.current.set('board_category_id', tabId[item]);
    navigate('?' + paramsRef.current.toString());
  };
  const handleSearch = () => {
    if (!searchWord) return;
    //초기화
    paramsRef.current = new URLSearchParams('pnum=10');
    paramsRef.current.set('keyword', searchWord);
    paramsRef.current.set('board_category_id', selected);
    navigate('?' + paramsRef.current.toString());
  };

  const search_board_category_id = paramsRef.current.get('keyword') ? paramsRef.current.get('board_category_id') ?? '0' : '0';
  const preSelected = useRef(search_board_category_id);
  // select 박스 border디자인 분기처리
  const [selected, setSelected] = useState(search_board_category_id);
  if (preSelected.current != search_board_category_id) {
    //쿼리스트링의값과 동기화하기, 예: 대메뉴링크클릭시 초기화.
    setSelected(search_board_category_id);
    preSelected.current = search_board_category_id;
  }

  let contentData = [];
  if (apiData) {
    contentData = apiData.list.map(i => {
      return {
        id: i.id,
        category: i.board_category_name,
        title: search_keyword
          ? (() => {
              //검색어가 있을 시 하아라이트처리한다.
              const parts = i.title.split(new RegExp(`(${search_keyword})`, 'gi'));
              return parts.map((w, i) => (w.toLowerCase() === search_keyword.toLowerCase() ? <b key={`${i.title}_${i}`}>{w}</b> : w));
            })()
          : i.title, //검색어없으면 그대로.
        date: i.reg_date.substring(0, 10),
      };
    });
  }

  const linkData = [
    {
      linkName: t('navigation.services'),
      linkUrl: `/${lang}/resource/board`,
    },
    {
      linkName: t('navigation.board'),
      linkUrl: `/${lang}/resource/board`,
    },
  ];

  return (
    <section className="ThiraBoardList">
      <div className="inner-width">
        <ThiraTitle title={t('BOARD.title')} navigation={true} linkData={linkData} />
      </div>
      <hr className="ThiraBorder" />
      <div className="inner-width">
        {/* TAB */}
        <ul className="ThiraBoardList__tab">
          {tabList.map((item, index) => (
            <li key={'tabList' + index}>
              {lang === 'ko' ? (
                <>
                  <button type="button" className={Number(tab) === tabIdKo[item] ? 'active' : undefined} onClick={() => handleClickTab(item)}>
                    {item}
                  </button>
                </>
              ) : (
                <>
                  <button type="button" className={Number(tab) === tabId[item] ? 'active' : undefined} onClick={() => handleClickTab(item)}>
                    {item}
                  </button>
                </>
              )}
            </li>
          ))}
        </ul>
        {/* SEARCH : PC */}
        <div className="ThiraBoardList__search pc-flex">
          <select className="search-select" value={selected} onChange={e => setSelected(e.target.value)}>
            <option value="0">{t('BOARD.search.0')}</option>
            <option value="1">{t('BOARD.search.1')}</option>
            <option value="2">{t('BOARD.search.2')}</option>
            {/* <option value="3">News</option> */}
            <option value="4">{t('BOARD.search.3')}</option>
            <option value="5">{t('BOARD.search.4')}</option>
          </select>
          <div className="search-wrap">
            <div className="input-wrap">
              <input type="text" value={searchWord} onChange={e => setSearchWord(e.target.value)} onKeyDown={e => e.key === 'Enter' && handleSearch()} placeholder={t('BOARD.searchText')} />
            </div>
            <button type="submit" onClick={handleSearch}>
              <img src={SearchPng} alt="검색" />
            </button>
          </div>
        </div>
        {/* TOTAL */}
        <p className="ThiraBoardList__total">
          <span>Total</span>
          <b>{Intl.NumberFormat().format(apiData?.paging.count ?? 0)}</b>
        </p>
        {/* CONTENT */}
        <article className="ThiraBoardList__content-wrap">
          <h2 className="blind">table</h2>
          <div className="content">
            {contentData.length > 0 ? (
              <React.Fragment>
                <div className="thead">
                  <p className="classification">Classification</p>
                  <p className="title">Title</p>
                  <p className="date">Date</p>
                </div>
                <ul className="tbody">
                  {contentData.map((list, index) => {
                    return (
                      <li key={'contentList' + index}>
                        <p className="classification">{list.category}</p>
                        <Link to={`detail?board_id=${board_id}&contents_id=${list.id}`} className="title">
                          {list.title}
                        </Link>
                        <p className="date">{list.date}</p>
                      </li>
                    );
                  })}
                </ul>
              </React.Fragment>
            ) : (
              <div className="tbody empty-content">
                <p>
                  <LineWrap text={t('no_result')} />
                </p>
              </div>
            )}
            {/* data 10개이상일시, 페이지 네이션 노출 */}
            {apiData?.paging.count > 10 && (
              <div className="pageination-wrap">
                <ThiraPagination paging={apiData?.paging} paramsRef={paramsRef} />
              </div>
            )}
          </div>
        </article>
        {/* SEARCH : MOBILE */}
        <div className="ThiraBoardList__search mo-flex">
          <select className="search-select" value={selected} onChange={e => setSelected(e.target.value)}>
            <option value="0">{t('BOARD.search.0')}</option>
            <option value="1">{t('BOARD.search.1')}</option>
            <option value="2">{t('BOARD.search.2')}</option>
            {/* <option value="3">News</option> */}
            <option value="4">{t('BOARD.search.3')}</option>
            <option value="5">{t('BOARD.search.4')}</option>
          </select>
          <div className="search-wrap">
            <div className="input-wrap">
              <input type="text" value={searchWord} onChange={e => setSearchWord(e.target.value)} onKeyDown={e => e.key === 'Enter' && handleSearch()} placeholder={t('BOARD.searchText')} />
            </div>
            <button type="submit" onClick={handleSearch}>
              <img src={SearchPng} alt="검색" />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ThiraBoard;
