import React from 'react';
import {useIntl} from 'react-intl'; // 다국어
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';
import {ThiraTitle} from '../../component/common/index';
import ThiraBanner from '../../component/contents/banner';
import LineWrap from '../../component/common/lineWrap';
//img
import banner01 from '../../assets/images/about/banner_manufacturing.jpg';
import IndustryBanner01 from '../../assets/images/industry/logistics_banner_01.jpg';
import IndustryBanner02 from '../../assets/images/industry/logistics_banner_02.jpg';

function ThiraIndustryLogistics() {
  // aos DelayTime
  const delayAos = '200';

  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어
  // 다국어 세팅
  const {t} = useTranslation('industry');

  const linkData = [
    {
      linkName: t('logistics.linkList.0'),
      linkUrl: `/${lang}/industry/logistics`,
    },
    {
      linkName: t('logistics.linkList.1'),
      linkUrl: `/${lang}/industry/logistics`,
    },
  ];

  // 배너 컴포넌트
  const bannerData = {
    bannerTitle: t('logistics.banner.title'),
    bannerList: [
      {
        url: `/${lang}/industry/manufacture`,
        background: banner01,
        text: t('logistics.banner.bannerList.0'),
      },
    ],
  };

  // const bannerData = {
  //   bannerTitle: LANG.banner.title,
  //   bannerList: [
  //     {
  //       url: '/industry/logistics',
  //       background: banner01,
  //       text: LANG.banner.bannerList[0],
  //     },
  //   ],
  // };
  return (
    <div className="ThiraIndustryLogistics">
      <div className="inner-width">
        <ThiraTitle title={t('logistics.title')} navigation={true} linkData={linkData} />
      </div>
      <hr className="ThiraBorder" />
      <div className="inner-width">
        {/* 1 */}
        <p className="txt-01" data-aos="fade-left">
          {t('logistics.descriptions.0.title')}
        </p>
        <p className="txt-02" data-aos="fade-left" data-aos-delay={delayAos * 1}>
          <LineWrap text={t('logistics.descriptions.0.body')} />
        </p>
        <figure className="img-wrap" data-aos="fade-left">
          <img src={IndustryBanner01} alt="banner img" />
        </figure>
        {/* 2 */}
        <p className="txt-01" data-aos="fade-left">
          {t('logistics.descriptions.1.title.0')}
        </p>
        <p className="txt-02" data-aos="fade-left">
          {t('logistics.descriptions.1.title.1')}
        </p>
        <p className="txt-02" data-aos="fade-left" data-aos-delay={delayAos * 1}>
          <LineWrap text={t('logistics.descriptions.1.body')} />
        </p>
        <p className="txt-01" data-aos="fade-left">
          {t('logistics.descriptions.2.title')}
        </p>
        <ol className="ThiraList ThiraList--slashNum">
          <li data-aos="fade-left" data-aos-delay={delayAos * 2}>
            {t('logistics.descriptions.2.body.0')}
          </li>
          <li data-aos="fade-left" data-aos-delay={delayAos * 3}>
            {t('logistics.descriptions.2.body.1')}
          </li>
          <li data-aos="fade-left" data-aos-delay={delayAos * 4}>
            {t('logistics.descriptions.2.body.2')}
          </li>
        </ol>
        <figure className="img-wrap" data-aos="fade-left">
          <img src={IndustryBanner02} alt="banner img" />
        </figure>
      </div>
      <hr className="ThiraBorder" />
      <ThiraBanner data={bannerData} />
    </div>
  );
}

export default ThiraIndustryLogistics;
