import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {ThiraTitle} from '../../component/common/index';
import ThiraPagination from '../../component/contents/pagination';
// img
import CloseSvg from '../../assets/images/common/ico_close_white.svg';
import SearchPng from '../../assets/images/common/ico_search.png';

function ThiraNewsBlogSearchReult() {
  // 검색 input border 분기처리
  const [searchWord, setSearchWord] = useState(0);

  // tab 리스트
  const tabList = ['Total', 'News', 'Blog'];
  const [tab, setTab] = useState(0);

  // select 박스 border디자인 분기처리
  const [selected, setSelected] = useState(false);

  // 컨텐츠 더미데이터
  const contentData = [
    {
      category: 'News',
      title: (
        <>
          <b>Robotics</b>
        </>
      ),
      date: '2023-03-03',
    },
    {
      category: 'News',
      title: (
        <>
          <b>Robotics</b> news
        </>
      ),
      date: '2023-02-03',
    },
    {
      category: 'Blog',
      title: (
        <>
          <b>Robotics</b> Blog
        </>
      ),
      date: '2023-01-10',
    },
    {
      category: 'News',
      title: (
        <>
          <b>Robotics</b> news
        </>
      ),
      date: '2023-01-03',
    },
    {
      category: 'News',
      title: (
        <>
          <b>Robotics</b> news
        </>
      ),
      date: '2023-01-01',
    },
  ];
  return (
    <section className="ThiraBoardList">
      <div className="inner-width">
        <div className="ThiraLocation">
          <Link className="ThiraLocation__btn" to="/">
            Home
          </Link>
          <span className="icon"></span>
          <span className="ThiraLocation__btn">Resource</span>
          <span className="icon"></span>
          <Link className="ThiraLocation__btn" to="/resource/newsBlog">
            News &amp; Blog
          </Link>
        </div>
        <ThiraTitle title="News &amp; Blog" />
      </div>
      <hr className="ThiraBorder" />
      <div className="inner-width">
        {/* TAB */}
        <ul className="ThiraBoardList__tab">
          {tabList.map((item, index) => {
            return (
              <li key={'tabList' + index}>
                <button
                  type="button"
                  className={tab === index ? 'active' : undefined}
                  onClick={() => {
                    setTab(index);
                  }}
                >
                  {item}
                </button>
              </li>
            );
          })}
        </ul>
        {/* SEARCH : PC */}
        <div className="ThiraBoardList__search pc-flex">
          <select className={selected === true ? 'search-select active' : 'search-select'} name="" id="" onChange={() => setSelected(true)}>
            <option value="total">total</option>
            <option value="news">news</option>
            <option value="blog">blog</option>
          </select>
          <div className={searchWord.length > 0 ? 'search-wrap active' : 'search-wrap'}>
            <div className="input-wrap">
              <input
                type="text"
                placeholder="Please enter to search"
                onChange={e => {
                  setSearchWord(e.target.value);
                }}
                required
                value="Robotics"
              />
            </div>
            {/* DEV :: 검색어 수정 시 아이콘 변경 */}
            {/* 00. default */}
            {/* <button type="submit">
              <img src={SearchPng} alt="검색" />
            </button> */}
            {/* 01. 검색어 수정 시 */}
            <button type="button">
              <img src={CloseSvg} alt="검색내용 삭제" />
            </button>
          </div>
        </div>
        {/* TOTAL */}
        <p className="ThiraBoardList__total">
          <span>Total</span>
          <b>50</b>
        </p>
        {/* CONTENT */}
        <article className="ThiraBoardList__content-wrap">
          <h2 className="blind">table</h2>
          <div className="content">
            {/* 데이터 있을때, 없을때 분기처리 */}
            {contentData.length > 0 ? (
              <React.Fragment>
                <div className="thead">
                  <p className="classification">Classification</p>
                  <p className="title">Title</p>
                  <p className="date">Date</p>
                </div>
                <ul className="tbody">
                  {contentData.map((list, index) => {
                    return (
                      <li key={'contentList' + index}>
                        <p className="classification">{list.category}</p>
                        <Link to="/resource/newsBlog/detail" className="title">
                          {list.title}
                        </Link>
                        <p className="date">{list.date}</p>
                      </li>
                    );
                  })}
                </ul>
              </React.Fragment>
            ) : (
              <div className="tbody empty-content">
                <p>
                  There are no search results.
                  <br />
                  Please search for a shorter search.
                </p>
              </div>
            )}
            {/* data 10개이상일시, 페이지 네이션 노출 */}
            <div className="pageination-wrap">
              <ThiraPagination />
            </div>
          </div>
        </article>
        {/* SEARCH : MOBILE */}
        <div className="ThiraBoardList__search mo-flex">
          <select className={selected === true ? 'search-select active' : 'search-select'} name="" id="" onChange={() => setSelected(true)}>
            <option value="total">total</option>
            <option value="news">news</option>
            <option value="blog">blog</option>
          </select>
          <div className={searchWord.length > 0 ? 'search-wrap active' : 'search-wrap'}>
            <div className="input-wrap">
              <input
                type="text"
                placeholder="Please enter to search"
                onChange={e => {
                  setSearchWord(e.target.value);
                }}
                required
              />
            </div>
            {/* DEV :: 검색어 수정 시 아이콘 변경 */}
            {/* 00. default */}
            {/* <button type="submit">
              <img src={SearchPng} alt="검색" />
            </button> */}
            {/* 01. 검색어 수정 시 */}
            <button type="button">
              <img src={CloseSvg} alt="검색내용 삭제" />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ThiraNewsBlogSearchReult;
