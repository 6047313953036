import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';

function ThiraTitle(props) {
  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어
  // 다국어 세팅
  const {t} = useTranslation('resource');
  return (
    <React.Fragment>
      {/* navigation 추가시 */}
      {props.navigation && (
        <div className="ThiraLocation">
          <Link className="ThiraLocation__btn" to="/">
            {t('navigation.home')}
          </Link>
          {props.linkData.map((list, index) => {
            return (
              <React.Fragment key={'linkList' + index}>
                <span className="icon"></span>
                <Link className="ThiraLocation__btn" to={list.linkUrl}>
                  {list.linkName}
                </Link>
              </React.Fragment>
            );
          })}
        </div>
      )}
      <h2 className="ThiraTitle">{props.title}</h2>
    </React.Fragment>
  );
}

export default ThiraTitle;
