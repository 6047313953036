import {Link, useLocation} from 'react-router-dom';
import {ThiraTitle} from '../../component/common/index';
import axios from 'axios';
import {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';

function ThiraBoardDetail() {
  const [apiData, setApiData] = useState(null);
  const location = useLocation();
  const sch = location.search;
  const params = new URLSearchParams(sch);
  const board_id = params.get('board_id');
  const contents_id = params.get('contents_id');

  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어
  // 다국어 세팅
  const {t} = useTranslation('resource');

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/board/${board_id}/contents/${contents_id}?lang=${i18next.language}`)
      .then(response => {
        setApiData(response.data);
      })
      .catch(response => {
        console.log('Error!');
      });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [location.search]);

  if (!apiData) return null; //로딩중...

  const linkData = [
    {
      linkName: t('navigation.services'),
      linkUrl: `/${lang}/resource/board`,
    },
    {
      linkName: t('navigation.board'),
      linkUrl: `/${lang}/resource/board`,
    },
  ];

  return (
    <section className="ThiraBoardDetail">
      <div className="inner-width">
        <div className="ThiraLocation">
          <Link className="ThiraLocation__btn" to={`/${lang}`}>
            {t('navigation.home')}
          </Link>
          <span className="icon"></span>
          <span className="ThiraLocation__btn">{t('navigation.board')}</span>
          <span className="icon"></span>
          <Link className="ThiraLocation__btn" to={`/${lang}/resource/board/detail`}>
            {t('BOARD.detail.0')}
          </Link>
        </div>
        <ThiraTitle title={t('BOARD.detail.1')} />
      </div>
      <hr className="ThiraBorder" />
      <div className="inner-width">
        {/* TOP */}
        <div className="ThiraBoardDetail__title-container">
          <p>
            <span className="category">{apiData.contents.board_category_name}</span>
            <span className="date">{apiData.contents.reg_date.substring(0, 10)}</span>
          </p>
          <h3 className="title">{apiData.contents.title}</h3>
        </div>
        {/* CONTENT */}
        <div className="ThiraBoardDetail__content-container">
          <div className="editor" dangerouslySetInnerHTML={{__html: apiData.contents.contents}}></div>
          <div className="file-wrap">
            <p>Attached file</p>
            <div className="file-list">
              {apiData.contentsFile.map((f, k) => (
                <button key={`att_${board_id}${contents_id}${k}`} type="button" onClick={() => window.open(f.download_url)}>
                  {f.org_name}
                </button>
              ))}
            </div>
          </div>
          <div className="list-button-wrap">
            <Link to={`/${lang}/resource/board`}>{t('common.button')}</Link>
          </div>
        </div>
        {/* BOTTOM */}
        <div className="ThiraBoardDetail__util-container">
          {apiData.previous[0] && (
            <div className="util">
              <p className="page-direction">{t('common.posts.0')}</p>
              <Link to={`?board_id=${board_id}&contents_id=${apiData.previous[0].id}`}>{apiData.previous[0].title}</Link>
              <p className="date">{apiData.previous[0].reg_date.substring(0, 10)}</p>
            </div>
          )}
          {apiData.next[0] && (
            <div className="util">
              <p className="page-direction">{t('common.posts.1')}</p>
              <Link to={`?board_id=${board_id}&contents_id=${apiData.next[0].id}`}>{apiData.next[0].title}</Link>
              <p className="date">{apiData.next[0].reg_date.substring(0, 10)}</p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default ThiraBoardDetail;
