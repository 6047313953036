import {useState, forwardRef, useRef, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation, Trans} from 'react-i18next';
import i18next from '../../i18n';

// Import Swiper
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {EffectFade, Navigation, Autoplay, Pagination} from 'swiper';
// aos
import AOS from 'aos';
// img
import Video from '../../assets/images/main/main_video.mp4';
import MouseWheelArrowSvg from '../../assets/images/main/mouse_wheel_arrow.svg';
import LineWrap from '../common/lineWrap';

function ThiraMainVis({lang}) {
  // 기본 언어
  const defaultlang = i18next.language; // 현재 보여지는 언어
  const {t} = useTranslation('main');

  // scroll
  let mainVisRef = useRef(null);
  // useEffect(() => {
  //   window.addEventListener('scroll', () => {
  //     props.SetMainVisRef(mainVisRef.current.offsetTop);
  //   });

  //   return () => {
  //     window.removeEventListener('scroll', () => {
  //       props.SetMainVisRef(mainVisRef.current.offsetTop);
  //     });
  //   };
  // });
  // swiper animation Event
  const [isActiveSwiper, setIsActiveSwiper] = useState([0]);

  const AosActiveFunction = realIndex => {
    setIsActiveSwiper(realIndex);
    AOS.init();
  };

  const SlideContent = [
    {
      title: (
        <>
          {/* AMRs
          <br className="br-mo" /> That Don’t Mind Mess */}
          <Trans i18nKey={t('mainVis.slideContent.title')} components={{br: <br />}} />
        </>
      ),
      desc: (
        // defaultLang === 'kor' ? (
        //   <>
        //     티라로보틱스는 자율주행 물류로봇 전문 기업입니다.
        //     <br />
        //     로봇 제작에 필요한 소프트웨어, 기구, 전장 설계에 관한 모든 기술들은 100%
        //     <br /> 자체 개발하여 직접 생산합니다.
        //   </>
        // ) : (
        //   <>
        //     THIRA ROBOTICS specializes in autonomous mobile robots.
        //     <br />
        //     All technologies such as control software, mechanical design and electrical design
        //     <br />
        //     that go into the robot process are 100% developed and manufactured in-house.
        //   </>
        // ),
        <>
          <LineWrap text={t('mainVis.slideContent.desc')} />
        </>
      ),
      url: `/${defaultlang}/amr/introduction`,
    },
  ];

  return (
    <section className="ThiraMainVis" ref={mainVisRef}>
      <h2 className="blind">메인 비쥬얼 섹션</h2>
      <div className="inner-width w-1520">
        <div slot="container-start" className="ThiraMainVis__bg">
          <video loop autoPlay muted playsInline>
            <source src={Video} type="video/mp4" />
          </video>
        </div>
        {/* pc */}
        <Swiper
          speed={200}
          effect={'fade'}
          navigation={true}
          modules={[EffectFade, Navigation, Autoplay]}
          className="visual-swiper gray-navigation pc-block"
          disableoninteraction="false"
          autoplay={{
            delay: 5000,
          }}
          onInit={() => AosActiveFunction(0)}
          onSlideChangeTransitionEnd={swiper => setIsActiveSwiper(swiper.realIndex)}
        >
          {SlideContent.map((item, index) => {
            return (
              <SwiperSlide key={'mainVisSlide' + index}>
                <div className="ThiraMainVis__title" data-aos={isActiveSwiper === index ? 'fade-up' : ''} data-aos-easing="ease-in-sine">
                  {item.title}
                </div>
                <div className="ThiraMainVis__desc">
                  <p data-aos={isActiveSwiper === index ? 'fade-up' : ''} data-aos-delay="300">
                    {item.desc}
                  </p>
                  <Link to={item.url} className="ThiraMainVis__view-more" data-aos={isActiveSwiper === index ? 'fade-up' : ''} data-aos-delay="600">
                    <span>View more</span>
                  </Link>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        {/* mobile */}
        <Swiper
          speed={200}
          effect={'fade'}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Pagination, Autoplay]}
          className="visual-swiper white-pagination mo-block"
          disableoninteraction="false"
          autoplay={{
            delay: 5000,
          }}
          onInit={() => AosActiveFunction(0)}
          onSlideChangeTransitionEnd={swiper => setIsActiveSwiper(swiper.realIndex)}
        >
          {SlideContent.map((item, index) => {
            return (
              <SwiperSlide key={'mainVisSlide' + index}>
                <div className="ThiraMainVis__title" data-aos={isActiveSwiper === index ? 'fade-up' : ''} data-aos-easing="ease-in-sine">
                  {item.title}
                </div>
                <div className="ThiraMainVis__desc">
                  <p data-aos={isActiveSwiper === index ? 'fade-up' : ''} data-aos-delay="300">
                    {item.desc}
                  </p>
                  <Link to={item.url} className="ThiraMainVis__view-more" data-aos={isActiveSwiper === index ? 'fade-up' : ''} data-aos-delay="600">
                    <span>View more</span>
                  </Link>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className="ThiraMainVis__scroll-wheel">
          <figure>
            <img src={MouseWheelArrowSvg} alt="아이콘" />
          </figure>
          <div className="scroll-wheel-animation"></div>
          <figure className="rotate-icon">
            <img src={MouseWheelArrowSvg} alt="아이콘" />
          </figure>
          <p>MOUSE WHEEL</p>
        </div>
      </div>
    </section>
  );
}

export default ThiraMainVis;
