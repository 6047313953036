import React from 'react';
import {Link} from 'react-router-dom';
import {useState, useEffect, useRef} from 'react';
import {useIntl} from 'react-intl'; // 다국어
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';
import logoImg from '../../assets/images/common/logo_color.png';
import CookieDetail from './cookieDetail';
import Modal from '../../util/Modal';
// 쿠키정책 pdf 파일 국/영문
import CookiePolicyEn from '../../assets/files/cookies-policy-en_US-20201028.pdf';
import CookiePolicyKo from '../../assets/files/cookies-policy-ko_KR-20201028.pdf';

function ThiraCookie({footerCookie, closeCookiePop}) {
  // 다국어 세팅
  const {t} = useTranslation('cookie_policy');
  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어

  const [isClosed, setIsClosed] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleClose = () => {
    setIsClosed(true);
  };

  // 팝업 열기
  const openCookie = () => {
    // disableScroll();
    setIsClosed(true);
    setIsPopupOpen(true);
    Modal.modalOpen();
  };

  // 팝업 닫기
  const closeCookie = () => {
    // enableScroll();
    setIsPopupOpen(false);
    Modal.modalClosed();
    // 푸터에서 쿠키 열었을때 닫힘 처리
    closeCookiePop();
  };

  // 푸터에서 쿠키 팝업 열었을 때, 열림처리
  useEffect(() => {
    if (footerCookie) {
      setIsClosed(true);
      setIsPopupOpen(true);
      Modal.modalOpen();
    }
  }, [footerCookie]);

  // 쿠키 상세보기 리스트 on/off
  const [listShow, setListShow] = useState(false);

  // 활성화 버튼 on/off
  const [isOn, setIsOn] = useState(false);
  const cookieActive = () => {
    setIsOn(!isOn);
  };

  // 쿠키상세정보 아코디언
  const [activeItem, setActiveItem] = useState(null);

  const ItemClick = item => {
    setActiveItem(item === activeItem ? null : item);
  };

  const [activeTab, setActiveTab] = useState(0);

  const TabContentList_new = () => {
    return t('detail', {returnObjects: true}).map((item, index) =>
      activeTab === index ? (
        <div className="cookie-box__content">
          <div className="content__header">
            <h2>{item.title}</h2>
            {item.is_all_active === 'true' ? <button className="all-active">{t('common.active')}</button> : null}
            {item.is_active === 'true' ? (
              <div className="active-radio-box">
                <span>{isOn ? item.active : item.inactive}</span>
                <div className={`active-radio ${isOn ? 'on' : ''}`}>
                  <input type="radio" name="radio" />
                  <label onClick={cookieActive}>{isOn ? item.active : item.inactive}</label>
                </div>
              </div>
            ) : null}
          </div>
          <div className="content__body">
            <p>{item.text}</p>
            <button className="cookie-detail-btn" onClick={() => setListShow(true)}>
              {t('common.detail')}
            </button>
          </div>
        </div>
      ) : null,
    );
  };

  return (
    <React.Fragment>
      {/* 팝업 */}
      {isPopupOpen && (
        <>
          {/* 쿠키정책 팝업(PC) */}
          <div className="popup-overlay">
            <div className="cookie-popup">
              <div className="cookie-popup__header">
                <h1 className="ThiraHeader__h1">
                  <img src={logoImg} alt="THIRA ROBOTICS " />
                  {t('common.center')}
                </h1>
              </div>
              {listShow ? (
                // 쿠키 세부정보
                <div className="cookie-popup__body cookie-list">
                  <div className="cookie-list__header">
                    <h3>
                      <button type="button" onClick={() => setListShow(false)}></button>
                      {t('common.list')}
                    </h3>
                    <div className="search-box">
                      <label>
                        검색
                        <input type="text" placeholder={t('common.search')} />
                        <button className="search-btn">{t('common.search')}</button>
                      </label>
                      {/* <div className="filter">
                        <button>{t('common.filter')}</button>
                      </div> */}
                    </div>
                  </div>
                  <div className="cookie-list__body">
                    <CookieDetail></CookieDetail>
                  </div>
                </div>
              ) : (
                // 메인
                <div className="cookie-popup__body">
                  {/* 쿠키정책 리스트 */}
                  <ul className="cookie-nav">
                    {t('detail', {returnObjects: true}).map((list, index) => {
                      return (
                        <li
                          className={activeTab === index ? 'cookie-nav__btn on' : 'cookie-nav__btn'}
                          key={'tab' + index}
                          onClick={() => {
                            setActiveTab(index);
                            // console.log(activeTab);
                          }}
                        >
                          {list.title}
                        </li>
                      );
                    })}
                  </ul>
                  {/* 리스트 컨텐츠 박스 */}
                  <div className="cookie-box">{TabContentList_new()}</div>
                </div>
              )}
              <div className="cookie-popup__footer">
                <button onClick={closeCookie}>{t('common.allSave')}</button>
                <button onClick={closeCookie}>{t('common.allAllow')}</button>
              </div>
            </div>
          </div>
          {/* 쿠키정책 팝업(Mobile) */}
          <div className="popup-overlay-mo">
            <div className="cookie-popup">
              <div className="cookie-popup__header">
                <h1 className="ThiraHeader__h1">{t('common.center')}</h1>
              </div>
              {listShow ? (
                // 쿠키 세부정보
                <div className="cookie-popup__body cookie-list">
                  <div className="cookie-list__header">
                    <h3>
                      <button type="button" onClick={() => setListShow(false)}></button>
                      {t('common.list')}
                    </h3>
                    {/* <div className="search-box">
                      <label>
                        검색
                        <input type="text" placeholder="검색" />
                        <button className="search-btn">검색</button>
                      </label>
                      <div className="filter">
                        <button>필터</button>
                      </div>
                    </div> */}
                  </div>
                  <div className="cookie-list__body">
                    <CookieDetail></CookieDetail>
                  </div>
                </div>
              ) : (
                // 메인
                <div className="cookie-popup__body">
                  {/* 쿠키정책 리스트 */}
                  <ul className="cookie-nav">
                    {t('detail', {returnObjects: true}).map((list, index) => {
                      return (
                        <li
                          className={activeTab === index ? 'cookie-nav__btn on' : 'cookie-nav__btn'}
                          key={'tab' + index}
                          onClick={() => {
                            setActiveTab(index);
                            console.log(activeTab);
                          }}
                        >
                          {list.title}
                          {/* 리스트 컨텐츠 박스 */}
                          <div className="cookie-box">{TabContentList_new()}</div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
              <div className="cookie-popup__footer">
                <button onClick={closeCookie}>{t('common.allSave')}</button>
                <button onClick={closeCookie}>{t('common.allAllow')}</button>
              </div>
            </div>
          </div>
        </>
      )}
      {/* 쿠키 배너 */}
      {!isClosed && (
        <div className="banner-overlay">
          <aside className="ThiraCookie">
            <div className="ThiraCookie-wrap">
              <div className="ThiraCookie__txt">
                <p>
                  {t('text')}
                  {lang === 'ko' ? (
                    <a href={CookiePolicyKo} target="_blank" rel="noopener noreferrer">
                      {t('common.cookie')}
                    </a>
                  ) : (
                    <a href={CookiePolicyEn} target="_blank" rel="noopener noreferrer">
                      {t('common.cookie')}
                    </a>
                  )}
                </p>
              </div>
              <div className="ThiraCookie__btn">
                <button className="ThiraCookie__btn-open" onClick={openCookie}>
                  {t('common.setting')}
                </button>
                <button className="ThiraCookie__btn-all" onClick={handleClose}>
                  {t('common.allAllow')}
                </button>
              </div>
            </div>
          </aside>
        </div>
      )}
    </React.Fragment>
  );
}

export default ThiraCookie;
