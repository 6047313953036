import {ThiraTitle} from '../../component/common/index';
import ThiraSns from '../../component/contents/sns';
import {Link} from 'react-router-dom';
import {useIntl} from 'react-intl'; // 다국어
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';
function ThiraAboutLocation() {
  // 다국어 세팅 (about.json 파일)
  const {t} = useTranslation('about');
  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어

  const linkData = [
    {
      linkName: t('navigation.services'),
      linkUrl: `/${lang}/about/OurStory`,
    },
    {
      linkName: t('navigation.location'),
      linkUrl: `/${lang}/about/Location`,
    },
  ];

  const duration = '100';

  return (
    <section className="ThiraAboutLocation">
      <div className="inner-width">
        {/* 
          텍스트 위치 : navigation > location
          페이지 내 위치 : 회사소개  > 찾아오시는 길 > 제목
        */}
        <ThiraTitle title={t('navigation.location')} navigation={true} linkData={linkData} />
      </div>
      <hr className="ThiraBorder" />
      <div className="inner-width">
        <article className="ThiraAboutLocation__info">
          <h3 className="blind">THIRA Infomation</h3>
          <div className="info-text">
            <dl data-aos="fade-up" data-aos-delay={duration * 1}>
              {/* 
                텍스트 위치 : LOCATION > info > email > title
                페이지 내 위치 : 회사소개  > 찾아오시는 길 > 구분 - 이메일
              */}
              <dt>{t('LOCATION.info.email.title')}</dt>
              <dd>
                {/* 
                  텍스트 위치 : LOCATION > info > email > content
                  페이지 내 위치 : 회사소개  > 찾아오시는 길 > 이메일 주소
                */}
                <a href={'mailto:' + t('LOCATION.info.email.content')}>sales@thirarobotics.com</a>
              </dd>
            </dl>
            <dl data-aos="fade-up" data-aos-delay={duration * 2}>
              {/* 
                텍스트 위치 : LOCATION > info > tel > title
                페이지 내 위치 : 회사소개  > 찾아오시는 길 > 구분 - 대표 전화번호
              */}
              <dt>{t('LOCATION.info.tel.title')}</dt>
              <dd>
                {/* 
                  텍스트 위치 : LOCATION > info > tel > content
                  페이지 내 위치 : 회사소개  > 찾아오시는 길 > 대표 전화번호
                */}
                <a href={'tel:' + t('LOCATION.info.tel.content')}>{t('LOCATION.info.tel.content')}</a>
              </dd>
            </dl>
            <dl data-aos="fade-up" data-aos-delay={duration * 3}>
              {/* 
                텍스트 위치 : LOCATION > info > fax > title
                페이지 내 위치 : 회사소개  > 찾아오시는 길 > 구분 - 팩스번호
              */}
              <dt>{t('LOCATION.info.fax.title')}</dt>
              <dd>
                {/* 
                  텍스트 위치 : LOCATION > info > fax > content
                  페이지 내 위치 : 회사소개  > 찾아오시는 길 > 팩스번호
                */}
                <a href={'tel:' + t('LOCATION.info.fax.content')}>{t('LOCATION.info.fax.content')}</a>
              </dd>
            </dl>
            <dl data-aos="fade-up" data-aos-delay={duration * 4}>
              <dt>
                {/* 
                  텍스트 위치 : LOCATION > info > homepage > title
                  페이지 내 위치 : 회사소개  > 찾아오시는 길 > 구분 - 홈페이지
                */}
                {t('LOCATION.info.homepage.title')}
              </dt>
              <dd>
                {/* 
                  텍스트 위치 : LOCATION > info > homepage > content
                  페이지 내 위치 : 회사소개  > 찾아오시는 길 > 홈페이지 주소
                */}
                {t('LOCATION.info.homepage.content')}
              </dd>
            </dl>
            <dl data-aos="fade-up" data-aos-delay={duration * 5}>
              <dt>
                {/* 
                  텍스트 위치 : LOCATION > info > address > title
                  페이지 내 위치 : 회사소개  >  > 구분 - 주소
                */}
                {t('LOCATION.info.address.title')}
              </dt>
              <dd>
                {/* 
                  텍스트 위치 : LOCATION > info > address > content
                  페이지 내 위치 : 회사소개  > 찾아오시는 길 > 주소
                */}
                {/* 23.11.25 기존코드 (13403) 해당 워딩 중복으로 인해 아래 114번 코드로 수정*/}
                {/* &#40;13403&#41; {t('LOCATION.info.address.content')} */}
                {/* 23.11.25 코드 수정 */}
                {t('LOCATION.info.address.content')}
              </dd>
            </dl>
          </div>
          <div className="button-wrap pc-block" data-aos="zoom-in" data-aos-delay={duration * 5}>
            {/* 
              텍스트 위치 : LOCATION > info > contact > title
              페이지 내 위치 : 회사소개  > 찾아오시는 길 > 문의하기 버튼
            */}
            <Link to={`/${lang}/inquiry`}>{t('LOCATION.info.contact.title')}</Link>
          </div>
          <div className="ThiraAboutLocation__sns-wrap mo-block" data-aos="zoom-in" data-aos-delay={duration * 5}>
            <ThiraSns />
          </div>
        </article>

        <article className="ThiraAboutLocation__map" data-aos="zoom-in" data-aos-delay={duration * 6}>
          <h3 className="blind">THIRA Map</h3>
          <iframe
            title="THIRA"
            src={
              lang === 'ko'
                ? 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17410.985021704328!2d127.15794322163062!3d37.43605472348396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca9ec4decb8d3%3A0x60efa8f53f34c5d9!2z7Yuw652866Gc67O07Yux7Iqk!5e0!3m2!1sko!2skr!4v1677808744413!5m2!1sko!2skr'
                : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3168.1859413937573!2d127.1545133895415!3d37.43271195979514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca9ec4decb8d3%3A0x60efa8f53f34c5d9!2sTHiRA%20Robotics!5e0!3m2!1sen!2skr!4v1688016847280!5m2!1sen!2skr'
            }
            width="800"
            height="600"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </article>
        <div className="ThiraAboutLocation__sns-wrap pc-block" data-aos="fade-up" data-aos-delay={duration * 8}>
          <ThiraSns />
        </div>
        <div className="button-wrap mo-block" data-aos="zoom-in">
          {/* 
              텍스트 위치 : LOCATION > info > contact > title
              페이지 내 위치 : 회사소개  > 찾아오시는 길 > 문의하기 버튼
            */}
          <Link to={`/${lang}/inquiry`}>{t('LOCATION.info.contact.title')}</Link>
        </div>
      </div>
    </section>
  );
}

export default ThiraAboutLocation;
