'use strict';

// qna 노출
export function toggleArrow(el) {
  const $target = el.closest('.ThiraFaq__cont');
  const $cont_all = document.querySelectorAll('.ThiraFaq__cont');
  const $target_li = $target.querySelectorAll('li');

  $cont_all.forEach(el => {
    if (el === $target) {
      $target.classList.toggle('active');
      $target_li.forEach((el, index) => {
        setTimeout(() => {
          el.classList.add('active');
        }, 100 * index);
      });
    } else {
      el.classList.remove('active');
      el.querySelectorAll('li').forEach(el => {
        el.classList.remove('active');
      });
    }
  });
}
