import {Link} from 'react-router-dom';
// swiper
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import {EffectCoverflow, Pagination} from 'swiper';
import React, {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';

// img
import T200 from '../../assets/images/product/Tseries/T200/T-200_01.png';
import T300 from '../../assets/images/product/Tseries/T300/T-300_01.png';
import T600 from '../../assets/images/product/Tseries/T600/T-600_01.png';
import T1000 from '../../assets/images/product/Tseries/T1000/T-1000_01.png';
import T1000F from '../../assets/images/product/Tseries/T1000F/T-1000F_01.png';
import L200 from '../../assets/images/product/Lseries/L200/L-200_01.png';

function ThiraMainProduct() {
  // 기본 언어
  const {t} = useTranslation('main');
  const lang = i18next.language; // 현재 보여지는 언어
  // product Data
  const ProductList = ['T200', 'T300', 'T600', 'T1000', 'T1000F', 'L200'];
  // swiper pagination
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + ProductList[index] + '</span>';
    },
  };

  return (
    <section className="ThiraMainProduct">
      <div className="inner-width">
        <hgroup>
          <h3 className="main-title">{t('mainProduct.product.title')}</h3>
          <p className="main-desc">{t('mainProduct.product.desc')}</p>
        </hgroup>
        {/* pc */}
        <ul className="product-group pc-block">
          <li className="product-group__list">
            <div className="product-group__title">
              <p data-aos="fade-right">
                <b>T200</b>
                <b>T300</b>
              </p>
              <p className="product-group__desc" data-aos="fade-right" data-aos-delay="100">
                {t('mainProduct.productGroup.group1')}
              </p>
            </div>
            <ul className="product">
              <li className="product__list" data-aos="fade-up">
                <Link to={`/${lang}/product/detail/T200`}>
                  <figure className="product__img">
                    <img src={T200} alt="T200 이미지" />
                  </figure>
                  <p className="product__name">T200</p>
                </Link>
              </li>
              <li className="product__list" data-aos="fade-up" data-aos-delay="100">
                <Link to={`/${lang}/product/detail/T300`}>
                  <figure className="product__img">
                    <img src={T300} alt="T300 이미지" />
                  </figure>
                  <p className="product__name">T300</p>
                </Link>
              </li>
            </ul>
          </li>
          <li className="product-group__list">
            <div className="product-group__title">
              <p data-aos="fade-right">
                <b>T600</b>
              </p>
              <p className="product-group__desc" data-aos="fade-right" data-aos-delay="100">
                {t('mainProduct.productGroup.group2')}
              </p>
            </div>
            <ul className="product">
              <li className="product__list" data-aos="fade-up">
                <Link to={`/${lang}/product/detail/T600`}>
                  <figure className="product__img">
                    <img src={T600} alt="t600 이미지" />
                  </figure>
                  <p className="product__name">T600</p>
                </Link>
              </li>
            </ul>
          </li>
          <li className="product-group__list">
            <div className="product-group__title">
              <p data-aos="fade-right">
                <b>T1000</b>
                <b className="f-icon">T1000</b>
              </p>
              <p className="product-group__desc" data-aos="fade-right" data-aos-delay="100">
                {t('mainProduct.productGroup.group3')}
              </p>
            </div>
            <ul className="product">
              <li className="product__list" data-aos="fade-up">
                <Link to={`/${lang}/product/detail/T1000`}>
                  <figure className="product__img">
                    <img src={T1000} alt="T1000 이미지" />
                  </figure>
                  <p className="product__name">T1000</p>
                </Link>
              </li>
              <li className="product__list" data-aos="fade-up" data-aos-delay="100">
                <Link to={`/${lang}/product/detail/T1000F`}>
                  <figure className="product__img">
                    <img src={T1000F} alt="T1000F 이미지" />
                  </figure>
                  <p className="product__name f-icon dark">T1000</p>
                </Link>
              </li>
            </ul>
          </li>
          <li className="product-group__list">
            <div className="product-group__title">
              <p data-aos="fade-right">
                <b>L200</b>
              </p>
              <p className="product-group__desc" data-aos="fade-right" data-aos-delay="100">
                {t('mainProduct.productGroup.group4')}
              </p>
            </div>
            <ul className="product">
              <li className="product__list" data-aos="fade-up">
                <Link to={`/${lang}/product/detail/L200`}>
                  <figure className="product__img">
                    <img src={L200} alt="L200 이미지" />
                  </figure>
                  <p className="product__name">L200</p>
                </Link>
              </li>
            </ul>
          </li>
        </ul>
        {/* mobile */}
        <div className="product-group mo-block">
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            // slidesPerView={'auto'}
            slidesPerView={1.5}
            coverflowEffect={{
              rotate: 0,
              stretch: -50,
              depth: 500,
            }}
            loop={true}
            pagination={pagination}
            modules={[EffectCoverflow, Pagination]}
            className="main-product-swiper"
          >
            <SwiperSlide>
              <div className="product__list">
                <Link to={`/${lang}/product/detail/T200`}>
                  <figure className="product__img">
                    <img src={T200} alt="T200 이미지" />
                  </figure>
                  <p className="product__name">T200</p>
                  <p className="product__desc">{t('mainProduct.productGroup.group1')}</p>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="product__list">
                <Link to={`/${lang}/product/detail/T300`}>
                  <figure className="product__img">
                    <img src={T300} alt="T300 이미지" />
                  </figure>
                  <p className="product__name">T300</p>
                  <p className="product__desc">{t('mainProduct.productGroup.group1')}</p>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="product__list">
                <Link to={`/${lang}/product/detail/T600`}>
                  <figure className="product__img">
                    <img src={T600} alt="T600 이미지" />
                  </figure>
                  <p className="product__name">T600</p>
                  <p className="product__desc">{t('mainProduct.productGroup.group2')}</p>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="product__list">
                <Link to={`/${lang}/product/detail/T1000`}>
                  <figure className="product__img">
                    <img src={T1000} alt="T1000 이미지" />
                  </figure>
                  <p className="product__name">T1000</p>
                  <p className="product__desc">{t('mainProduct.productGroup.group3')}</p>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="product__list">
                <Link to={`/${lang}/product/detail/T1000F`}>
                  <figure className="product__img">
                    <img src={T1000F} alt="T1000F 이미지" />
                  </figure>
                  <p className="product__name f-icon">T1000</p>
                  <p className="product__desc">{t('mainProduct.productGroup.group3')}</p>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="product__list">
                <Link to={`/${lang}/product/detail/L200`}>
                  <figure className="product__img">
                    <img src={L200} alt="L200 이미지" />
                  </figure>
                  <p className="product__name">L200</p>
                  <p className="product__desc">{t('mainProduct.productGroup.group4')}</p>
                </Link>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  );
}

export default ThiraMainProduct;
