import React, {useEffect, useState} from 'react';
import {ThiraMainVis, ThiraMainAmr, ThiraMainProduct, ThiraMainPartner, ThiraMainCompare, ThiraMainNews} from '../../component/main/index';
// scss
import '../../assets/css/main.scss';

function ThiraMain({lang}) {
  // 기본 언어
  const defaultLang = localStorage.getItem('lang');

  let [touchStartPos, setTouchStartPos] = useState(0);
  let [touchEndPos, setTouchEndPos] = useState(0);

  const startPos = 0;
  const [amrTop, setAmrTop] = useState(0);

  function wheelHandle(e) {
    let currentPos = Math.ceil(window.pageYOffset);
    let WheelMove = e.deltaY;
    // console.log('움 직 임', WheelMove, currentPos, 'currentPos', amrTop, 'amrTop');
    if (WheelMove < 0) {
      if (currentPos >= startPos && currentPos <= amrTop) {
        e.preventDefault();
        window.scrollTo({
          top: startPos,
          behavior: 'smooth',
        });
      }
    } else {
      if (currentPos >= startPos && currentPos < amrTop) {
        e.preventDefault();
        window.scrollTo({
          top: amrTop,
          behavior: 'smooth',
        });
      } else if (currentPos >= amrTop) {
      }
    }
  }

  function handlePointerDown(e) {
    // if (e.pointerType === 'touch') {
    setTouchStartPos(e.touches[0].pageY);
    e.preventDefault();
    // }
  }

  function handlePointerUp(e) {
    // if (e.pointerType === 'touch') {
    setTouchEndPos(e.changedTouches[0].pageY);
    // }
  }

  function handleTouch() {
    console.log(touchStartPos - touchEndPos);
    if (touchStartPos - touchEndPos > 10) {
      //터치 감도
      let currentPos = window.pageYOffset;
      if (currentPos >= startPos && currentPos < amrTop) {
        window.scrollTo({
          top: amrTop,
          behavior: 'smooth',
        });
      }
    }
    if (touchStartPos - touchEndPos < -10) {
      //터치 감도
      let currentPos = window.pageYOffset;
      if (currentPos >= startPos && currentPos < amrTop) {
        window.scrollTo({
          top: startPos,
          behavior: 'smooth',
        });
      }
    }
  }

  useEffect(() => {
    // if (document.documentElement.clientWidth > 750) {
    window.addEventListener('touchstart', handlePointerDown);
    window.addEventListener('touchend', handlePointerUp);
    window.addEventListener('wheel', wheelHandle, {passive: false});
    // }

    return () => {
      window.removeEventListener('touchstart', handlePointerDown);
      window.removeEventListener('touchend', handlePointerUp);
      // window.removeEventListener('pointerdown', handlePointerDown);
      // window.removeEventListener('pointerup', handlePointerUp);
      window.removeEventListener('wheel', wheelHandle, {passive: false});
    };
  });

  return (
    <React.Fragment>
      <ThiraMainVis></ThiraMainVis>
      {/* {defaultLang === 'en' && ( */}
      <>
        <ThiraMainAmr setAmrTop={setAmrTop}></ThiraMainAmr>
        <ThiraMainProduct></ThiraMainProduct>
        <ThiraMainPartner></ThiraMainPartner>
        <ThiraMainCompare></ThiraMainCompare>
        <ThiraMainNews></ThiraMainNews>
      </>
      {/* )} */}
    </React.Fragment>
  );
}

export default ThiraMain;
