import React, {useEffect} from 'react';
import {ThiraTitle} from '../../component/common/index';
import * as AmrJs from '../../assets/js/amr';
import {ThiraAmrLocation} from '../../component/amr/index';
import ThiraBanner from '../../component/contents/banner';
import {useIntl} from 'react-intl'; // 다국어
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';
// Sources
import ico_wms from '../../assets/images/amr/ico_wms.svg';
import ico_wes from '../../assets/images/amr/ico_wes.svg';
import ico_mes from '../../assets/images/amr/ico_mes.svg';
import ico_erp from '../../assets/images/amr/ico_erp.svg';
import bannerFMS from '../../assets/images/amr/banner_fms.png';
import bannerAppCase from '../../assets/images/amr/banner_application_case.png';
import bannerIntroduction from '../../assets/images/amr/banner_introduction.png';
// 티라 솔루션 이미지 추가
import SolutionImg from '../../assets/images/amr/THIRA-SF-Solution.png';
import LineWrap from '../../component/common/lineWrap';

function ThiraAmrSolutions(props) {
  useEffect(() => {
    AmrJs.lazyLoad();
  });
  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어
  // 다국어 세팅
  const {t} = useTranslation('amr');

  // 배너 컴포넌트 데이터
  const bannerData = {
    bannerTitle: t('solutions.banner.title'),
    bannerList: [
      {
        url: `/${lang}/amr/introduction`,
        background: bannerIntroduction,
        text: t('solutions.banner.bannerList.0'),
      },
      {
        url: `/${lang}/amr/fms`,
        background: bannerFMS,
        text: t('solutions.banner.bannerList.1'),
      },
      {
        url: `/${lang}/amr/CaseStudies`,
        background: bannerAppCase,
        text: t('solutions.banner.bannerList.2'),
      },
    ],
  };

  return (
    <div className="ThiraAmrSolutions">
      <div className="inner-width">
        <ThiraAmrLocation name={t('solutions.title')} link="solutions"></ThiraAmrLocation>

        <ThiraTitle title={t('solutions.title')}></ThiraTitle>
      </div>

      <hr className="ThiraBorder" />

      <div className="inner-width">
        <p className="txt-01"></p>
        <h3 className="txt-01">{t('solutions.descriptions.0.title')}</h3>
        <p className="txt-02 mt-40">{<LineWrap text={t('solutions.descriptions.0.body')} />}</p>
        <ul className="ThiraAmrSolutions__icons ico">
          <li>
            <figure className="ico__img">
              <img src={ico_wms} alt="WMS" />
            </figure>
            <p className="ico__txt">WMS</p>
          </li>
          <li>
            <figure className="ico__img">
              <img src={ico_wes} alt="WES" />
            </figure>
            <p className="ico__txt">WES</p>
          </li>
          <li>
            <figure className="ico__img">
              <img src={ico_mes} alt="MES" />
            </figure>
            <p className="ico__txt">MES</p>
          </li>
          <li>
            <figure className="ico__img">
              <img src={ico_erp} alt="ERP" />
            </figure>
            <p className="ico__txt">ERP</p>
          </li>
        </ul>
        {/* 티라 솔루션 이미지 추가 및 구조 변경 20230706 //*/}
        <div className="solution-division">
          <div className="solution-division__left">
            <p className="txt-03">{t('solutions.descriptions.1.title')}</p>
            <p className="txt-02">{<LineWrap text={t('solutions.descriptions.1.body')} />}</p>
            <p className="txt-03">{t('solutions.descriptions.2.title')}</p>
            <p className="txt-02">{<LineWrap text={t('solutions.descriptions.2.body')} />}</p>
          </div>
          <div className="solution-division__right">
            <figure>
              <img src={SolutionImg} alt="solution img" />
            </figure>
          </div>
        </div>
      </div>
      <br />
      <br />

      <hr className="ThiraBorder" />
      <ThiraBanner data={bannerData} />
    </div>
  );
}

export default ThiraAmrSolutions;
