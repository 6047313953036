import React, {useState} from 'react';
import Modal from '../../util/Modal';

//img
import CloseIconSvg from '../../assets/images/common/modal_close.svg';
// swiper
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import {FreeMode, Navigation, Thumbs} from 'swiper';

function ThumbNailModal({data}) {
  const {isState, SlideContent} = data || '';
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  // 팝업 닫기
  const onClickClose = () => {
    isState.setState(false);
    Modal.modalClosed();
  };

  return (
    <React.Fragment>
      {isState.state && (
        <div className="default-modal thumb-modal">
          <div className="default-modal__bg" onClick={onClickClose}></div>
          <div className="default-modal__content">
            <button className="close-btn" type="button" onClick={onClickClose}>
              <img src={CloseIconSvg} alt="모달 닫기" />
            </button>
            <div className="modal-swiper">
              {/* Thumbs Swiper */}
              <Swiper spaceBetween={114} thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null}} modules={[FreeMode, Navigation, Thumbs]} className="thumb-swiper">
                {SlideContent.map((list, index) => {
                  return (
                    <SwiperSlide key={'thumbSlide' + index}>
                      <img src={list.img} alt={list.alt} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              {/* Small Swiper */}
              <div className="small-swiper-wrap">
                <Swiper
                  onSwiper={setThumbsSwiper}
                  navigation={true}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="small-swiper"
                  breakpoints={{
                    0: {
                      spaceBetween: 5,
                      slidesPerView: 4.5,
                    },
                    750: {
                      spaceBetween: 15,
                      slidesPerView: 6,
                    },
                  }}
                >
                  {SlideContent.map((list, index) => {
                    return (
                      <SwiperSlide key={'smallPcSlide' + index}>
                        <img src={list.img} alt={list.name} />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default ThumbNailModal;
