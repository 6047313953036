// swiper
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import {Navigation, Autoplay, Pagination} from 'swiper';

// img (국문페이지용)
import PartnersKr01Png from '../../assets/images/references/ref_kr_01.png';
import PartnersKr02Png from '../../assets/images/references/ref_kr_02.png';
import PartnersKr03Png from '../../assets/images/references/ref_kr_03.png';
import PartnersKr04Png from '../../assets/images/references/ref_kr_04.png';
import PartnersKr05Png from '../../assets/images/references/ref_kr_05.png';
import PartnersKr06Png from '../../assets/images/references/ref_kr_06.png';
import PartnersKr07Png from '../../assets/images/references/ref_kr_07.png';
import PartnersKr08Png from '../../assets/images/references/ref_kr_08.png';
import PartnersKr09Png from '../../assets/images/references/ref_kr_09.png';
import PartnersKr10Png from '../../assets/images/references/ref_kr_10.png';
import PartnersKr11Png from '../../assets/images/references/ref_kr_11.png';
import PartnersKr12Png from '../../assets/images/references/ref_kr_12.png';
import PartnersKr13Png from '../../assets/images/references/ref_kr_13.png';
// img (영문페이지용)
import Partners01Png from '../../assets/images/references/ref_en_01.png';
import Partners02Png from '../../assets/images/references/ref_en_02.png';
import Partners03Png from '../../assets/images/references/ref_en_03.png';
import Partners04Png from '../../assets/images/references/ref_en_04.png';
import Partners05Png from '../../assets/images/references/ref_en_05.png';
import Partners06Png from '../../assets/images/references/ref_en_06.png';
import Partners07Png from '../../assets/images/references/ref_en_07.png';
import Partners08Png from '../../assets/images/references/ref_en_08.png';
import Partners09Png from '../../assets/images/references/ref_en_09.png';
import Partners10Png from '../../assets/images/references/ref_en_10.png';
import Partners11Png from '../../assets/images/references/ref_en_11.png';
import Partners12Png from '../../assets/images/references/ref_en_12.png';
import Partners13Png from '../../assets/images/references/ref_en_13.png';

// 기본 언어
const defaultLang = localStorage.getItem('lang');

function PartnerSwiper() {
  const swiperContent_kr = [
    {
      img: PartnersKr01Png,
      alt: '파트너사',
    },
    {
      img: PartnersKr02Png,
      alt: '파트너사',
    },
    {
      img: PartnersKr03Png,
      alt: '파트너사',
    },
    {
      img: PartnersKr04Png,
      alt: '파트너사',
    },
    {
      img: PartnersKr05Png,
      alt: '파트너사',
    },
    {
      img: PartnersKr06Png,
      alt: '파트너사',
    },
    {
      img: PartnersKr07Png,
      alt: '파트너사',
    },
    {
      img: PartnersKr08Png,
      alt: '파트너사',
    },
    {
      img: PartnersKr09Png,
      alt: '파트너사',
    },
    {
      img: PartnersKr10Png,
      alt: '파트너사',
    },
    {
      img: PartnersKr11Png,
      alt: '파트너사',
    },
    {
      img: PartnersKr12Png,
      alt: '파트너사',
    },
    {
      img: PartnersKr13Png,
      alt: '파트너사',
    },
  ];
  const swiperContent = [
    {
      img: Partners01Png,
      alt: 'partner',
    },
    {
      img: Partners02Png,
      alt: 'partner',
    },
    {
      img: Partners03Png,
      alt: 'partner',
    },
    {
      img: Partners04Png,
      alt: 'partner',
    },
    {
      img: Partners05Png,
      alt: 'partner',
    },
    {
      img: Partners06Png,
      alt: 'partner',
    },
    {
      img: Partners07Png,
      alt: 'partner',
    },
    {
      img: Partners08Png,
      alt: 'partner',
    },
    {
      img: Partners09Png,
      alt: 'partner',
    },
    {
      img: Partners10Png,
      alt: 'partner',
    },
    {
      img: Partners11Png,
      alt: 'partner',
    },
    {
      img: Partners12Png,
      alt: 'partner',
    },
    {
      img: Partners13Png,
      alt: 'partner',
    },
  ];
  return (
    <div className="partner-swiper">
      {/* PC */}
      <Swiper
        slidesPerView={3}
        spaceBetween={15}
        navigation={true}
        autoplay={{
          delay: 2500,
        }}
        modules={[Pagination, Autoplay, Navigation]}
        breakpoints={{
          0: {
            spaceBetween: 7,
            slidesPerView: 3,
          },
          750: {
            spaceBetween: 15,
            slidesPerView: 3,
          },
        }}
        className="partner-swiper__swiper-wrap gray-navigation"
      >
        {defaultLang === 'kor'
          ? swiperContent_kr.map((item, index) => (
              <SwiperSlide key={'partner-swiper' + index}>
                <img src={item.img} alt="파트너사 이미지" />
              </SwiperSlide>
            ))
          : swiperContent.map((item, index) => (
              <SwiperSlide key={'partner-swiper' + index}>
                <img src={item.img} alt="partner image" />
              </SwiperSlide>
            ))}
      </Swiper>
    </div>
  );
}

export default PartnerSwiper;
