'use strict';

// 솔루션 내용 시간차 노출
export function lazyLoad() {
  const $targets = document.querySelectorAll('.ThiraSolutions .inner-width > *');
  $targets.forEach((el, index) => {
    setTimeout(() => {
      el.classList.add('active');
    }, 100 * index);
  });
}
