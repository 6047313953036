import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';

function ThiraAmrLocation(props) {
  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어
  // 다국어 세팅
  const {t} = useTranslation('amr');

  return (
    <div className="ThiraLocation">
      <Link className="ThiraLocation__btn" to={`/${lang}`}>
        {t('menu.home')}
      </Link>
      <span className="icon"></span>
      <Link className="ThiraLocation__btn" to={`/${lang}/amr/introduction`}>
        {t('menu.amr')}
      </Link>
      <span className="icon"></span>
      <Link className="ThiraLocation__btn" to={`/${lang}/amr/${props.link}`}>
        {props.name}
      </Link>
    </div>
  );
}

export default ThiraAmrLocation;
