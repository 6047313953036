import React, {useState} from 'react';
import {ThiraTitle} from '../../component/common/index';
import ThiraBanner from '../../component/contents/banner';
import ThiraTable from '../../component/contents/ThiraTable';
import {useIntl} from 'react-intl'; // 다국어
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';
//img
import BannerImg01 from '../../assets/images/about/banner_solution.jpg';
import VasimLogo from '../../assets/images/main/partners_04.png';
import DreamCorpLogo from '../../assets/images/main/partners_06.png';
import BigWaverLogo from '../../assets/images/main/partners_09.png';
import ClobotLogo from '../../assets/images/main/partners_11.png';
import YYSoftLogo from '../../assets/images/main/partners_12.png';
import SUNGJOONLogo from '../../assets/images/main/partners_13.png';
import AutomationLogo from '../../assets/images/main/partners_10.png';

function ThiraAboutPartner() {
  // 다국어 세팅 (about.json 파일)
  const {t} = useTranslation('about');
  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어

  const linkData = [
    {
      linkName: t('navigation.services'),
      linkUrl: `/${lang}/about/OurStory`,
    },
    {
      linkName: t('navigation.partner'),
      linkUrl: `/${lang}/about/partner`,
    },
  ];

  // 배너 컴포넌트 데이터
  const bannerData = {
    /* 텍스트 위치 : PARTNER > banner > title
      페이지 내 위치 : 회사소개  > 파트너 소개 > 제목 */
    bannerTitle: t('PARTNER.banner.title'),
    bannerList: [
      {
        /* 텍스트 위치 : PARTNER > banner > list > 첫번째 객체 > url
          페이지 내 위치 : 회사소개  > 파트너 소개 > 최 하단 배너 링크 */
        url: `/${lang}` + t('PARTNER.banner.list.0.url'),
        background: BannerImg01,
        /* 텍스트 위치 : PARTNER > banner > list > 첫번째 객체 > text
          페이지 내 위치 : 회사소개  > 파트너 소개 > 최 하단 배너 내용 */
        text: t('PARTNER.banner.list.0.text'),
      },
    ],
  };

  // partner List
  const partnerList = [BigWaverLogo, YYSoftLogo, ClobotLogo, AutomationLogo, DreamCorpLogo, VasimLogo, SUNGJOONLogo];
  //
  const [clickOpen, setClickOpen] = useState({}); // mobile 토글
  const toggleOpen = id => {
    setClickOpen(prevClickOpen => ({
      ...prevClickOpen,
      [id]: !prevClickOpen[id],
    }));
  };

  return (
    <section className="ThiraAboutPartner">
      <div className="inner-width">
        {/* 
          텍스트 위치 : navigation > partner
          페이지 내 위치 : 회사소개  > 파트너소개 > 제목
        */}
        <ThiraTitle title={t('PARTNER.title')} navigation={true} linkData={linkData} />
      </div>
      <hr className="ThiraBorder" />
      <div className="inner-width">
        <article className="ThiraAboutPartner__partner-list">
          <h3 className="blind">Partner List</h3>
          <ul className="pc-flex">
            {/* 
              텍스트 위치 : PARTNER > partnerList > 객체(순차적으로)
              페이지 내 위치 : 회사소개  > 파트너소개 > 파트너 리스트
            */}
            {partnerList.map((img, index) => {
              return (
                <li key={index} data-aos="flip-left" data-aos-delay={100 * index}>
                  <div className="logo-img-wrap">
                    <img src={img} alt={t(`PARTNER.partnerList.${index}.name`) + 'logo'} />
                  </div>
                  <h4 className="company-name">{t(`PARTNER.partnerList.${index}.name`)}</h4>
                  <p className="company-desc">{t(`PARTNER.partnerList.${index}.desc`)}</p>
                  <div className="company-info">
                    <address>{t(`PARTNER.partnerList.${index}.address`)}</address>
                    {t(`PARTNER.partnerList.${index}.phone`) && (
                      <p>
                        <span>Phone: </span>
                        {t(`PARTNER.partnerList.${index}.phone`)}
                      </p>
                    )}
                    {t(`PARTNER.partnerList.${index}.email`) && (
                      <p>
                        <span>E-mail: </span>
                        {t(`PARTNER.partnerList.${index}.email`)}
                      </p>
                    )}
                    {t(`PARTNER.partnerList.${index}.homepage`) && (
                      <p>
                        <span>Homepage: </span>
                        {t(`PARTNER.partnerList.${index}.homepage`)}
                      </p>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
          {/* 모바일 리스트 */}
          <ul className="mo-block">
            {/* 
              텍스트 위치 : PARTNER > partnerList > 객체(순차적으로)
              페이지 내 위치 : 회사소개  > 파트너소개 > 파트너 리스트
            */}
            {partnerList.map((img, index) => {
              return (
                <li key={index} data-aos="flip-left">
                  <div className="logo-img-wrap">
                    <img src={img} alt={t(`PARTNER.partnerList.${index}.name`) + 'logo'} />
                  </div>
                  <button className={clickOpen === true ? 'company-name active' : 'company-name'} type="button" onClick={() => toggleOpen(index)}>
                    {t(`PARTNER.partnerList.${index}.name`)}
                  </button>
                  {clickOpen[index] && (
                    <React.Fragment>
                      <p className="company-desc" data-aos="fade-up">
                        {t(`PARTNER.partnerList.${index}.desc`)}
                      </p>
                      <div className="company-info" data-aos="fade-up">
                        <address>{t(`PARTNER.partnerList.${index}.address`)}</address>
                        {/* <p>{LANG.partnerList[index].info}</p> */}
                        {t(`PARTNER.partnerList.${index}.phone`) && (
                          <p>
                            <span>Phone: </span>
                            {t(`PARTNER.partnerList.${index}.phone`)}
                          </p>
                        )}
                        {t(`PARTNER.partnerList.${index}.email`) && (
                          <p>
                            <span>E-mail: </span>
                            {t(`PARTNER.partnerList.${index}.email`)}
                          </p>
                        )}
                        {t(`PARTNER.partnerList.${index}.homepage`) && (
                          <p>
                            <span>Homepage: </span>
                            {t(`PARTNER.partnerList.${index}.homepage`)}
                          </p>
                        )}
                      </div>
                    </React.Fragment>
                  )}
                </li>
              );
            })}
          </ul>
        </article>
        <article className="ThiraAboutPartner__grid">
          {/* 
            텍스트 위치 : PARTNER > grid > title
            페이지 내 위치 : 회사소개  > 파트너소개 > 티라 선택 이유 테이블 제목
          */}
          <h3 data-aos="fade-up">{t('PARTNER.grid.title')}</h3>
          <ThiraTable />
        </article>
      </div>
      <div className="hr-wrap">
        <hr className="ThiraBorder" />
      </div>
      <ThiraBanner data={bannerData} />
    </section>
  );
}

export default ThiraAboutPartner;
