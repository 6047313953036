import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {ThiraTitle} from '../../component/common/index';
import * as SolutionJs from '../../assets/js/solutions';
import ThiraSolutionsLocation from './location';
import {useIntl} from 'react-intl'; // 다국어
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';
// Import Swiper
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {Parallax, Navigation} from 'swiper';

// Sources
import Animation04Gif from '../../assets/images/common/video_elevators.mp4';
import Animation04MobileGif from '../../assets/images/common/video_elevators_mobile.mp4';
import bannerAntiSlip from '../../assets/images/solutions/banner_antislip.png';
import bannerObstacle from '../../assets/images/solutions/banner_obstacle.png';
import bannerFollow from '../../assets/images/solutions/banner_follow.png';
import bannerSafety from '../../assets/images/solutions/banner_safety.png';
import media_03 from '../../assets/images/solutions/solution_03.png';
import media_04 from '../../assets/images/solutions/solution_04.png';
import LineWrap from '../common/lineWrap';

function ThiraSolutionsElevator(props) {
  useEffect(() => {
    SolutionJs.lazyLoad();
  });
  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어
  // 다국어 세팅
  const {t} = useTranslation('solutions');

  const bannerImgList = [bannerAntiSlip, bannerObstacle, bannerAntiSlip, bannerFollow, bannerSafety];
  const BANNER_CONTENT_LIST = t('navigation.optionList', {returnObjects: true});
  const filteredData = BANNER_CONTENT_LIST.filter((item, index) => index !== 2);

  return (
    <>
      <div className="ThiraSolutionsElevator">
        <div className="inner-width">
          <ThiraSolutionsLocation default={props.name}></ThiraSolutionsLocation>
          <ThiraTitle title={t('OVERCOMING_STEPS.title')}></ThiraTitle>
        </div>

        <hr className="ThiraBorder" />

        <div className="inner-width">
          <p className="txt-01">{t('OVERCOMING_STEPS.descriptions.0.title')}</p>
          <figure className="animation-img-wrap">
            <video src={Animation04Gif} loop autoPlay muted playsInline className="pc-block"></video>
            <video src={Animation04MobileGif} loop autoPlay muted playsInline className="mo-block"></video>
          </figure>
          <p className="txt-02">
            <LineWrap text={t('OVERCOMING_STEPS.descriptions.0.body')} />
          </p>
          <div className="ThiraSolutions__media">
            <div className="media">
              <img src={media_03} alt="미디어 3" />
            </div>
            <div className="media">
              <img src={media_04} alt="미디어 4" />
            </div>
          </div>
        </div>

        <hr className="ThiraBorder" />

        <div className="inner-width">
          <p className="txt-01">{t('navigation.bannerTitle')}</p>
        </div>
        <Swiper
          speed={600}
          parallax={true}
          watchOverflow={true}
          spaceBetween={30}
          slidesPerView={'1.4'}
          navigation={{
            nextEl: '.banner-swiper-button-next',
            prevEl: '.banner-swiper-button-prev',
          }}
          modules={[Parallax, Navigation]}
          className="banner mo-flex"
        >
          {bannerImgList.map((img, idx) => {
            return (
              <SwiperSlide key={idx}>
                <img src={img} alt="배경 이미지" className="banner__bg" />
                <Link to={`/${lang}` + filteredData[idx].link} className="banner__link"></Link>
                <p className="banner__txt">{filteredData[idx].name}</p>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className="inner-width">
          <div className="ThiraSolutions__banner">
            <Swiper
              speed={600}
              parallax={true}
              watchOverflow={true}
              spaceBetween={30}
              slidesPerView={'3'}
              slidesPerGroup={'3'}
              navigation={{
                nextEl: '.banner-swiper-button-next',
                prevEl: '.banner-swiper-button-prev',
              }}
              modules={[Parallax, Navigation]}
              className="banner pc-flex"
            >
              {bannerImgList.map((img, idx) => {
                return (
                  <SwiperSlide key={idx}>
                    <img src={img} alt="배경 이미지" className="banner__bg" />
                    <Link to={`/${lang}` + filteredData[idx].link} className="banner__link"></Link>
                    <p className="banner__txt">{filteredData[idx].name}</p>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <i className="banner-swiper-button-prev"></i>
            <i className="banner-swiper-button-next"></i>
          </div>
        </div>
      </div>
    </>
  );
}

export default ThiraSolutionsElevator;
