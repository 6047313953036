import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

function importAll(r) {
  const translations = {};
  r.keys().forEach(key => {
    const langKey = key.replace(/^\.\//, '').replace(/\.(json)$/, '');
    translations[langKey] = r(key);
  });
  return translations;
}
// ko, en 폴더 하위의 메뉴명으로 생성된 json 파일 전부를 읽어서 사용 가능하도록 처리.
const resources = {
  ko: importAll(require.context('./locale/ko', false, /\.json$/)),
  en: importAll(require.context('./locale/en', false, /\.json$/)),
};
// URL에 따라 초기 언어 설정 (현재 기본 초기언어 ko)
const defaultLanguage = window.location.pathname.startsWith('/en') ? 'en' : 'ko';
// 초기 언어 변경 시 en과 ko만 바꿔서 변경해주시면 됩니다.
// ()

i18n.use(initReactI18next).init({
  resources,
  lng: defaultLanguage, // 초기 언어 설정 (원하는 언어로 변경 가능)
  fallbackLng: 'en', // 언어가 없는 경우의 대체 언어
  interpolation: {
    escapeValue: false, // 리액트에서 HTML 형식 문자열 사용 가능하도록 설정
  },
});

export default i18n;
