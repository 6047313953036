import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';

function CookieDetail() {
  const defaultLang = localStorage.getItem('lang');
  // 다국어 세팅
  const {t} = useTranslation('cookie_policy');
  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어
  const [activeItem, setActiveItem] = useState(null);

  const itemClick = item => {
    setActiveItem(item === activeItem ? null : item);
  };

  const testCookie = [
    {item: '0', title: '자사쿠키'},
    {item: '1', title: 'WWW.NAVER.COM'},
    {item: '2', title: 'WWW.ZAIND.COM'},
  ];

  const testCookieDetail = [
    {pid: '0', id: '0', name: '_fbp', host: 'ZANID.com', date: '90일', type: '퍼스트 파티'},
    {pid: '0', id: '1', name: '_fbp', host: 'ZANID.com', date: '90일', type: '퍼스트 파티'},
    {pid: '1', id: '0', name: '_fbp', host: 'ZANID.com', date: '90일', type: '퍼스트 파티'},
    {pid: '1', id: '1', name: '_fbp', host: 'ZANID.com', date: '90일', type: '퍼스트 파티'},
    {pid: '2', id: '0', name: '_fbp', host: 'ZANID.com', date: '90일', type: '퍼스트 파티'},
    {pid: '2', id: '1', name: '_fbp', host: 'ZANID.com', date: '90일', type: '퍼스트 파티'},
  ];
  return (
    <>
      <ToggleItemList isActive={activeItem === 'item1'} onClick={() => itemClick('item1')} title="자사쿠키">
        <ul class="container-content">
          <li>
            <ul className="container-content__detail">
              <li>
                <h5>{t('detailPage.title.name')}</h5>
                <p>_fbp</p>
              </li>
              <li>
                <h5>{t('detailPage.title.host')}</h5>
                <p>ZANID.com</p>
              </li>
              <li>
                <h5>{t('detailPage.title.term')}</h5>
                <p>90 일</p>
              </li>
              <li>
                <h5>{t('detailPage.title.type')}</h5>
                <p>퍼스트 파티</p>
              </li>
            </ul>
          </li>
          <li>
            <ul className="container-content__detail">
              <li>
                <h5>{t('detailPage.title.name')}</h5>
                <p>_fbp</p>
              </li>
              <li>
                <h5>{t('detailPage.title.host')}</h5>
                <p>ZANID.com</p>
              </li>
              <li>
                <h5>{t('detailPage.title.term')}</h5>
                <p>90 일</p>
              </li>
              <li>
                <h5>{t('detailPage.title.type')}</h5>
                <p>퍼스트 파티</p>
              </li>
            </ul>
          </li>
        </ul>
      </ToggleItemList>
      <ToggleItemList isActive={activeItem === 'item2'} onClick={() => itemClick('item2')} title="WWW.NAVER.COM">
        <ul class="container-content">
          <li>
            <ul className="container-content__detail">
              <li>
                <h5>{t('detailPage.title.name')}</h5>
                <p>_fbp</p>
              </li>
              <li>
                <h5>{t('detailPage.title.host')}</h5>
                <p>ZANID.com</p>
              </li>
              <li>
                <h5>{t('detailPage.title.term')}</h5>
                <p>90 일</p>
              </li>
              <li>
                <h5>{t('detailPage.title.type')}</h5>
                <p>퍼스트 파티</p>
              </li>
            </ul>
          </li>
          <li>
            <ul className="container-content__detail">
              <li>
                <h5>{t('detailPage.title.name')}</h5>
                <p>_fbp</p>
              </li>
              <li>
                <h5>{t('detailPage.title.host')}</h5>
                <p>ZANID.com</p>
              </li>
              <li>
                <h5>{t('detailPage.title.term')}</h5>
                <p>90 일</p>
              </li>
              <li>
                <h5>{t('detailPage.title.type')}</h5>
                <p>퍼스트 파티</p>
              </li>
            </ul>
          </li>
        </ul>
      </ToggleItemList>
      <ToggleItemList isActive={activeItem === 'item3'} onClick={() => itemClick('item3')} title="WWW.ZAIND.COM">
        <ul class="container-content">
          <li>
            <ul className="container-content__detail">
              <li>
                <h5>{t('detailPage.title.name')}</h5>
                <p>_fbp</p>
              </li>
              <li>
                <h5>{t('detailPage.title.host')}</h5>
                <p>ZANID.com</p>
              </li>
              <li>
                <h5>{t('detailPage.title.term')}</h5>
                <p>90 일</p>
              </li>
              <li>
                <h5>{t('detailPage.title.type')}</h5>
                <p>퍼스트 파티</p>
              </li>
            </ul>
          </li>
          <li>
            <ul className="container-content__detail">
              <li>
                <h5>{t('detailPage.title.name')}</h5>
                <p>_fbp</p>
              </li>
              <li>
                <h5>{t('detailPage.title.host')}</h5>
                <p>ZANID.com</p>
              </li>
              <li>
                <h5>{t('detailPage.title.term')}</h5>
                <p>90 일</p>
              </li>
              <li>
                <h5>{t('detailPage.title.type')}</h5>
                <p>퍼스트 파티</p>
              </li>
            </ul>
          </li>
        </ul>
      </ToggleItemList>
    </>
  );

  // 쿠키상세정보 아코디언 아이템리스트
  function ToggleItemList({isActive, title, onClick, children}) {
    return (
      <ul className="body-box">
        <li className={`body-box__container ${isActive ? 'on' : ''}`} onClick={onClick}>
          <div className="container-btn">
            <h4>{title}</h4>
            <div className="btn-group">
              <button>
                {t('detailPage.show')}
                <span className="btn-arrow">아래 화살표</span>
              </button>
            </div>
          </div>
          {isActive && children}
        </li>
      </ul>
    );
  }
}

export default CookieDetail;
