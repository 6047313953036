import {Outlet} from 'react-router-dom';

function ThiraInquiry(props) {
  return (
    <div className="ThiraInquiry">
      <Outlet name={props.name} />
    </div>
  );
}

export default ThiraInquiry;
