import {useEffect, useState} from 'react';

// img
import TopBtnWhiteSvg from '../../assets/images/common/top_icon_white.svg';
import TopBtnGraySvg from '../../assets/images/common/top_icon_gray.svg';

function ThiraFloat(props, ref) {
  // top 버튼
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.pageYOffset > 0 ? setScrollPosition(true) : setScrollPosition(false);
    });
  }, [scrollPosition]);

  // hover
  const [isHover, setIsHover] = useState(false);

  return (
    <aside className={scrollPosition > 0 ? 'ThiraFloat active' : 'ThiraFloat'}>
      <button
        type="button"
        onClick={() => {
          scrollTop();
        }}
        onMouseOver={() => setIsHover(true)}
        onMouseOut={() => setIsHover(false)}
      >
        {/* PC 아이콘 */}
        <img className="pc-block" src={isHover ? TopBtnWhiteSvg : TopBtnGraySvg} alt="최상단으로 이동" />
        {/* Mobile 아이콘 */}
        <img className="mo-block" src={TopBtnGraySvg} alt="최상단으로 이동" />
      </button>
    </aside>
  );
}

export default ThiraFloat;
