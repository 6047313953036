import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';
import ThiraTable from '../contents/ThiraTable';
// img
import BannerPng from '../../assets/images/main/banner.png';

function ThiraMainCompare() {
  // 기본 언어
  const {t} = useTranslation('main');
  const lang = i18next.language; // 현재 보여지는 언어
  // 팝업 열기
  const [isActiveModal, setIsActiveModal] = useState(false);

  return (
    <section className="ThiraMainCompare section">
      <div className="inner-width">
        <hgroup>
          <h3 className="main-title" data-aos="fade-up">
            {t('mainCompare.main.title')}
          </h3>
          <p className="main-desc" data-aos="fade-up" data-aos-delay="300">
            {t('mainCompare.main.desc')}
          </p>
        </hgroup>
        <ThiraTable />
        <div className="ThiraMainCompare__banner">
          <Link to={`/${lang}/inquiry`}>
            <img src={BannerPng} alt="배너 링크로 가기" />
            <div className="ThiraMainCompare__banner-text pc-block" data-aos="fade-left">
              <p>{t('mainCompare.banner.txt1')}</p>
              <p>
                <b>{t('mainCompare.banner.txt2')}</b>
              </p>
            </div>
            <div className="ThiraMainCompare__banner-text mo-block" data-aos="zoom-in">
              <p>{t('mainCompare.banner.txt1')}</p>
              <p>
                <b>{t('mainCompare.banner.txt2')}</b>
              </p>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default ThiraMainCompare;
