import React from 'react';
import {useIntl} from 'react-intl'; // 다국어
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';
import {ThiraTitle} from '../../component/common/index';
import ThiraBanner from '../../component/contents/banner';
import LineWrap from '../../component/common/lineWrap';
//img
import banner01 from '../../assets/images/about/banner_logistics.jpg';
import IndustryBanner01 from '../../assets/images/industry/manufacture_banner_01.jpg';
import IndustryBanner02 from '../../assets/images/industry/manufacture_banner_02.jpg';
import IndustryBanner03 from '../../assets/images/industry/manufacture_banner_03.jpg';
import IndustryBanner04 from '../../assets/images/industry/manufacture_banner_04.jpg';
import IndustryBanner05 from '../../assets/images/industry/manufacture_banner_05.jpg';

function ThiraIndustryManufacture() {
  // aos DelayTime
  const delayAos = '200';

  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어
  // 다국어 세팅
  const {t} = useTranslation('industry');

  const linkData = [
    {
      linkName: t('manufacture.linkList.0'),
      linkUrl: `/${lang}/industry/manufacture`,
    },
    {
      linkName: t('manufacture.linkList.1'),
      linkUrl: `/${lang}/industry/manufacture`,
    },
  ];

  // 배너 컴포넌트
  const bannerData = {
    bannerTitle: t('manufacture.banner.title'),
    bannerList: [
      {
        url: `/${lang}/industry/logistics`,
        background: banner01,
        text: t('manufacture.banner.bannerList.0'),
      },
    ],
  };

  return (
    <div className="ThiraIndustryManufacture">
      <div className="inner-width">
        <ThiraTitle title={t('manufacture.title')} navigation={true} linkData={linkData} />
      </div>
      <hr className="ThiraBorder" />
      <div className="inner-width">
        {/* 1 */}
        <p className="txt-01" data-aos="fade-left">
          {t('manufacture.descriptions.0.title')}
        </p>
        <p className="txt-02" data-aos="fade-left" data-aos-delay={delayAos * 1}>
          <LineWrap text={t('manufacture.descriptions.0.body')} />
        </p>
        <figure className="img-wrap" data-aos="fade-left">
          <img src={IndustryBanner01} alt="banner img" />
        </figure>
        {/* 2 */}
        <p className="txt-01" data-aos="fade-left">
          {t('manufacture.descriptions.1.title.0')}
        </p>
        <p className="txt-02" data-aos="fade-left">
          {t('manufacture.descriptions.1.title.1')}
        </p>
        <p className="txt-02" data-aos="fade-left" data-aos-delay={delayAos * 1}>
          <LineWrap text={t('manufacture.descriptions.1.body')} />
        </p>
        <p className="txt-01" data-aos="fade-left">
          {t('manufacture.descriptions.2.title')}
        </p>
        <ol className="ThiraList ThiraList--slashNum">
          <li data-aos="fade-left" data-aos-delay={delayAos * 2}>
            {t('manufacture.descriptions.2.body.0')}
          </li>

          <li data-aos="fade-left" data-aos-delay={delayAos * 3}>
            {t('manufacture.descriptions.2.body.1')}
          </li>
        </ol>
        <div className="youtube-wrap">
          <div className="youtube" data-aos="fade-left">
            <img src={IndustryBanner02} alt="banner img" />
          </div>
          <div className="youtube" data-aos="fade-left" data-aos-delay={delayAos * 1}>
            <img src={IndustryBanner03} alt="banner img" />
          </div>
        </div>
        {/* 3 */}
        <p className="txt-01" data-aos="fade-left">
          {t('manufacture.descriptions.3.title.0')}
        </p>
        <p className="txt-02" data-aos="fade-left">
          {t('manufacture.descriptions.3.title.1')}
        </p>
        <p className="txt-02" data-aos="fade-left" data-aos-delay={delayAos * 1}>
          <LineWrap text={t('manufacture.descriptions.3.body')} />
          {t('manufacture.descriptions.4.title')}
        </p>
        <ol className="ThiraList ThiraList--slashNum">
          <li data-aos="fade-left">{t('manufacture.descriptions.4.body.0')}</li>
          <li data-aos="fade-left">{t('manufacture.descriptions.4.body.1')}</li>
          <li data-aos="fade-left">{t('manufacture.descriptions.4.body.2')}</li>
        </ol>
        <div className="youtube-wrap">
          <figure className="youtube" data-aos="fade-left">
            <img src={IndustryBanner04} alt="banner img" />
          </figure>
          <figure className="youtube" data-aos="fade-left" data-aos-delay={delayAos * 1}>
            <img src={IndustryBanner05} alt="banner img" />
          </figure>
        </div>
        <p className="txt-02">{t('manufacture.descriptions.5.body')}</p>
      </div>
      <hr className="ThiraBorder custom-hr" />
      <ThiraBanner data={bannerData} />
    </div>
  );
}

export default ThiraIndustryManufacture;
