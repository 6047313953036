import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import {ThiraTitle} from '../../component/common/index';
import * as AmrJs from '../../assets/js/amr';
import {ThiraAmrLocation} from '../../component/amr/index';
import axios from 'axios';
import {useIntl} from 'react-intl'; // 다국어
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';
// Import Swiper
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {Parallax, Navigation, Pagination} from 'swiper';
import Video from '../../assets/images/amr/THIIRA_Case Studies_main.mp4';
import Image from '../../assets/images/amr/case_01.png';

import CaseBanner from '../../component/contents/caseBanner';

function ThiraAmrAppCase(props) {
  useEffect(() => {
    AmrJs.lazyLoad();
  });

  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어
  // 다국어 세팅
  const {t} = useTranslation('amr');

  const board_id = 4;

  const [banner, setBanner] = useState(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/board/${board_id}/banner`)
      .then(response => {
        setBanner(response.data.list);
      })
      .catch(() => {
        console.log('banner Error!');
      });
  }, []);

  const [list, setList] = useState(null);
  const [paging, setPaging] = useState({final: 1});
  const page = useRef(1);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/board/${board_id}?lang=${i18next.language}&pnum=6`)
      .then(response => {
        setList(response.data.list);
        setPaging(response.data.paging);
      })
      .catch(() => {
        console.log('list Error!');
      });
  }, []);

  const handleClickMore = () => {
    if (page.current < paging.final) {
      page.current += 1;
      axios
        .get(`${process.env.REACT_APP_API}/board/${board_id}?lang=${i18next.language}&pnum=6&page=${page.current}`)
        .then(response => {
          setList(prev => [...prev, ...response.data.list]);
          setPaging(response.data.paging);
        })
        .catch(() => {
          console.log('more Error!');
        });
    }
  };

  // 테스트 데이터
  const testCase = [
    {id: '0', thumb_img: Image, title: '파카코리아'},
    {id: '1', thumb_img: Image, title: '파카코리아'},
    {id: '2', thumb_img: Image, title: '파카코리아'},
    {id: '3', thumb_img: Image, title: '파카코리아'},
    {id: '4', thumb_img: Image, title: '파카코리아'},
    {id: '5', thumb_img: Image, title: '파카코리아'},
    {id: '6', thumb_img: Image, title: '파카코리아'},
    {id: '7', thumb_img: Image, title: '파카코리아'},
    {id: '8', thumb_img: Image, title: '파카코리아'},
    {id: '9', thumb_img: Image, title: '파카코리아'},
    {id: '10', thumb_img: Image, title: '파카코리아'},
    {id: '11', thumb_img: Image, title: '파카코리아'},
  ];

  return (
    <>
      <div className="ThiraAmrAppCase">
        <div className="inner-width">
          <ThiraAmrLocation name={t('case.title')} link="CaseStudies"></ThiraAmrLocation>

          <ThiraTitle title={t('case.title')}></ThiraTitle>
        </div>

        <hr className="ThiraBorder" />
        {/* 티라 caseStudies 동영상 추가 20230706 */}
        {/* 자동재생 전체 채우도록 수정 20230713 */}
        <div className="thira-caseStudies-vidio">
          <figure>
            <video loop autoPlay muted playsInline>
              <source src={Video} type="video/mp4" />
            </video>
          </figure>
        </div>
        {/* <div className="ThiraAmrAppCase__nav">
        <div className="inner-width">
          <Link to="#prev" className="amr-swiper-button-prev">
            <span>Prev Banner</span>
          </Link>
          <h3 className="txt-01">Banner Title</h3>
          <Link to="#next" className="amr-swiper-button-next">
            <span>Next Title</span>
          </Link>
        </div>
      </div>
      {banner?.length > 0 && (
        <Swiper
          speed={600}
          parallax={true}
          watchOverflow={true}
          loop={true}
          spaceBetween={50}
          centeredSlides={true}
          slidesPerView={'auto'}
          pagination={true}
          navigation={{
            nextEl: '.amr-swiper-button-next',
            prevEl: '.amr-swiper-button-prev',
          }}
          onTransitionStart={swiper => {
            const $prevSlide = swiper.slides[swiper.realIndex - 1];
            const $activeSlide = swiper.slides[swiper.realIndex];
            const $nextSlide = swiper.slides[swiper.realIndex + 1];
            const $target = document.querySelector('.ThiraAmrAppCase__nav');
            let prevTit = '';
            let activeTit = $activeSlide.querySelector('img').getAttribute('alt');
            let nextTit = '';
            // 타이틀 값 구하기
            if ($prevSlide) {
              prevTit = $prevSlide.querySelector('img').getAttribute('alt');
            } else {
              prevTit = document.querySelector('.ThiraAmrAppCase__swiper .swiper-slide:last-of-type').querySelector('img').getAttribute('alt');
            }
            if ($nextSlide) {
              nextTit = $nextSlide.querySelector('img').getAttribute('alt');
            }
            // 타이틀 변경
            $target.querySelector('.amr-swiper-button-prev span').innerText = prevTit;
            $target.querySelector('.txt-01').innerText = activeTit;
            $target.querySelector('.amr-swiper-button-next span').innerText = nextTit;
          }}
          modules={[Parallax, Navigation, Pagination]}
          className="ThiraAmrAppCase__swiper"
        >
          {banner?.map(item => (
            <SwiperSlide key={`case_banner_${item.id}`}>
              <Link to={`detail?board_id=${item.board_id}&contents_id=${item.id}`}>
                <figure className="bg">
                  <img src={`/storage/${item.banner_img}`} alt={item.title} />
                </figure>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      )} */}
        <div className="ThiraAmrAppCase__result">
          <div className="inner-width">{list?.length > 0 && <h4 className="txt-01">{t('case.title')}</h4>}</div>
          <div className="inner-width">
            {list?.length > 0 && (
              <>
                <ul className="ThiraAmrAppCase__list">
                  {list?.map(item => (
                    <li className="ThiraAmrAppCase__item" key={`case_list_${item.id}`}>
                      <Link to={`detail?board_id=${board_id}&contents_id=${item.id}`} className="ThiraAmrAppCase__link">
                        <figure className="ThiraAmrAppCase__thum">
                          <img src={`/storage/${item.thumb_img}`} alt="이미지" />
                        </figure>
                        <p className="txt-03">{item.title}</p>
                      </Link>
                    </li>
                  ))}
                </ul>
                {page.current < paging.final && (
                  <button className="ThiraAmrAppCase__more pc-flex" onClick={handleClickMore}>
                    {t('case.button')}
                  </button>
                )}
              </>
            )}
            {/* {testCase?.length > 0 && (
              <>
                <ul className="ThiraAmrAppCase__list">
                  {testCase?.map(item => (
                    <li className="ThiraAmrAppCase__item" key={item.id}>
                      <Link to={item.id} className="ThiraAmrAppCase__link">
                        <figure className="ThiraAmrAppCase__thum">
                          <img src={item.thumb_img} alt="이미지" />
                        </figure>
                        <p className="txt-03">{item.title}</p>
                      </Link>
                    </li>
                  ))}
                </ul>
              </>
            )} */}
          </div>
        </div>
        {/* PUB :: 20230517 추가 -> 20230706 다시제거 */}
        {/* <div className="ThiraAmrAppCase__nav">
        <div className="inner-width">
          <CaseBanner />
        </div>
      </div> */}
        {/* //PUB :: 20230517 추가 -> 20230706 다시제거*/}
      </div>
    </>
  );
}

export default ThiraAmrAppCase;
