import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {ThiraTitle} from '../../component/common/index';
import {ThiraAmrLocation} from '../../component/amr/index';
import * as AmrJs from '../../assets/js/amr';
import {useIntl} from 'react-intl'; // 다국어
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';
// rc-slider
import 'rc-slider/assets/index.css';
// import Slider, {createSliderWithTooltip, createRangeWithTooltip, Range, Handle} from 'rc-slider';
import CaseBanner from '../../component/contents/caseBanner';
// IMG
import T200 from '../../assets/images/product/Tseries/T200/T-200_01.png';
import T600 from '../../assets/images/product/Tseries/T600/T-600_01.png';
import T1000 from '../../assets/images/product/Tseries/T1000/T-1000_01.png';
import L200 from '../../assets/images/product/Lseries/L200/L-200_01.png';
import LineWrap from '../../component/common/lineWrap';

function ThiraAmrROI() {
  const navigate = useNavigate(); //페이지 이동
  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어
  // 다국어 세팅
  const {t} = useTranslation('amr');

  useEffect(() => {
    AmrJs.lazyLoad();
  });

  // 체크 리스트 받기
  const [checkedList, setCheckedList] = useState([]);
  const onCheckedElement = (checked, item) => {
    if (checked) {
      setCheckedList([...checkedList, item]);
    } else if (!checked) {
      setCheckedList(checkedList.filter(el => el !== item));
    }
  };

  // console.log(LANG.Read_Our_Case_Studies);
  // robot Data
  const RobotData = [
    {
      id: 'T200/300',
      img: T200,
      name: 'T200/ 300 Model',
      payLoad: ['T200 [200kg (441lbs)]', 'T300 [300kg (661lbs)]'],
    },
    {
      id: 'T600',
      img: T600,
      name: 'T600 Model',
      payLoad: ['600kg (1,323lbs)'],
    },
    {
      id: 'T1000',
      img: T1000,
      name: 'T1000 Model',
      payLoad: ['1000kg (2,205lbs)'],
    },
    {
      id: 'L200',
      img: L200,
      name: 'L200 Model',
      payLoad: ['200kg (441lbs)'],
    },
  ];

  // function log(value) {
  //   console.log(value); //eslint-disable-line
  // }
  // DEV :: rc-slider 사용하여 작업했습니다!
  const Slider = require('rc-slider');
  const createSliderWithTooltip = Slider.createSliderWithTooltip;
  const Range = createSliderWithTooltip(Slider.Range);

  let details = {};
  const handleSetDetails = (key, value) => (details[key] = value);

  const handleClickCalculate = () => {
    const ROIData = {
      robot: RobotData.filter(item => checkedList.includes(item.id)).map(item => item.name),
      ...details,
    };
    localStorage.setItem('ROIData', JSON.stringify(ROIData));
    navigate(`/${lang}/inquiry`);
  };
  return (
    <div className="ThiraAmrROI">
      <div className="inner-width">
        <ThiraAmrLocation name={t('roi.title')} link="ROI"></ThiraAmrLocation>
        <ThiraTitle title={t('roi.title')} />
      </div>
      <hr className="ThiraBorder" />
      <div className="inner-width">
        <form action="">
          {/* <p className="ThiraAmrROI__title">{LANG.subTitle}</p> */}
          <fieldset>
            <legend>
              {t('roi.descriptions.0.title')}
              <br />
              <b>{t('roi.descriptions.0.body')}</b>
            </legend>
            <hr />
            <ul className="select-list">
              {RobotData.map(item => {
                return (
                  <li key={item.id}>
                    <input
                      id={item.id}
                      type="checkbox"
                      value={item.id}
                      onChange={e => {
                        onCheckedElement(e.target.checked, e.target.value);
                        // console.log(checkedList, e.target.checked, e.target.value);
                      }}
                    />
                    <label htmlFor={item.id}>
                      <div className="select-list__thum">
                        <img src={item.img} alt={item.name + '이미지'} />
                      </div>
                      <div className="line-animation">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                      <p className="select-list__name">{item.name}</p>
                    </label>
                    <div className="select-list__detail">
                      {item.payLoad?.map((list, idx) => {
                        return (
                          <dl key={idx}>
                            <dt> {idx === 0 ? 'Payload : ' : ''} </dt>
                            <dd>{list}</dd>
                          </dl>
                        );
                      })}
                    </div>
                  </li>
                );
              })}
            </ul>
          </fieldset>
          {checkedList.length > 0 && (
            <React.Fragment>
              <fieldset>
                <legend>
                  {t('roi.descriptions.1.title')}
                  <br />
                  <b>{t('roi.descriptions.1.body')}</b>
                </legend>
                <hr />
                <ul className="input-list">
                  <li className="input-list__wrap">
                    <label htmlFor="num">{t('roi.question.0')}</label>
                    {/* <Slider
                      range
                      className="range-slider"
                      min={15000}
                      max={20000}
                      allowCross={true} // 교차 허용
                      defaultValue={[0, 100]}
                      // tipFormatter={percentFormatter}
                      tipProps={{overlayClassName: 'foo'}}
                      onChange={log}
                      // tipFormatter={value => `${value}%`}
                      // value={}
                      // onChange={handleChange}
                    /> */}
                    <Range
                      range
                      className="range-slider"
                      min={1}
                      max={1000}
                      marks={{1: 1, 1000: 1000}}
                      allowCross={false}
                      draggableTrack
                      defaultValue={handleSetDetails('d01', [0, 500])}
                      tipFormatter={value => `${value}`}
                      tipProps={({overlayClassName: 'foo'}, {prefixCls: 'rc-slider-tooltip'})}
                      onChange={newVal => {
                        handleSetDetails('d01', newVal);
                      }}
                    />
                    {/* <div className="range-warp">
                      <Range
                        className="range-slider"
                        defaultVisible
                        visible
                        min={1}
                        max={1000}
                        marks={{1: 1, 1000: 1000}}
                        defaultValue={[0, 500]}
                        allowCross={false} // 교차 허용
                        tipFormatter={value => `${value}`}
                        // tipProps={({overlayClassName: 'foo'}, {prefixCls: 'rc-slider-tooltip'})}
                        tipProps={{prefixCls: 'rc-slider-tooltip'}}
                        onChange={log}
                        toolTipVisibleAlways={true}
                        // value={pay}
                        // onChange={pay => setPay(pay)}
                      />
                    </div> */}
                  </li>
                  <li className="input-list__wrap">
                    <label htmlFor="num">{t('roi.question.1')}</label>
                    <div className="range-warp">
                      <Range
                        className="range-slider"
                        min={170}
                        max={650}
                        marks={{170: 170, 650: 650}}
                        defaultValue={handleSetDetails('d02', [0, 410])}
                        allowCross={false}
                        tipFormatter={value => `${value}`}
                        tipProps={{prefixCls: 'rc-slider-tooltip'}}
                        onChange={newVal => {
                          handleSetDetails('d02', newVal);
                        }}
                        toolTipVisibleAlways={true}
                      />
                    </div>
                  </li>
                  <li className="input-list__wrap">
                    <label htmlFor="num">{t('roi.question.2')}</label>
                    <div className="range-warp">
                      <Range
                        className="range-slider"
                        min={1}
                        max={100}
                        marks={{1: 1, 100: 100}}
                        defaultValue={handleSetDetails('d03', [0, 50])}
                        allowCross={false}
                        tipFormatter={value => `${value}`}
                        tipProps={{prefixCls: 'rc-slider-tooltip'}}
                        onChange={newVal => {
                          handleSetDetails('d03', newVal);
                        }}
                        toolTipVisibleAlways={true}
                      />
                    </div>
                  </li>
                  <li className="input-list__wrap">
                    <label htmlFor="num">{t('roi.question.3')}</label>
                    <div className="range-warp">
                      <Range
                        className="range-slider"
                        min={15}
                        max={100}
                        marks={{15: 15, 100: 100}}
                        defaultValue={handleSetDetails('d04', [0, 57])}
                        allowCross={false}
                        tipFormatter={value => `${value}`}
                        tipProps={{prefixCls: 'rc-slider-tooltip'}}
                        onChange={newVal => {
                          handleSetDetails('d04', newVal);
                        }}
                        toolTipVisibleAlways={true}
                      />
                    </div>
                  </li>
                  <li className="input-list__wrap">
                    <label htmlFor="num">{t('roi.question.4')}</label>
                    <div className="range-warp">
                      <Range
                        className="range-slider"
                        min={15}
                        max={52}
                        marks={{15: 15, 52: 52}}
                        defaultValue={handleSetDetails('d05', [0, 33])}
                        allowCross={false}
                        tipFormatter={value => `${value}`}
                        tipProps={{prefixCls: 'rc-slider-tooltip'}}
                        onChange={newVal => {
                          handleSetDetails('d05', newVal);
                        }}
                        toolTipVisibleAlways={true}
                      />
                    </div>
                  </li>
                  <li className="input-list__wrap">
                    <label htmlFor="num">{t('roi.question.5')}</label>
                    <div className="range-warp">
                      <Range
                        className="range-slider"
                        min={1}
                        max={7}
                        marks={{1: 1, 7: 7}}
                        defaultValue={handleSetDetails('d06', [0, 4])}
                        allowCross={false}
                        tipFormatter={value => `${value}`}
                        tipProps={{prefixCls: 'rc-slider-tooltip'}}
                        onChange={newVal => {
                          handleSetDetails('d06', newVal);
                        }}
                        toolTipVisibleAlways={true}
                      />
                    </div>
                  </li>
                  <li className="input-list__wrap">
                    <label htmlFor="num">{t('roi.question.6')}</label>
                    <div className="range-warp">
                      <Range
                        className="range-slider"
                        min={1}
                        max={24}
                        marks={{1: 1, 24: 24}}
                        defaultValue={handleSetDetails('d07', [0, 12])}
                        allowCross={false}
                        tipFormatter={value => `${value}`}
                        tipProps={{prefixCls: 'rc-slider-tooltip'}}
                        onChange={newVal => {
                          handleSetDetails('d07', newVal);
                        }}
                        toolTipVisibleAlways={true}
                      />
                    </div>
                  </li>
                  <li className="input-list__wrap">
                    <label htmlFor="num">{t('roi.question.7')}</label>
                    <div className="range-warp">
                      <Range
                        className="range-slider"
                        min={1}
                        max={6}
                        marks={{1: 1, 6: 6}}
                        defaultValue={handleSetDetails('d08', [0, 4])}
                        allowCross={false}
                        tipFormatter={value => `${value}`}
                        tipProps={{prefixCls: 'rc-slider-tooltip'}}
                        onChange={newVal => {
                          handleSetDetails('d08', newVal);
                        }}
                        toolTipVisibleAlways={true}
                      />
                    </div>
                  </li>
                </ul>
              </fieldset>
              <div className="button-wrap">
                <button type="button" onClick={handleClickCalculate}>
                  {t('roi.Calculate')}
                </button>
              </div>
              {/* PUB :: 20230517 추가 */}
              {/* Small Banner */}
              <div className="ThiraAmrROI__contact-banner">
                <p>
                  {/* {LANG.Contact_Banner.body.split('\n').map((line, index) => {
                    return (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    );
                  })} */}
                  <LineWrap text={t('roi.Contact_Banner.body')} />
                </p>
                <Link to="/inquiry">{t('roi.Contact_Banner.buttonText')}</Link>
              </div>
              {/* Read Our Case Studies */}
              <div className="ThiraCaseCard">
                <h4>{t('roi.Read_Our_Case_Studies.cardTitle')}</h4>
                <ul class="case-card">
                  {t('roi.Read_Our_Case_Studies.cardDatList', {returnObjects: true}).map((list, index) => {
                    return (
                      <li className="case-card__list" key={index}>
                        <b className="case-card__name">
                          {list.title}
                          <br />({list.company})
                        </b>
                        <p className="case-card__desc">{list.desc}</p>
                        <Link className="case-card__link" to="/amr/CaseStudies">
                          {t('roi.Read_Our_Case_Studies.buttonText')}
                        </Link>
                      </li>
                    );
                  })}
                  <li className="case-card__list empty mo-block"></li>
                </ul>
              </div>
              {/* // PUB :: 20230517 추가 */}
            </React.Fragment>
          )}
        </form>
      </div>
    </div>
  );
}

export default ThiraAmrROI;
