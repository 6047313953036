import {Outlet} from 'react-router-dom';
import '../../assets/css/industry.scss';

function ThiraIndustry(props) {
  return (
    <div className="ThiraIndustry">
      <Outlet name={props.name} />
    </div>
  );
}

export default ThiraIndustry;
