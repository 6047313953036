import {Outlet} from 'react-router-dom';
import '../../assets/css/amr.scss';

function ThiraAmr(props) {
  return (
    <div className="ThiraAmr">
      <Outlet name={props.name} />
    </div>
  );
}

export default ThiraAmr;
