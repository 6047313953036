import React, {useState, useEffect} from 'react';
import * as ServiceJs from '../../assets/js/service';
import {ThiraTitle} from '../../component/common/index';
import '../../assets/css/services.scss';
import {useIntl} from 'react-intl'; // 다국어
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';
import axios from 'axios';

function ThiraFaq() {
  const board_id = 5;
  const [list, setList] = useState(null);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/board/${board_id}?lang=${i18next.language}&pnum=1111`)
      .then(response => {
        setList(response.data.list);
      })
      .catch(() => {
        console.log('list Error!');
      });
  }, []);
  // 다국어 세팅
  const intl = useIntl();
  const LANG = intl.messages.SERVICES.FAQ;

  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어
  // 다국어 세팅
  const {t} = useTranslation('resource');

  const linkData = [
    {
      linkName: t('navigation.services'),
      linkUrl: `/${lang}/resource/board`,
    },
    {
      linkName: 'FAQ',
      linkUrl: `/${lang}/resource/faq`,
    },
  ];

  return (
    <div className="ThiraFaq">
      <div className="inner-width">
        <ThiraTitle title={t('FAQ.title')} navigation={true} linkData={linkData} />
      </div>
      <hr className="ThiraBorder" />
      <ul className="inner-width">
        {list?.map((li, index) => {
          return (
            <li className="ThiraFaq__cont" key={`faq${li.id}`}>
              <h3
                className="ThiraFaq__subtitle"
                onClick={e => {
                  ServiceJs.toggleArrow(e.target.closest('.ThiraFaq__subtitle'));
                }}
              >
                <span>{li.title}</span>
                <button className="arr">아래 화살표</button>
              </h3>
              <div className="ThiraFaq__toggle">
                <ul className="ThiraFaq__list">
                  <li className="ThiraFaq__item">
                    <p className="txt-01">{li.title}</p>
                    <p className="txt-02" dangerouslySetInnerHTML={{__html: li.contents}}></p>
                  </li>
                </ul>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default ThiraFaq;
