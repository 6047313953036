import {Link} from 'react-router-dom';
import ThiraSns from '../contents/sns';
import {useState, useEffect, useRef} from 'react';
import {useIntl} from 'react-intl'; // 다국어
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';
import ThiraPolicy from './privacy-policy';
import ThiraCookie from './cookie';
import Modal from '../../util/Modal';
import LineWrap from './lineWrap';

// img
import LogoPng from '../../assets/images/common/logo.png';

function ThiraFooter() {
  // 다국어 세팅
  const {t} = useTranslation('footer');
  // 다국어 세팅
  // const intl = useIntl();
  // const FOOTER_NAV_LANG = intl.messages.HEADER.gnbMenuName;
  // const FOOTER_LANG = intl.messages.FOOTER;
  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어

  const nav_url = [`${lang}/amr/`, `${lang}/industry/`, `${lang}/solutions/`, `${lang}/product/`, `${lang}/resource/`, `${lang}/about/`]; // 메뉴 데이터
  // 메뉴 데이터
  const menuList = [
    {
      id: 0,
      defaultUrl: `${lang}/amr/`,
      LnbMenuList: [`${lang}/amr/introduction`, `${lang}/amr/fms`, `${lang}/amr/solutions`, `${lang}/amr/CaseStudies`, `${lang}/amr/ROI`],
    },
    {
      id: 1,
      defaultUrl: `${lang}/industry/`,
      LnbMenuList: [`${lang}/industry/manufacture`, `${lang}/industry/logistics`],
    },
    {
      id: 2,
      defaultUrl: `${lang}/solutions/`,
      LnbMenuList: [`${lang}/solutions/AntiSlip`, `${lang}/solutions/Obstacle`, `${lang}/solutions/Elevator`, `${lang}/solutions/Logistics`, `${lang}/solutions/Following`, `${lang}/solutions/Safety`],
    },
    {
      id: 3,
      defaultUrl: `${lang}/product/`,
      LnbMenuList: [`${lang}/product/models/T-Series`, `${lang}/product/models/L-Series`, `${lang}/product/models/chargingSystem`, `${lang}/product/models/Acc`],
    },
    {
      id: 4,
      defaultUrl: `${lang}/resource/`,
      LnbMenuList: [`${lang}/resource/board`, `${lang}/resource/newsBlog`, `${lang}/resource/faq`],
    },
    {
      id: 5,
      defaultUrl: `${lang}/about/`,
      LnbMenuList: [`${lang}/about/OurStory`, `${lang}/about/Location`, `${lang}/about/partner`, `${lang}/about/certification`],
    },
  ];

  // 약관 팝업
  const [isPolicyPopupOpen, setIsPolicyPopupOpen] = useState(false);

  // 쿠키 팝업
  const [isCookiePopupOpen, setIsCookiePopupOpen] = useState(false);

  // 약관 열기
  const openPopup = () => {
    setIsPolicyPopupOpen(true);
    Modal.modalOpen();
  };
  // 약관 닫기
  const closePopup = () => {
    setIsPolicyPopupOpen(false);
    Modal.modalClosed();
  };

  // 쿠키 열기
  const openCookiePop = () => {
    setIsCookiePopupOpen(true);
  };

  // 쿠키 닫기
  const closeCookiePop = () => {
    setIsCookiePopupOpen(false);
    Modal.modalClosed();
  };

  return (
    <>
      {/* 쿠키 팝업 */}
      {isCookiePopupOpen && <ThiraCookie footerCookie={isCookiePopupOpen} closeCookiePop={closeCookiePop}></ThiraCookie>}
      {/* 약관 팝업 */}
      {isPolicyPopupOpen && <ThiraPolicy closePopup={closePopup}></ThiraPolicy>}
      <footer className="ThiraFooter">
        <div className="ThiraFooter__top">
          <div className="inner-width">
            <nav className="ThiraFooter__terms">
              <ul>
                {menuList?.map((menu, index) => (
                  <li className="terms-list" key={'list' + index}>
                    <h4>{t(`gnbMenuName.${index}.title`)}</h4>
                    <ul className="terms-content">
                      {menu.LnbMenuList?.map((sub, index2) => (
                        <li key={'content' + index2}>
                          <Link key={index2} to={sub}>
                            <LineWrap text={t(`gnbMenuName.${index}.subMenu.${index2}`)} />
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
        <div className="ThiraFooter__btm">
          <div className="inner-width">
            <div className="ThiraFooter__util">
              <div className="ThiraFooter__logo">
                <img src={LogoPng} alt="THIRA ROBOTICS " />
              </div>
            </div>
            <div className="ThiraFooter__btm__etc">
              <div className="btm-etc">
                <div className="etc-top">
                  <div className="ThiraFooter__address">
                    &#40;13403&#41; {t('address')}
                    <br />
                    <span>sales@thirarobotics.com</span> / {t('number')} / Fax: {t('fax')}
                  </div>
                </div>
                <div className="etc-bottom">
                  <p className="ThiraFooter__copyright">COPYRIGHT &copy; 2022 THIRARobotics Co., Ltd. All rights reserved.</p>
                  <p className="ThiraFooter__policy">
                    <Link type="button" onClick={openPopup}>
                      {t('privacy')}
                    </Link>
                    <span>|</span>
                    <Link type="button" onClick={openCookiePop}>
                      {t('cookie')}
                    </Link>
                  </p>
                </div>
              </div>
              <div className="ThiraFooter__sns">
                <ThiraSns />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default ThiraFooter;
