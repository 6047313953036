import {Link} from 'react-router-dom';
import {useEffect, useState} from 'react';
import Modal from '../../util/Modal';
import {useIntl} from 'react-intl'; // 다국어
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';
// img
import LogoPng from '../../assets/images/common/logo.png';
import CloseSvg from '../../assets/images/common/ico_close_white.svg';

function ThiraHeaderMobileSub({data}) {
  const {isState} = data || '';
  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어
  // 다국어 세팅
  const {t} = useTranslation('header');
  // 헤더 스크롤 이벤트
  const [isActiveScroll, setIsActiveScroll] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.pageYOffset > 0 ? setIsActiveScroll(true) : setIsActiveScroll(false);
    });
  }, [isActiveScroll]);

  // 서브메뉴 닫기
  const onClickClose = () => {
    isState.setState(false);
    Modal.modalClosed();
  };

  const [clickOpen, setClickOpen] = useState({}); // mobile 토글
  const toggleOpen = id => {
    // setClickOpen(false);
    setClickOpen(prevClickOpen => ({
      ...prevClickOpen,
      [id]: !prevClickOpen[id],
    }));
  };

  // 국영문 변경 이벤트
  const handleLanguageChange = e => {
    const selectedLanguage = e.target.value;
    localStorage.setItem('lang', selectedLanguage); //api통신용으로 세팅한건데, i18next을 사용하게되어 api에서는 더이상 사용안함.
    i18next.changeLanguage(selectedLanguage);

    const currentPath = window.location.pathname;
    let newPath;
    // 다른 언어로 변경한 경우, 언어 부분을 변경한 새로운 URL 생성
    newPath = currentPath.replace(/\/(ko|en)(?=\/|$)/, `/${selectedLanguage}`);
    // 기본 언어가 없을 경우에는 en을 추가
    if (!newPath.includes('/ko') && !newPath.includes('/en')) {
      window.location.href = `${newPath}${selectedLanguage}`;
    } else {
      // 생성한 새로운 URL로 페이지 이동
      window.location.href = newPath;
    }
  };

  // 다국어 세팅
  const intl = useIntl();
  const HEADER_LANG = intl.messages.HEADER;

  // 메뉴 데이터
  const menuList = [
    {
      id: 0,
      defaultUrl: `${lang}/amr/`,
      LnbMenuList: [`${lang}/amr/introduction`, `${lang}/amr/fms`, `${lang}/amr/solutions`, `${lang}/amr/CaseStudies`, `${lang}/amr/ROI`],
    },
    {
      id: 1,
      defaultUrl: `${lang}/industry/`,
      LnbMenuList: [`${lang}/industry/manufacture`, `${lang}/industry/logistics`],
    },
    {
      id: 2,
      defaultUrl: `${lang}/solutions/`,
      LnbMenuList: [`${lang}/solutions/AntiSlip`, `${lang}/solutions/Obstacle`, `${lang}/solutions/Elevator`, `${lang}/solutions/Logistics`, `${lang}/solutions/Following`, `${lang}/solutions/Safety`],
    },
    {
      id: 3,
      defaultUrl: `${lang}/product/`,
      LnbMenuList: [`${lang}/product/models/T-Series`, `${lang}/product/models/L-Series`, `${lang}/product/models/chargingSystem`, `${lang}/product/models/Acc`],
    },
    {
      id: 4,
      defaultUrl: `${lang}/resource/`,
      LnbMenuList: [`${lang}/resource/board`, `${lang}/resource/newsBlog`, `${lang}/resource/faq`],
    },
    {
      id: 5,
      defaultUrl: `${lang}/about/`,
      LnbMenuList: [`${lang}/about/OurStory`, `${lang}/about/Location`, `${lang}/about/partner`, `${lang}/about/certification`],
    },
  ];

  return (
    <div className="ThiraMHeaderMobileSub" data-aos="fade-right">
      <div className="ThiraMHeaderMobileSub__top">
        <div className="inner-width header-inner">
          <h1 className="ThiraMHeaderMobileSub__h1">
            <Link to="/" onClick={onClickClose}>
              <img src={LogoPng} alt="THIRA ROBOTICS " />
            </Link>
          </h1>
          <button type="button" onClick={onClickClose} className="hamburger-button">
            <img src={CloseSvg} alt="메뉴 닫기" />
          </button>
        </div>
      </div>
      <div className="inner-width header-content">
        <nav className="ThiraMHeaderMobileSub__gnb">
          <ul>
            {menuList.map((list, idx) => {
              return (
                <li className="ThiraMHeaderMobileSub__gnb-list" key={'menuList' + idx}>
                  <button className={clickOpen[list.id] ? 'active' : undefined} type="button" onClick={() => toggleOpen(list.id)}>
                    {t(`gnbMenuName.${idx}.title`)}
                  </button>
                  {clickOpen[list.id] && (
                    <ul className="ThiraMHeaderMobileSub__sub-menu">
                      {list.LnbMenuList.map((URL, index) => {
                        return (
                          <li key={'lnbMenuList' + index}>
                            <Link to={URL} onClick={onClickClose}>
                              {t(`gnbMenuName.${idx}.subMenu.${index}`)}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </nav>
        <div className="ThiraMHeaderMobileSub__util-wrap">
          <Link to={`/${lang}/inquiry`} className="contact-button" onClick={onClickClose}>
            {t('Contact_Us')}
          </Link>
          <div className="t-lang">
            <select
              className="t-sel"
              name=""
              id=""
              // onChange={e => {
              //   localStorage.setItem('lang', e.target.value);
              //   window.location.reload();
              // }}
              onChange={handleLanguageChange}
              // defaultValue={localStorage.getItem('lang')}
              value={i18next.language}
            >
              <option value="en">ENG</option>
              <option value="ko">KOR</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThiraHeaderMobileSub;
