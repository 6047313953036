import React from 'react';
import {Link} from 'react-router-dom';
import {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';
import imagePath from '../../assets/images/main/news_02.jpg';
import axios from 'axios';

function ThiraPopup(props) {
  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어

  const [apiData, setApiData] = useState(null);
  const board_id = 7;
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/board/${board_id}/popup?lang=${i18next.language}`)
      .then(response => {
        setApiData(response.data);
      })
      .catch(response => {
        console.log('top_banner Error!');
      });
  }, []);

  const checkboxRef = useRef();
  const checkboxRef_m = useRef();
  const today = (() => {
    const today = new Date();

    const year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2);
    const day = ('0' + today.getDate()).slice(-2);

    const dateString = year + '-' + month + '-' + day;
    return dateString;
  })();
  const sdns = localStorage.getItem('seven_day_no_show');
  const [isClosed, setIsClosed] = useState(sdns && today < sdns);

  const handleClose = () => {
    setIsClosed(true);
    const sevenDayNoShow = checkboxRef.current.checked || checkboxRef_m.current.checked;
    if (sevenDayNoShow) {
      let d = new Date();
      let sel_day = 7; //일자를 조절하시면 됩니다. -1이면 하루전/ +1이면 내일
      d.setDate(d.getDate() + sel_day);

      let year = d.getFullYear();
      let month = ('0' + (d.getMonth() + 1)).slice(-2);
      let day = ('0' + d.getDate()).slice(-2);
      let dt = year + '-' + month + '-' + day;
      localStorage.setItem('seven_day_no_show', dt);
    }
  };

  //   테스트데이터
  // const popupTestKr = {title: '7월 독일 슈트트가르트 전시시작!!', link: 'https://www.thirarobotics.com/about/OurStory', url: '/main/news_01.jpg'};
  // const popupTest = {title: 'July Start of exhibition in Stuttgart, Germany!!', link: 'https://www.thirarobotics.com/solutions/AntiSlip', url: '/main/news_02.jpg'};

  const imgUrl = apiData && apiData.contents_img ? `/storage/${apiData.contents_img}` : null;
  return (
    <React.Fragment>
      {!isClosed && apiData && (
        <>
          {/* PC */}
          <aside className="popup-overlay">
            <div className="popup-content">
              <div className="popup-content__img">
                <Link to={apiData.link}>
                  <img src={imgUrl} alt={apiData.title} />
                  <h2>{apiData.title}</h2>
                </Link>
              </div>
              <div className="popup-content__btn">
                <label>
                  <input type="checkbox" ref={checkboxRef} />
                  <span className="on"></span>
                  {lang === 'ko' ? '7일간 보지 않기' : "don't watch for 7 days"}
                </label>
                <button onClick={handleClose}>{lang === 'ko' ? '닫기' : 'close'}</button>
              </div>
            </div>
          </aside>
          {/* 모바일 */}
          <aside className="popup-overlay-mo">
            <div className="popup-content">
              <div className="popup-content__img">
                <Link to={apiData.link}>
                  <img src={imgUrl} alt={apiData.title} />
                  <h2>{apiData.title}</h2>
                </Link>
              </div>
              <div className="popup-content__btn">
                <label>
                  <input type="checkbox" ref={checkboxRef_m} />
                  <span className="on"></span>
                  {lang === 'ko' ? '7일간 보지 않기' : "don't watch for 7 days"}
                </label>
                <button onClick={handleClose}>{lang === 'ko' ? '닫기' : 'close'}</button>
              </div>
            </div>
          </aside>
        </>
      )}
    </React.Fragment>
  );
}

export default ThiraPopup;
