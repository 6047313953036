import React from 'react';

// 줄바꿈 치환
const LineWrap = ({text}) => {
  return (
    <span>
      {text.split('\n').map((txt, index) => (
        <React.Fragment key={index}>
          {txt}
          <br />
        </React.Fragment>
      ))}
    </span>
  );
};

export default LineWrap;
