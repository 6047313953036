import {Routes, Route} from 'react-router-dom';
import LanguageInitializer from '../component/main/languageInitializer';
import i18next from '../i18n';

// 메인
import ThiraMain from '../views/main/main';

// AMR
import ThiraAmr from '../views/amr/amr';
import ThiraAmrIntroduction from '../views/amr/introduction';
import ThiraAmrFms from '../views/amr/fms';
import ThiraAmrSolutions from '../views/amr/solutions';
import ThiraAmrAppCase from '../views/amr/applicationCase';
import ThiraAmrAppDetail from '../views/amr/applicationCaseDetail';
import ThiraAmrROI from '../views/amr/roi';

// 비즈니스 라인
import ThiraIndustry from '../views/industry/industry';
import ThiraIndustryManufacture from '../views/industry/industryManufacture';
import ThiraIndustryLogistics from '../views/industry/industryLogistics';

// 솔루션
import ThiraSolutions from '../views/solutions/solutions';
import {ThiraSolutionsAntiSlip, ThiraSolutionsObstacle, ThiraSolutionsElevator, ThiraSolutionsLogistic, ThiraSolutionsSafety, ThiraSolutionsFollowing} from '../component/solutions/index';

// 제품 소개
import ThiraProduct from '../views/product/product';
import ThiraProductModels from '../views/product/models';
import ThiraProductDetail from '../views/product/detail';
import {
  ThiraProductT200,
  ThiraProductT300,
  ThiraProductT600,
  ThiraProductT1000,
  ThiraProductT1000F,
  ThiraProductL200,
  ThiraProductChargingSystem,
  ThiraProductL200Lidar,
  ThiraProductT300Arm,
  ThiraProductT300ConveyorA,
  ThiraProductT300ConveyorB,
  ThiraProductT300Lift,
  ThiraProductT1000Conveyor,
} from '../component/product/index';

// 서비스
import ThiraService from '../views/services/service';
import ThiraBoardList from '../views/services/boardList';
import ThiraBoardSearchResult from '../views/services/boardSearchResult';
import ThiraBoardDetail from '../views/services/detail';
import ThiraNewsBlog from '../views/services/newsBlog';
import ThiraNewsBlogSearchReult from '../views/services/newsBlogSearchResult';
import ThiraNewsBlogDetail from '../views/services/newsBlogDetail';
import ThiraFaq from '../views/services/faq';

// 회사 소개
import ThiraAbout from '../views/about/about';
import ThiraAboutLocation from '../views/about/location';
import ThiraAboutPartner from '../views/about/partner';
import ThiraAboutOurStory from '../views/about/ourStory';
import ThiraCertification from '../views/about/certification';

// 문의
import ThiraInquiry from '../views/inquiry/inquiry';
import ThiraInquiryForm from '../views/inquiry/inquiryForm';
import ThiraInquiryComplete from '../views/inquiry/inquiryComplete';

// 페이지를 찾을 수 없습니다.
import ThiraNotFound from '../views/common/notFound';

const lang = i18next.language;
function ThiraRouter() {
  return (
    <Routes>
      {/* 메인 */}
      <Route path={`/${lang}`} element={<ThiraMain />} />
      <Route path={`/`} element={<ThiraMain />} />

      {/* AMR */}
      <Route path={`/${lang}`} element={<ThiraAmr />}>
        <Route path="amr/introduction" element={<ThiraAmrIntroduction />}></Route>
        <Route path="amr/fms" element={<ThiraAmrFms />}></Route>
        <Route path="amr/solutions" element={<ThiraAmrSolutions />}></Route>
        <Route path="amr/CaseStudies" element={<ThiraAmrAppCase />}></Route>
        <Route path="amr/CaseStudies/detail" element={<ThiraAmrAppDetail />}></Route>
        <Route path="amr/ROI" element={<ThiraAmrROI />}></Route>
      </Route>

      {/* Industry */}
      <Route path={`/${lang}`} element={<ThiraIndustry />}>
        <Route path="industry/manufacture" element={<ThiraIndustryManufacture name="Industry Manufacture" />}></Route>
        <Route path="industry/logistics" element={<ThiraIndustryLogistics name="Industry Distribution" />}></Route>
      </Route>

      {/* 솔루션 */}
      <Route path={`/${lang}`} element={<ThiraSolutions />}>
        <Route path="solutions/AntiSlip" element={<ThiraSolutionsAntiSlip name="Anti Slip" />}></Route>
        <Route path="solutions/Obstacle" element={<ThiraSolutionsObstacle name="Uneven surface driving" />}></Route>
        <Route path="solutions/Elevator" element={<ThiraSolutionsElevator name="Overcoming steps, Taking elevators" />}></Route>
        <Route path="solutions/Logistics" element={<ThiraSolutionsLogistic name="Logistics automation" />}></Route>
        <Route path="solutions/Following" element={<ThiraSolutionsFollowing name="Following function" />}></Route>
        <Route path="solutions/Safety" element={<ThiraSolutionsSafety name="Triple Safety System" />}></Route>
      </Route>

      {/* 제품 소개 */}
      <Route path={`/${lang}`} element={<ThiraProduct />}>
        <Route path="product/models" element={<ThiraProductModels />}>
          <Route path=":models" element={<ThiraProductModels />}></Route>
        </Route>
        <Route path="product/detail" element={<ThiraProductDetail />}>
          <Route path="T200" element={<ThiraProductT200 name="T200" />}></Route>
          <Route path="T300" element={<ThiraProductT300 name="T300" />}></Route>
          <Route path="T600" element={<ThiraProductT600 name="T600" />}></Route>
          <Route path="T1000" element={<ThiraProductT1000 name="T1000" />}></Route>
          <Route path="T1000F" element={<ThiraProductT1000F name="T1000F" />}></Route>
          <Route path="L200" element={<ThiraProductL200 name="L200" />}></Route>
          <Route path="chargingSystem" element={<ThiraProductChargingSystem name="Charging system" />}></Route>
          <Route path="T300_arm" element={<ThiraProductT300Arm name="T300 Arm" />}></Route>
          <Route path="T300_conveyor_A" element={<ThiraProductT300ConveyorA name="T300 ConveyorA" />}></Route>
          <Route path="T300_conveyor_B" element={<ThiraProductT300ConveyorB name="T300 ConveyorB" />}></Route>
          <Route path="T300_lift" element={<ThiraProductT300Lift name="T300 Lift" />}></Route>
          <Route path="T1000_conveyor" element={<ThiraProductT1000Conveyor name="T1000 Conveyor" />}></Route>
          <Route path="L200_lidar" element={<ThiraProductL200Lidar name="L200 Lidar" />}></Route>
        </Route>
      </Route>

      {/* 서비스 */}
      <Route path={`/${lang}`} element={<ThiraService />}>
        <Route path="resource/board" element={<ThiraBoardList name="Board" />}></Route>
        <Route path="resource/board/searchResult" element={<ThiraBoardSearchResult name="Board Search Result" />}></Route>
        <Route path="resource/board/detail" element={<ThiraBoardDetail name="Board Detail" />}></Route>
        <Route path="resource/newsBlog" element={<ThiraNewsBlog name="News Blog" />}></Route>
        <Route path="resource/newsBlog/searchResult" element={<ThiraNewsBlogSearchReult name="News Blog Search Result" />}></Route>
        <Route path="resource/newsBlog/detail" element={<ThiraNewsBlogDetail name="News Blog Detail" />}></Route>
        <Route path="resource/faq" element={<ThiraFaq name="FAQ" />}></Route>
      </Route>

      {/* 회사소개 */}
      <Route path={`/${lang}`} element={<ThiraAbout />}>
        <Route path="about/OurStory" element={<ThiraAboutOurStory name="Our Story" />}></Route>
        <Route path="about/Location" element={<ThiraAboutLocation name="Location" />}></Route>
        <Route path="about/partner" element={<ThiraAboutPartner name="Partner Introduction" />}></Route>
        <Route path="about/certification" element={<ThiraCertification name="Certification" />}></Route>
      </Route>

      {/* 문의 */}
      <Route path={`/${lang}`} element={<ThiraInquiry />}>
        <Route path="inquiry" element={<ThiraInquiryForm name="Inquiry" />}></Route>
        <Route path="inquiry/complete" element={<ThiraInquiryComplete name="Inquiry Complete" />}></Route>
      </Route>

      {/* 페이지를 찾을 수 없습니다. */}
      <Route path="/*" element={<ThiraNotFound />}></Route>
    </Routes>
  );
}

export default ThiraRouter;
