import React, {useEffect, useState} from 'react';
import * as ProductJs from '../../assets/js/product';
// import {useIntl} from 'react-intl'; // 다국어
import {useTranslation} from 'react-i18next';
// import i18next from '../../i18n';
import ThiraProductLocation from '../../component/product/location';
import ThumbNailModal from '../modal/ThumbNailModal';
import Modal from '../../util/Modal';
import LineWrap from '../../component/common/lineWrap';

// IMG
import T200_1 from '../../assets/images/product/Tseries/T200/T-200_01.png';
import T200_2 from '../../assets/images/product/Tseries/T200/T-200_02.png';
import T200_3 from '../../assets/images/product/Tseries/T200/T-200_03.png';
import T200_4 from '../../assets/images/product/Tseries/T200/T-200_04.png';
import T200_5 from '../../assets/images/product/Tseries/T200/T-200_05.png';
import T200_6 from '../../assets/images/product/Tseries/T200/T-200_06.png';
import T200_7 from '../../assets/images/product/Tseries/T200/T-200_07.png';
import T200_8 from '../../assets/images/product/Tseries/T200/T-200_08.png';
import T200_9 from '../../assets/images/product/Tseries/T200/T-200_09.png';
import DetailPng from '../../assets/images/product/Tseries/T200/T-200_detail.png';
import DetailMobPng from '../../assets/images/product/Tseries/T200/T-200_detail_mob.png';
// import Video from '../../assets/images/product/Tseries/T200/T200_video.mp4';
function ThiraProductT200(props) {
  // 기본 언어
  // const lang = i18next.language; // 현재 보여지는 언어
  // 다국어 세팅
  const {t} = useTranslation('product');

  useEffect(() => {
    ProductJs.showTitle();
    ProductJs.showThumbs();
  });

  // 팝업 열기
  const [isActiveModal, setIsActiveModal] = useState(false);
  const onClickModalOpen = () => {
    setIsActiveModal(true);
    Modal.modalOpen();
  };

  const ContentImgList = [
    {img: T200_1, name: 'T200_1'},
    {img: T200_2, name: 'T200_2'},
    {img: T200_3, name: 'T200_3'},
    {img: T200_4, name: 'T200_4'},
    {img: T200_5, name: 'T200_5'},
    {img: T200_6, name: 'T200_6'},
    {img: T200_7, name: 'T200_7'},
    {img: T200_8, name: 'T200_8'},
    {img: T200_9, name: 'T200_9'},
  ];

  const ModalData = {
    isState: {
      state: isActiveModal,
      setState: setIsActiveModal,
    },
    SlideContent: ContentImgList,
  };

  // 토글 리스트
  // const [isExpanded, setIsExpanded] = useState(false);

  // const toggleContent = () => {
  //   setIsExpanded(!isExpanded);
  // };

  return (
    <React.Fragment>
      <ThumbNailModal data={ModalData} />
      <ThiraProductLocation default={props.name}></ThiraProductLocation>

      {/* PC */}
      <div className="ThiraProductDetail__top pc-flex">
        <div className="ThiraProductDetail__bigThum">
          <img src={T200_1} alt="썸네일" />
        </div>
        <div className="ThiraProductDetail__topInf">
          <h2 className="ThiraProductDetail__title">{props.name}</h2>
          <p className="ThiraProductDetail__desc">{t('t200.productDetail.desc')}</p>
          <div className="ThiraProductDetail__smallThum">
            {ContentImgList?.filter((item, index) => index < 4).map((item, index) => {
              return (
                <button
                  key={'contentImg' + index}
                  data-name={item.name}
                  onClick={e => {
                    ProductJs.showPopup(e.target.closest('button'));
                  }}
                  className={index === 0 ? 'border' : undefined}
                >
                  <img src={item.img} alt={item.name} />
                </button>
              );
            })}
            {/* 요소 5개 초과/이하 분기처리 -> 이상일 시 모달 연결 */}
            {ContentImgList?.filter((item, index) => index === 4).map((item, index) => {
              return (
                <React.Fragment>
                  {ContentImgList?.length > 5 ? (
                    <button className="more" key={'contentImg' + index} data-name={item.name} onClick={onClickModalOpen}>
                      <p className="more__txt">+{ContentImgList.length - 5}</p>
                      <img src={item.img} alt={item.name} />
                    </button>
                  ) : (
                    <button
                      key={'contentImg' + index}
                      data-name={item.name}
                      onClick={e => {
                        ProductJs.showPopup(e.target.closest('button'));
                      }}
                    >
                      <img src={item.img} alt={item.name} />
                    </button>
                  )}
                </React.Fragment>
              );
            })}
          </div>
          {/* <button
            className="ThiraProductDetail__btn"
            onClick={e => {
              ProductJs.viewDetail(e.target.closest('button'));
            }}
          >
            View Detail Page
          </button> */}
        </div>
      </div>

      {/* MO */}
      <div className="ThiraProductDetail__top mo-block">
        <h2 className="ThiraProductDetail__title">{props.name}</h2>
        <div className="ThiraProductDetail__bigThum">
          <img src={T200_1} alt="썸네일" />
        </div>
        <div className="ThiraProductDetail__smallThum">
          {ContentImgList?.filter((item, index) => index < 4).map((item, index) => {
            return (
              <button
                key={'contentImg' + index}
                data-name={item.name}
                onClick={e => {
                  ProductJs.showPopup(e.target.closest('button'));
                }}
                className={index === 0 ? 'border' : undefined}
              >
                <img src={item.img} alt={item.name} />
              </button>
            );
          })}
          {/* 요소 5개 초과/이하 분기처리 -> 이상일 시 모달 연결 */}
          {ContentImgList?.filter((item, index) => index === 4).map((item, index) => {
            return (
              <React.Fragment>
                {ContentImgList?.length > 5 ? (
                  <button className="more" key={'contentImg' + index} data-name={item.name} onClick={onClickModalOpen}>
                    <p className="more__txt">+{ContentImgList.length - 5}</p>
                    <img src={item.img} alt={item.name} />
                  </button>
                ) : (
                  <button
                    key={'contentImg' + index}
                    data-name={item.name}
                    onClick={e => {
                      ProductJs.showPopup(e.target.closest('button'));
                    }}
                  >
                    <img src={item.img} alt={item.name} />
                  </button>
                )}
              </React.Fragment>
            );
          })}
        </div>
        <p className="ThiraProductDetail__desc">{t('t200.productDetail.desc')}</p>
      </div>

      {/* Specifications */}
      <div className="ThiraProductDetail__specification detail">
        <h3>{t('t200.specifications.title')}</h3>
        <figure>
          <img className="pc-block" src={DetailPng} alt="상세보기" />
          <img className="mo-block" src={DetailMobPng} alt="상세보기" />
        </figure>
      </div>

      {/* VIDEO */}
      {/* <div className="ThiraProductDetail__specification">
        <h3>Specification video</h3>
        <figure>
          <video src={Video} playsInline controls></video>
        </figure>
      </div> */}

      <div className="ThiraProductDetail__cont active">
        <h3
          className="ThiraProductDetail__subtitle"
          onClick={e => {
            ProductJs.toggleArrow(e.target.closest('.ThiraProductDetail__subtitle'));
          }}
        >
          {t('t200.specifications.sub.0.title')}
          <button className="arr">아래 화살표</button>
        </h3>
        <ul className="ThiraProductDetail__list">
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.0.item.0.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.0.item.0.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.0.item.1.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.0.item.1.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.0.item.2.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.0.item.2.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.0.item.3.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.0.item.3.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.0.item.4.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.0.item.4.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.0.item.5.itemDiv')}</p>
            <p className="txt-02">
              <LineWrap text={t('t200.specifications.sub.0.item.5.itemTxt')} />
            </p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.0.item.6.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.0.item.6.itemTxt')}</p>
          </li>
        </ul>
      </div>

      <div className="ThiraProductDetail__cont">
        <h3
          className="ThiraProductDetail__subtitle"
          onClick={e => {
            ProductJs.toggleArrow(e.target.closest('.ThiraProductDetail__subtitle'));
          }}
        >
          {t('t200.specifications.sub.1.title')}
          <button className="arr">아래 화살표</button>
        </h3>
        <ul className="ThiraProductDetail__list">
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.1.item.0.itemDiv')}</p>
            <p className="txt-02">
              <LineWrap text={t('t200.specifications.sub.1.item.0.itemTxt')} />
            </p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.1.item.1.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.1.item.1.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.1.item.2.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.1.item.2.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.1.item.3.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.1.item.3.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.1.item.4.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.1.item.4.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.1.item.5.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.1.item.5.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.1.item.6.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.1.item.6.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.1.item.7.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.1.item.7.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.1.item.8.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.1.item.8.itemTxt')}</p>
          </li>
        </ul>
      </div>

      <div className="ThiraProductDetail__cont">
        <h3
          className="ThiraProductDetail__subtitle"
          onClick={e => {
            ProductJs.toggleArrow(e.target.closest('.ThiraProductDetail__subtitle'));
          }}
        >
          {t('t200.specifications.sub.2.title')}
          <button className="arr">아래 화살표</button>
        </h3>
        <ul className="ThiraProductDetail__list">
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.2.item.0.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.2.item.0.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.2.item.1.itemDiv')}</p>
            <p className="txt-02">
              <LineWrap text={t('t200.specifications.sub.2.item.1.itemTxt')} />
            </p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.2.item.2.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.2.item.2.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.2.item.3.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.2.item.3.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.2.item.4.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.2.item.4.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.2.item.5.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.2.item.5.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.2.item.6.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.2.item.6.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.2.item.7.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.2.item.7.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.2.item.8.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.2.item.8.itemTxt')}</p>
          </li>
        </ul>
      </div>

      <div className="ThiraProductDetail__cont">
        <h3
          className="ThiraProductDetail__subtitle"
          onClick={e => {
            ProductJs.toggleArrow(e.target.closest('.ThiraProductDetail__subtitle'));
          }}
        >
          {t('t200.specifications.sub.3.title')} <button className="arr">아래 화살표</button>
        </h3>
        <ul className="ThiraProductDetail__list">
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.3.item.0.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.3.item.0.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.3.item.1.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.3.item.1.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.3.item.2.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.3.item.2.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.3.item.3.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.3.item.3.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.3.item.4.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.3.item.4.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.3.item.5.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.3.item.5.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.3.item.6.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.3.item.6.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.3.item.7.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.3.item.7.itemTxt')}</p>
          </li>
        </ul>
      </div>

      <div className="ThiraProductDetail__cont">
        <h3
          className="ThiraProductDetail__subtitle"
          onClick={e => {
            ProductJs.toggleArrow(e.target.closest('.ThiraProductDetail__subtitle'));
          }}
        >
          {t('t200.specifications.sub.4.title')} <button className="arr">아래 화살표</button>
        </h3>
        <ul className="ThiraProductDetail__list">
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.4.item.0.itemDiv')}</p>
            <p className="txt-02">
              <LineWrap text={t('t200.specifications.sub.4.item.0.itemTxt')} />
            </p>
          </li>
        </ul>
      </div>

      <div className="ThiraProductDetail__cont">
        <h3
          className="ThiraProductDetail__subtitle"
          onClick={e => {
            ProductJs.toggleArrow(e.target.closest('.ThiraProductDetail__subtitle'));
          }}
        >
          {t('t200.specifications.sub.5.title')} <button className="arr">아래 화살표</button>
        </h3>
        <ul className="ThiraProductDetail__list">
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.5.item.0.itemDiv')}</p>
            {/* <p className="txt-02">{t('t200.specifications.sub.5.item.0.itemTxt')}</p> */}
            {/* 23.11.26 줄바꿈 떄문에 아래코드로 변경 */}
            <p className="txt-02">
              <LineWrap text={t('t200.specifications.sub.5.item.0.itemTxt')} />
            </p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.5.item.1.itemDiv')}</p>
            <p className="txt-02">
              <LineWrap text={t('t200.specifications.sub.5.item.1.itemTxt')} />
            </p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.5.item.2.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.5.item.2.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.5.item.3.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.5.item.3.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.5.item.4.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.5.item.4.itemTxt')}</p>
          </li>
        </ul>
      </div>

      <div className="ThiraProductDetail__cont">
        <h3
          className="ThiraProductDetail__subtitle"
          onClick={e => {
            ProductJs.toggleArrow(e.target.closest('.ThiraProductDetail__subtitle'));
          }}
        >
          {t('t200.specifications.sub.6.title')} <button className="arr">아래 화살표</button>
        </h3>
        <ul className="ThiraProductDetail__list">
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.6.item.0.itemDiv')}</p>
            <p className="txt-02">
              <LineWrap text={t('t200.specifications.sub.6.item.0.itemTxt')} />
            </p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.6.item.1.itemDiv')}</p>
            <p className="txt-02">
              <LineWrap text={t('t200.specifications.sub.6.item.1.itemTxt')} />
            </p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.6.item.2.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.6.item.2.itemTxt')}</p>
          </li>
        </ul>
      </div>

      <div className="ThiraProductDetail__cont">
        <h3
          className="ThiraProductDetail__subtitle"
          onClick={e => {
            ProductJs.toggleArrow(e.target.closest('.ThiraProductDetail__subtitle'));
          }}
        >
          {t('t200.specifications.sub.7.title')} <button className="arr">아래 화살표</button>
        </h3>
        <ul className="ThiraProductDetail__list">
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.7.item.0.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.7.item.0.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('t200.specifications.sub.7.item.1.itemDiv')}</p>
            <p className="txt-02">{t('t200.specifications.sub.7.item.1.itemTxt')}</p>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}

export default ThiraProductT200;
