import {Outlet, useParams} from 'react-router-dom';
import '../../assets/css/about.scss';

function ThiraAbout(props) {
  let {name} = useParams();
  return (
    <div className="ThiraAbout">
      <Outlet name={props.name} />
    </div>
  );
}

export default ThiraAbout;
