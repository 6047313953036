import {Link} from 'react-router-dom';
import React, {useEffect, useRef} from 'react';
// import {useIntl} from 'react-intl'; // 다국어
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';
// Import Swiper
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import LineWrap from '../../component/common/lineWrap';

import {EffectFade, Navigation, Autoplay} from 'swiper';

import PartnerSwiper from '../../component/contents/partnerSwiper'; // component
// img
import ContentPng from '../../assets/images/about/content_about.png';
import ContentMobPng from '../../assets/images/about/content_about_mob.png';
import ContentBgPng from '../../assets/images/about/content_bg_thira.png';
import MouseWheelArrowSvg from '../../assets/images/main/mouse_wheel_arrow.svg';
import OurAwards from '../../assets/images/about/about_our_awards.png';
import OurStory from '../../assets/images/about/about_story.png';
// import OurTeam_default from '../../assets/images/about/about_team_00.png';
import OurTeam_01 from '../../assets/images/about/about_team_01.png';
import OurTeam_02 from '../../assets/images/about/about_team_02.png';
import OurTeam_03 from '../../assets/images/about/about_team_03.png';
import OurTeam_04 from '../../assets/images/about/about_team_04.png';
import OurTeam_05 from '../../assets/images/about/about_team_05.png';
import OurTeam_06 from '../../assets/images/about/about_team_06.png';
import OurTeam_07 from '../../assets/images/about/about_team_07.png';
import OurTeam_08 from '../../assets/images/about/about_team_08.png';
import OurTeam_09 from '../../assets/images/about/about_team_09.png';
// import Vision_01 from '../../assets/images/about/vision_01.png';
// import Vision_02 from '../../assets/images/about/vision_02.png';
// import Vision_03 from '../../assets/images/about/vision_03.png';

function ThiraAboutOurStory() {
  // 다국어 세팅 (about.json 파일)
  const {t} = useTranslation('about');
  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어

  const ourTeamData = [OurTeam_01, OurTeam_02, OurTeam_03, OurTeam_04, OurTeam_05, OurTeam_06, OurTeam_07, OurTeam_08, OurTeam_09];

  // About scroll Event
  const contentRef = useRef(0);
  const sectionRef = useRef();

  function onScroll() {
    const sectionHeight = contentRef.current.clientHeight;
    const content = contentRef.current;
    let value = 1 - window.scrollY / 500;
    if (window.scrollY < sectionHeight && value > 0.5) {
      sectionRef.current.classList.remove('activeAos');
      content.style.transform = `scale(${value}) `;
      content.style.opacity = value;
    } else if (window.scrollY > content.clientHeight / 2) {
      sectionRef.current.classList.add('activeAos');
    } else {
      sectionRef.current.classList.remove('activeAos');
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll, {passive: true});
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div className="ThiraAboutOurStory">
      {/* About THIRA ROBOTICS */}
      <section className="ThiraAboutOurStory__about" ref={sectionRef}>
        <div className="inner-width">
          <figure className="content" ref={contentRef}>
            <img src={window.innerWidth > 1023 ? ContentPng : ContentMobPng} alt="컨텐츠" />
          </figure>
          <div className="text-box">
            <h3 className="ThiraAboutOurStory__title">
              {/* 
                텍스트 위치 : OUR_STORY > title > 첫번째 문자열
                페이지 내 위치 : 회사소개  > Our Story > 제일 처음 타이틀 
              */}
              {t('OUR_STORY.title.0')}
            </h3>
            <p className="txt-01">
              {/* 
                텍스트 위치 : OUR_STORY > about > 첫번째 문자열
                페이지 내 위치 : 회사소개  > Our Story > 티라로보틱스 소개글 1문단
              */}
              <LineWrap text={t('OUR_STORY.about.0')} />
            </p>
            <br className="br-pc" />
            <br className="br-pc" />
            <p className="txt-01">
              {/* 
                텍스트 위치 : OUR_STORY > about > 두번째 문자열
                페이지 내 위치 : 회사소개  > Our Story > 티라로보틱스 소개글 2문단
              */}
              <LineWrap text={t('OUR_STORY.about.1')} />
            </p>
            <br className="br-pc" />
            <br className="br-pc" />
            <p className="txt-01" style={{textAlign: 'left'}}>
              <LineWrap text={t('OUR_STORY.about.2')} />
              <LineWrap text={t('OUR_STORY.about.3')} />
              <LineWrap text={t('OUR_STORY.about.4')} />
              <LineWrap text={t('OUR_STORY.about.5')} />
              <LineWrap text={t('OUR_STORY.about.6')} />
              <LineWrap text={t('OUR_STORY.about.7')} />
              <LineWrap text={t('OUR_STORY.about.8')} />
              <LineWrap text={t('OUR_STORY.about.9')} />
              <LineWrap text={t('OUR_STORY.about.10')} />
              <LineWrap text={t('OUR_STORY.about.11')} />
            </p>
          </div>
          <div className="ThiraAboutOurStory__scroll-wheel pc-block">
            <figure>
              <img src={MouseWheelArrowSvg} alt="아이콘" />
            </figure>
            <div className="scroll-wheel-animation"></div>
            <figure className="rotate-icon">
              <img src={MouseWheelArrowSvg} alt="아이콘" />
            </figure>
            <p>MOUSE WHEEL</p>
          </div>
          <figure className="content-bg">
            <img src={ContentBgPng} alt="컨텐츠 배경" />
          </figure>
        </div>
      </section>

      {/* Our Mission */}
      <section className="ThiraAboutOurStory__mission">
        <div className="inner-width">
          <h3 className="ThiraAboutOurStory__title" data-aos="fade-up">
            {/* 
              텍스트 위치 : OUR_STORY > title > 두번째 문자열
              페이지 내 위치 : 회사소개  > Our Story > 미션 섹션 타이틀
            */}
            {t('OUR_STORY.title.1')}
          </h3>
          <p className="txt-01" data-aos="fade-up" data-aos-delay="200">
            {/* 
              텍스트 위치 : OUR_STORY > mission > 첫번째 문자열
              페이지 내 위치 : 회사소개  > Our Story > 미션 섹션 내용
            */}
            {/* json 데이터 자체에 줄바꿈 표시가 들어가서 적용 */}
            <LineWrap text={t('OUR_STORY.mission.0')} />
          </p>
        </div>
      </section>

      {/* Our Vision */}
      <section className="ThiraAboutOurStory__vision">
        <div className="inner-width">
          <h3 className="ThiraAboutOurStory__title" data-aos="fade-up">
            {/* 
              텍스트 위치 : OUR_STORY > title > 세번째 문자열
              페이지 내 위치 : 회사소개  > Our Story > 비전 섹션 타이틀
            */}
            {t('OUR_STORY.title.2')}
          </h3>
          <p className="txt-01" data-aos="fade-up" data-aos-delay="200">
            {/* 
              텍스트 위치 : OUR_STORY > vision > 첫번째 문자열
              페이지 내 위치 : 회사소개  > Our Story > 비전 섹션 내용
            */}
            <LineWrap text={t('OUR_STORY.vision.0')} />
          </p>
          {/* TODO :: 0507 업체 요청사항 (인증서를 저희가 직접 수정 및 교체 가능할까요? 안된다면 삭제 부탁드립니다.) */}
          {/* <div className="vision">
            <div className="vision__swiper" data-aos="fade-right" data-aos-delay="400">
              <Swiper
                speed={200}
                modules={[Navigation, Autoplay]}
                navigation={true}
                loop={true}
                spaceBetween={104}
                disableoninteraction="false"
                autoplay={{
                  delay: 5000,
                }}
                className="vision-swiper"
              >
                <SwiperSlide className="vision-swiper__slide">
                  <figure className="vision-swiper__img">
                    <img src={Vision_01} alt="이미지" />
                  </figure>
                  <p className="txt-02">CTK-2022-03345</p>
                  <h4 className="ThiraAboutOurStory__subtitle">FCC SDoC</h4>
                </SwiperSlide>
                <SwiperSlide className="vision-swiper__slide">
                  <figure className="vision-swiper__img">
                    <img src={Vision_02} alt="이미지" />
                  </figure>
                  <p className="txt-02">CA23P2001</p>
                  <h4 className="ThiraAboutOurStory__subtitle">Kiwa</h4>
                </SwiperSlide>
                <SwiperSlide className="vision-swiper__slide">
                  <figure className="vision-swiper__img">
                    <img src={Vision_03} alt="이미지" />
                  </figure>
                  <p className="txt-02">CA23P4005</p>
                  <h4 className="ThiraAboutOurStory__subtitle">Kiwa</h4>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="vision__txt">
              <h4 className="ThiraAboutOurStory__subtitle" data-aos="fade-left" data-aos-delay="400">
                {defaultLang === 'kor' ? '혁신적인 솔루션' : 'Our Innovative Solutions'}
              </h4>
              <p className="txt-01" data-aos="fade-left" data-aos-delay="600">
                {defaultLang === 'kor' ? (
                  <>
                    어려운 환경 조건도 극복 가능한 AMR을 제공합니다.
                    <br />
                    <br />
                    티라로보틱스의 AMR들은 거친 지형의 주행부터 자동화 물류 공정에 이르기까지 어떤 과제든 극복합니다.
                    <br />
                    <br />
                    모든 AMR은 자체 설계 및 개발, 생산하기 때문에 높은 품질과 기능성을 보장합니다.
                    <br />
                    <br />
                    그렇기에, 현재 필요사항에 가장 적합한 솔루션들을 찾아보실 수 있습니다.
                  </>
                ) : (
                  <>
                    Tough AMR for Tough Cases Have less-than-perfect facility conditions?
                    <br />
                    <br />
                    From rough terrain navigation to automated logistics fulfillment, our AMR are equipped to handle any task.
                    <br />
                    <br />
                    All THIRA Robotics AMR units are designed in-house to ensure high quality and functionality for clients.
                    <br />
                    <br />
                    Find the solution that best suits your needs.
                  </>
                )}
              </p>
            </div>
          </div> */}
        </div>
      </section>

      {/* Sign up to read ... */}
      <section className="ThiraAboutOurStory__signup">
        <div className="inner-width">
          <h3 className="ThiraAboutOurStory__title" data-aos="fade-up">
            {/* 
              텍스트 위치 : OUR_STORY > signUp > 첫번째 객체 > title
              페이지 내 위치 : 회사소개  > Our Story > 비전섹션 하단 구독 문구
            */}
            {t('OUR_STORY.signUp.0.title')}
          </h3>
          <Link to={`/${lang}/inquiry`} className="sign-up" data-aos="zoom-in" data-aos-delay="200">
            {/* 
              텍스트 위치 : OUR_STORY > signUp > 첫번째 객체 > button
              페이지 내 위치 : 회사소개  > Our Story > 비전섹션 하단 구독하기 버튼
            */}
            {t('OUR_STORY.signUp.1.button')}
          </Link>
        </div>
      </section>

      {/* Our Story */}
      <section className="ThiraAboutOurStory__story">
        <div className="inner-width">
          <h3 className="ThiraAboutOurStory__title" data-aos="fade-up">
            {/* 
              텍스트 위치 : OUR_STORY > title > 네번째 문자열
              페이지 내 위치 : 회사소개  > Our Story > 구독센션 하단 스토리 문단 타이틀
            */}
            {t('OUR_STORY.title.3')}
          </h3>
          <div className="story">
            <div className="story__cont" data-aos="fade-right" data-aos-delay="200">
              <figure>
                <img src={OurStory} alt="이미지" />
              </figure>
            </div>
            <div className="story__cont" data-aos="fade-left" data-aos-delay="400">
              <h4 className="ThiraAboutOurStory__subtitle">
                {/* 
                  텍스트 위치 : OUR_STORY > story > 첫번째 객체 > title
                  페이지 내 위치 : 회사소개  > Our Story > 티라로보틱스 스토리 첫번째 문단 제목
                */}
                {t('OUR_STORY.story.0.title')}
              </h4>
              <p className="txt-01">
                {/* 
                  텍스트 위치 : OUR_STORY > story > 첫번째 객체 > txt
                  페이지 내 위치 : 회사소개  > Our Story > 티라로보틱스 스토리 첫번째 문단 내용
                */}
                <LineWrap text={t('OUR_STORY.story.0.txt')} />
              </p>
            </div>
            <div className="story__cont" data-aos="fade-up">
              <h4 className="ThiraAboutOurStory__subtitle">
                {/* 
                  텍스트 위치 : OUR_STORY > story > 두번째 객체 > title
                  페이지 내 위치 : 회사소개  > Our Story > 티라로보틱스 스토리 두번째 문단 제목
                */}
                {t('OUR_STORY.story.1.title')}
              </h4>
              <p className="txt-01">
                {/* 
                  텍스트 위치 : OUR_STORY > story > 두번째 객체 > txt
                  페이지 내 위치 : 회사소개  > Our Story > 티라로보틱스 스토리 두번째 문단 내용
                */}
                <LineWrap text={t('OUR_STORY.story.1.txt')} />
              </p>
            </div>
            <div className="story__cont" data-aos="fade-up">
              <h4 className="ThiraAboutOurStory__subtitle">
                {/* 
                  텍스트 위치 : OUR_STORY > story > 세번째 객체 > title
                  페이지 내 위치 : 회사소개  > Our Story > 티라로보틱스 스토리 세번째 문단 제목
                */}
                {t('OUR_STORY.story.2.title')}
              </h4>
              <p className="txt-01">
                {/* 
                  텍스트 위치 : OUR_STORY > story > 세번째 객체 > title
                  페이지 내 위치 : 회사소개  > Our Story > 티라로보틱스 스토리 세번째 문단 내용
                */}
                <LineWrap text={t('OUR_STORY.story.2.txt')} />
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Our Team */}
      <section className="ThiraAboutOurStory__team">
        <div className="inner-width">
          <h3 className="ThiraAboutOurStory__title" data-aos="fade-up">
            {/* 
              텍스트 위치 : OUR_STORY > title > 다섯번째 문자열
              페이지 내 위치 : 회사소개  > Our Story > 스토리 하단 임원진 소개 섹션 타이틀
            */}
            {t('OUR_STORY.title.4')}
          </h3>
          <ul className="team pc-grid">
            {/* 
              텍스트 위치 : OUR_STORY > ourTeamData
              페이지 내 위치 : 회사소개  > Our Story > 임원진 소개 섹션 내용
            */}
            {ourTeamData.map((img, index) => {
              return (
                <li className="team__cont" data-aos="fade-up" data-aos-delay={100 * index} key={'teamList' + index}>
                  <figure className="team__img">
                    <img src={img} alt={t(`OUR_STORY.ourTeamData.${index}.name`)} />
                  </figure>
                  <p className="txt-02">
                    <LineWrap text={t(`OUR_STORY.ourTeamData.${index}.name`)} />
                  </p>
                  <p className="txt-03">
                    <LineWrap text={t(`OUR_STORY.ourTeamData.${index}.position`)} />
                  </p>
                  <p className="txt-04">
                    <LineWrap text={t(`OUR_STORY.ourTeamData.${index}.desc`)} />
                  </p>
                </li>
              );
            })}
          </ul>
          <div className="team mo-block" data-aos="fade-up" data-aos-delay="400">
            <Swiper
              speed={200}
              effect={'fade'}
              modules={[EffectFade, Navigation, Autoplay]}
              navigation={true}
              loop={true}
              disableoninteraction="false"
              autoplay={{
                delay: 5000,
              }}
              className="team-swiper"
            >
              {/* 
                텍스트 위치 : OUR_STORY > ourTeamData
                페이지 내 위치 : 회사소개  > Our Story > 임원진 소개 섹션 내용
              */}
              {ourTeamData.map((img, index) => {
                return (
                  <SwiperSlide className="team__cont" key={'teamList' + index}>
                    <figure className="team__img">
                      <img src={img} alt={t(`OUR_STORY.ourTeamData.${index}.name`)} />
                    </figure>
                    <p className="txt-02">
                      <LineWrap text={t(`OUR_STORY.ourTeamData.${index}.name`)} />
                    </p>
                    <p className="txt-03">
                      <LineWrap text={t(`OUR_STORY.ourTeamData.${index}.position`)} />
                    </p>
                    <p className="txt-04">
                      <LineWrap text={t(`OUR_STORY.ourTeamData.${index}.desc`)} />
                    </p>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </section>

      {/* Our Partners */}
      <section className="ThiraAboutOurStory__partners">
        <div className="inner-width">
          <h3 className="ThiraAboutOurStory__title" data-aos="fade-up" data-aos-delay="200">
            {/* 
              텍스트 위치 : OUR_STORY > title > 여섯번째 문자열
              페이지 내 위치 : 회사소개  > Our Story > 임원진 소개 하단 주요고객 섹션 제목
            */}
            {t('OUR_STORY.title.5')}
          </h3>
          <div className="partners" data-aos="fade-up" data-aos-delay="200">
            <PartnerSwiper></PartnerSwiper>
          </div>
        </div>
      </section>

      {/* Our Awards */}
      <section className="ThiraAboutOurStory__awards">
        <div className="inner-width">
          <h3 className="ThiraAboutOurStory__title" data-aos="fade-up">
            {/* 
              텍스트 위치 : OUR_STORY > title > 일곱번째 문자열
              페이지 내 위치 : 회사소개  > Our Story > 주요 고객 하단 수상 섹션 제목
            */}
            {t('OUR_STORY.title.6')}
          </h3>
          <p className="txt-01" data-aos="fade-up" data-aos-delay="200">
            {/* 
              텍스트 위치 : OUR_STORY > award
              페이지 내 위치 : 회사소개  > Our Story > 주요 고객 하단 수상 섹션 내용
            */}
            {t('OUR_STORY.award')}
          </p>
          <img src={OurAwards} alt="KOREA ROBOT COMPANY OF THE YEAR 2022" />
        </div>
      </section>

      {/* 하단 배너 */}
      <section className="ThiraAboutOurStory__banner" data-aos="fade-up">
        <h3 className="ThiraAboutOurStory__title">
          {/* 
            텍스트 위치 : OUR_STORY > banner > title
            페이지 내 위치 : 회사소개  > Our Story > 최하단 배너 제목
          */}
          {t('OUR_STORY.banner.title')}
        </h3>
        <p className="txt-01">
          {/* 
            텍스트 위치 : OUR_STORY > banner > txt
            페이지 내 위치 : 회사소개  > Our Story > 최하단 배너 내용
          */}
          {t('OUR_STORY.banner.txt')}
        </p>
      </section>
    </div>
  );
}

export default ThiraAboutOurStory;
