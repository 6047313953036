import React, {useEffect, useState} from 'react';
import * as ProductJs from '../../assets/js/product';
import ThiraProductLocation from './location';
import ThumbNailModal from '../modal/ThumbNailModal';
import Modal from '../../util/Modal';
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';

// IMG
import Station_1 from '../../assets/images/product/station/station_01.png';
import Station_2 from '../../assets/images/product/station/station_02.png';
import Station_3 from '../../assets/images/product/station/station_03.png';
import Station_4 from '../../assets/images/product/station/station_04.png';
import Station_5 from '../../assets/images/product/station/station_05.png';
import DetailPng from '../../assets/images/product/station/station_detail.png';
import DetailMobPng from '../../assets/images/product/station/station_detail_mob.png';

function ChargingSystem(props) {
  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어
  // 다국어 세팅
  const {t} = useTranslation('product');

  useEffect(() => {
    ProductJs.showTitle();
    ProductJs.showThumbs();
  });

  // 팝업 열기
  const [isActiveModal, setIsActiveModal] = useState(false);
  const onClickModalOpen = () => {
    setIsActiveModal(true);
    Modal.modalOpen();
  };

  const ContentImgList = [
    {img: Station_1, name: 'Station_1'},
    {img: Station_2, name: 'Station_2'},
    {img: Station_3, name: 'Station_3'},
    {img: Station_4, name: 'Station_4'},
    {img: Station_5, name: 'Station_5'},
  ];

  const ModalData = {
    isState: {
      state: isActiveModal,
      setState: setIsActiveModal,
    },
    SlideContent: ContentImgList,
  };

  return (
    <React.Fragment>
      <ThumbNailModal data={ModalData} />
      <ThiraProductLocation default="chargingSystem"></ThiraProductLocation>

      {/* PC */}
      <div className="ThiraProductDetail__top pc-flex">
        <div className="ThiraProductDetail__bigThum">
          <img src={Station_1} alt="썸네일" />
        </div>
        <div className="ThiraProductDetail__topInf">
          <h2 className="ThiraProductDetail__title">{props.name}</h2>
          <p className="ThiraProductDetail__desc">{t('charging.productDetail.desc')}</p>

          <div className="ThiraProductDetail__smallThum">
            {ContentImgList?.filter((item, index) => index < 4).map((item, index) => {
              return (
                <button
                  key={'contentImg' + index}
                  data-name={item.name}
                  onClick={e => {
                    ProductJs.showPopup(e.target.closest('button'));
                  }}
                  className={index === 0 ? 'border' : undefined}
                >
                  <img src={item.img} alt={item.name} />
                </button>
              );
            })}
            {/* 요소 5개 초과/이하 분기처리 -> 이상일 시 모달 연결 */}
            {ContentImgList?.filter((item, index) => index === 4).map((item, index) => {
              return (
                <React.Fragment>
                  {ContentImgList?.length > 5 ? (
                    <button className="more" key={'contentImg' + index} data-name={item.name} onClick={onClickModalOpen}>
                      <p className="more__txt">+{ContentImgList.length - 5}</p>
                      <img src={item.img} alt={item.name} />
                    </button>
                  ) : (
                    <button
                      key={'contentImg' + index}
                      data-name={item.name}
                      onClick={e => {
                        ProductJs.showPopup(e.target.closest('button'));
                      }}
                    >
                      <img src={item.img} alt={item.name} />
                    </button>
                  )}
                </React.Fragment>
              );
            })}
          </div>
          {/* <button
            className="ThiraProductDetail__btn"
            onClick={e => {
              ProductJs.viewDetail(e.target.closest('button'));
            }}
          >
            View Detail Page
          </button> */}
        </div>
      </div>

      {/* MO */}
      <div className="ThiraProductDetail__top mo-block">
        <h2 className="ThiraProductDetail__title">{props.name}</h2>
        <div className="ThiraProductDetail__bigThum">
          <img src={Station_1} alt="썸네일" />
        </div>
        <div className="ThiraProductDetail__smallThum">
          {ContentImgList?.filter((item, index) => index < 4).map((item, index) => {
            return (
              <button
                key={'contentImg' + index}
                data-name={item.name}
                onClick={e => {
                  ProductJs.showPopup(e.target.closest('button'));
                }}
                className={index === 0 ? 'border' : undefined}
              >
                <img src={item.img} alt={item.name} />
              </button>
            );
          })}
          {/* 요소 5개 초과/이하 분기처리 -> 이상일 시 모달 연결 */}
          {ContentImgList?.filter((item, index) => index === 4).map((item, index) => {
            return (
              <React.Fragment>
                {ContentImgList?.length > 5 ? (
                  <button className="more" key={'contentImg' + index} data-name={item.name} onClick={onClickModalOpen}>
                    <p className="more__txt">+{ContentImgList.length - 5}</p>
                    <img src={item.img} alt={item.name} />
                  </button>
                ) : (
                  <button
                    key={'contentImg' + index}
                    data-name={item.name}
                    onClick={e => {
                      ProductJs.showPopup(e.target.closest('button'));
                    }}
                  >
                    <img src={item.img} alt={item.name} />
                  </button>
                )}
              </React.Fragment>
            );
          })}
        </div>
        <p className="ThiraProductDetail__desc">{t('charging.productDetail.desc')}</p>
      </div>

      {/* Specifications */}
      <div className="ThiraProductDetail__specification detail">
        <h3>{t('charging.specifications.title')}</h3>
        <figure>
          <img className="pc-block" src={DetailPng} alt="상세보기" />
          <img className="mo-block" src={DetailMobPng} alt="상세보기" />
        </figure>
      </div>

      <div className="ThiraProductDetail__cont active">
        <h3
          className="ThiraProductDetail__subtitle"
          onClick={e => {
            ProductJs.toggleArrow(e.target.closest('.ThiraProductDetail__subtitle'));
          }}
        >
          {t('charging.specifications.sub.0.title')}
          <button className="arr">아래 화살표</button>
        </h3>
        <div className="ThiraProductDetail__toggle">
          <ul className="ThiraProductDetail__list">
            <li className="ThiraProductDetail__item">
              <p className="txt-01">{t('charging.specifications.sub.0.item.0.itemDiv')}</p>
              <p className="txt-02">{t('charging.specifications.sub.0.item.0.itemTxt')}</p>
            </li>
            <li className="ThiraProductDetail__item">
              <p className="txt-01">{t('charging.specifications.sub.0.item.1.itemDiv')}</p>
              <p className="txt-02">{t('charging.specifications.sub.0.item.1.itemTxt')}</p>
            </li>
            <li className="ThiraProductDetail__item">
              <p className="txt-01">{t('charging.specifications.sub.0.item.2.itemDiv')}</p>
              <p className="txt-02">{t('charging.specifications.sub.0.item.2.itemTxt')}</p>
            </li>
            <li className="ThiraProductDetail__item">
              <p className="txt-01">{t('charging.specifications.sub.0.item.3.itemDiv')}</p>
              <p className="txt-02">{t('charging.specifications.sub.0.item.3.itemTxt')}</p>
            </li>
          </ul>
        </div>
      </div>

      <div className="ThiraProductDetail__cont">
        <h3
          className="ThiraProductDetail__subtitle"
          onClick={e => {
            ProductJs.toggleArrow(e.target.closest('.ThiraProductDetail__subtitle'));
          }}
        >
          {t('charging.specifications.sub.1.title')}
          <button className="arr">아래 화살표</button>
        </h3>
        <ul className="ThiraProductDetail__list">
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('charging.specifications.sub.1.item.0.itemDiv')}</p>
            <p className="txt-02">{t('charging.specifications.sub.1.item.0.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('charging.specifications.sub.1.item.1.itemDiv')}</p>
            <p className="txt-02">{t('charging.specifications.sub.1.item.1.itemTxt')}</p>
          </li>
          <li className="ThiraProductDetail__item">
            <p className="txt-01">{t('charging.specifications.sub.1.item.2.itemDiv')}</p>
            <p className="txt-02">{t('charging.specifications.sub.1.item.2.itemTxt')}</p>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}

export default ChargingSystem;
