import React from 'react';
import {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {ThiraTitle} from '../../component/common/index';
import ReactDOMServer from 'react-dom/server';
import {useIntl} from 'react-intl'; // 다국어
import {useTranslation, Trans} from 'react-i18next';
import i18next from '../../i18n';
// 모달코드 추가
import Modal from '../../util/Modal';
import DefaultModal from '../../component/modal/DefaultModal';
// icon img
import DocSvg from '../../assets/images/service/ico_doc.svg';
import EtcSvg from '../../assets/images/service/ico_etc.svg';
import JpgSvg from '../../assets/images/service/ico_jpg.svg';
import PdfSvg from '../../assets/images/service/ico_pdf.svg';
import PngSvg from '../../assets/images/service/ico_png.svg';
// 문서 이미지
// 인증서
import certiImg01 from '../../assets/images/about/CE_Machinery Directive (2006_42_EC).jpg';
import certiImg02 from '../../assets/images/about/CE_Electromagnetic Compatibility Directive (2014_30_EU).jpg';
import certiImg03 from '../../assets/images/about/DOC of Machinery_ARM.jpg';
import certiImg04 from '../../assets/images/about/DOC of Machinery_Charging Station.jpg';
import certiImg05 from '../../assets/images/about/FCC.jpg';
import certiImg06 from '../../assets/images/about/ROHS Ⅱ_Digital input_output PCB.jpg';
import certiImg07 from '../../assets/images/about/ROHS Ⅱ_BLDC MOTOR.jpg';
import certiImg08 from '../../assets/images/about/ROHS Ⅱ_Top module Interface PCB.jpg';
import certiImg09 from '../../assets/images/about/ROHS Ⅱ_Moter Driver Interface PCB.jpg';
import certiImg10 from '../../assets/images/about/KITI 시험성적서 2종 취득(한국공업기술원)_Payload 1,000kg 적재 경사로 이동.jpg';
import certiImg11 from '../../assets/images/about/ITI 시험성적서 2종 취득(한국공업기술원)_Payload 1,000kg 적재높이 31mm 장애물 통과 이동.jpg';
import certiImg12 from '../../assets/images/about/KC 인증.jpg';
import certiImg13 from '../../assets/images/about/환경경영시스템 인증 획득 ISO 14001(국문).jpg';
import certiImg14 from '../../assets/images/about/환경경영시스템 인증 획득 ISO 14001(영문).jpg';
import certiImg15 from '../../assets/images/about/품질경영시스템 인증 획득 ISO 9001(국문).jpg';
import certiImg16 from '../../assets/images/about/품질경영시스템 인증 획득 ISO 9001(영문).jpg';
import certiImg17 from '../../assets/images/about/벤처기업 획득.jpg';
// 특허증
import certiImg18 from '../../assets/images/about/디자인_물류로봇용 충전시스템.png';

function ThiraCertification() {
  // 다국어 세팅 (about.json 파일)
  const {t} = useTranslation('about');
  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어

  const board_id = 3;
  const category = [
    // 분류코드하드코딩하자
    {id: 8, name: 'Certification'},
    {id: 9, name: 'Confirmation'},
    {id: 10, name: 'Patent Certificate'},
  ];
  //분류별리스트초기값
  const [list, setList] = useState(() => {
    let v = {};
    category.forEach(e => {
      v[e.id] = [];
    });
    return v;
  });
  //분류별페이지네이션초기값
  const [paging, setPaging] = useState(() => {
    let v = {};
    category.forEach(e => {
      v[e.id] = {final: 1};
    });
    return v;
  });
  //분류별현재페이지초기값
  const page = useRef(
    (() => {
      let v = {};
      category.forEach(e => {
        v[e.id] = 1;
      });
      return v;
    })(),
  );
  //첨부파일초기값
  const [attFile, setAttFile] = useState(null);

  useEffect(() => {
    setApiData(category[0].id);
  }, []);
  useEffect(() => {
    setApiData(category[1].id);
  }, []);
  useEffect(() => {
    setApiData(category[2].id);
  }, []);

  const setApiData = cid => {
    axios
      .get(`${process.env.REACT_APP_API}/board/${board_id}/withAttFile?lang=${i18next.language}&pnum=10&page=${page.current[cid]}&board_category_id=${cid}`)
      .then(response => {
        setList(prev => {
          return {...prev, [cid]: [...prev[cid], ...response.data.list]};
        });
        setAttFile(prev => {
          return {...prev, ...response.data.contentsFile};
        });
        setPaging(prev => {
          return {...prev, [cid]: response.data.paging};
        });
      })
      .catch(() => {
        console.log('setApiData Error!');
      });
  };
  // console.log(list,attFile,paging,page)
  const handleClickMore = cate_id => {
    if (page.current[cate_id] < paging[cate_id].final) {
      page.current[cate_id] += 1;
      setApiData(cate_id);
    }
  };

  const uiData = (() => {
    let v = {};
    category.forEach(e => {
      v[e.id] = list[e.id].map(l => {
        const f = attFile[l.id] ? attFile[l.id][0] : null;
        return {
          id: l.id,
          title: l.title,
          content: l.description?.split(/(\n)/gi).map((t, i) => (t === '\n' ? <br key={`${l.id}of${i}br`} /> : t)),
          img: l.thumb_img ? `/storage/${l.thumb_img}` : null,
          fileLink: f ? `/storage/get_board_file/${f.id}/${f.org_name}` : null,
        };
      });
    });
    return v;
  })();

  // 테스트 데이터 인증서_2023.0524_송유라
  // const certiTest01 = [
  //   {title: 'CE_Machinery Directive (2006_42_EC)', content: 'The Product described above complies with the requirements of the Machinery Directive (2006/42/EC).', img: certiImg01},
  //   {
  //     title: 'CE_Electromagnetic Compatibility Directive (2014_30_EU)',
  //     content: 'The equipment described above complies with the requirements of the Electromagnetic Compatibility Directive (2014/30/EU) Annex II.',
  //     img: certiImg02,
  //   },
  //   {
  //     title: 'DOC of Machinery_ARM',
  //     content: (
  //       <>
  //         • EN ISO 12100:2010
  //         <br />
  //         • EN 60204-1:2018
  //         <br />
  //         • EN ISO 13849-1:2015
  //         <br />
  //         • EN IEC 61000-6-2:2019
  //         <br />
  //         • EN 12895:2015+A1:2019
  //         <br />
  //         • EN IEC 61000-6-4:2019
  //         <br />
  //         • EN IEC 61000-3-2:2019 +A1:2021
  //         <br />
  //         • EN 61000-3-3 + A2:2021
  //         <br />
  //         • ISO 3691-4:2020
  //         <br />
  //         • EN 1175-1:1999+A1:2010
  //         <br />
  //         • 2006/42/EC
  //         <br />
  //         • 2014/35/EU
  //         <br />
  //         • 2014/30/EU
  //         <br />
  //       </>
  //     ),
  //     img: certiImg03,
  //   },
  //   {
  //     title: 'DOC of Machinery_Charging Station',
  //     content: (
  //       <>
  //         • EN ISO 12100:2010 <br />
  //         • EN 60204-1:2018 <br />
  //         • EN ISO 13849-1:2015 <br />
  //         • EN 61000-6-2:2019
  //         <br />
  //         • EN 12895:2015+A1:2019
  //         <br />
  //         • EN 61000-6-4:2019
  //         <br />
  //         • ISO 3691-4:2020
  //         <br />
  //         • EN 1175-1:1999+A1:2010
  //         <br />
  //         • 2006/42/EC <br />
  //         • 2014/35/EU
  //         <br />
  //         • 2014/30/EU
  //         <br />
  //       </>
  //     ),
  //     img: certiImg04,
  //   },
  //   {title: 'FCC', content: 'FCC SDoC: ARM / Charging Station', img: certiImg05},
  //   {title: 'ROHS Ⅱ_Digital input_output PCB', content: '', img: certiImg06},
  //   {title: 'ROHS Ⅱ_BLDC MOTOR', content: '', img: certiImg07},
  //   {title: 'ROHS Ⅱ_Top module Interface PCB', content: '', img: certiImg08},
  //   {title: 'ROHS Ⅱ_Moter Driver Interface PCB', content: '', img: certiImg09},
  // ];

  // // 테스트 데이터 확인서_2023.0526_송유라
  // const certiTest03 = [
  //   {title: 'KITI 시험성적서 2종 취득(한국공업기술원): Payload 1,000kg 적재 경사로 이동', content: '', img: certiImg10},
  //   {
  //     title: 'ITI 시험성적서 2종 취득(한국공업기술원): Payload 1,000kg 적재높이 31mm 장애물 통과 이동',
  //     content: '',
  //     img: certiImg11,
  //   },
  //   {title: 'KC 인증', content: '', img: certiImg12},
  //   {title: '환경경영시스템 인증 획득 ISO 14001(국문)', content: '', img: certiImg13},
  //   {title: '환경경영시스템 인증 획득 ISO 14001(영문)', content: '', img: certiImg14},
  //   {title: '품질경영시스템 인증 획득 ISO 9001(국문)', content: '', img: certiImg15},
  //   {title: '품질경영시스템 인증 획득 ISO 9001(영문)', content: '', img: certiImg16},
  //   {title: '벤처기업 획득', content: '', img: certiImg17},
  // ];
  // // 테스트 데이터 특허증_2023.0524_송유라
  // const certiTest02 = [
  //   {title: '디자인: 물류로봇용 충전시스템', content: '', img: certiImg18},
  //   {title: '디자인: 산업용 물류로봇', content: '', img: certiImg18},
  //   {title: '디자인: 물류로봇용 감속모터출원', content: '', img: certiImg18},
  //   {title: '출원: 물류로봇용 충전시스템', content: '', img: certiImg18},
  //   {title: '특허: 피벗축 이동장치와 이것이 구비된 육륜지지차량', content: '', img: certiImg18},
  //   {title: '특허: 롤테이너 운반차', content: '', img: certiImg18},
  //   {title: '특허: 파레트 트럭', content: '', img: certiImg18},
  //   {title: '특허: 물류 시스템의 사용자 식별 시스템 및 방법', content: '', img: certiImg18},
  // ];

  // const certificationList = list?.map(item => {
  //   const f = attFile[item.id] ? attFile[item.id][0] : null;

  //   let svg = EtcSvg;
  //   svg = ['docx', 'doc'].includes(f?.extension) ? DocSvg : svg;
  //   svg = ['jpeg', 'jpg'].includes(f?.extension) ? JpgSvg : svg;
  //   svg = f?.extension === 'png' ? PngSvg : svg;
  //   svg = f?.extension === 'pdf' ? PdfSvg : svg;

  //   return {
  //     id: item.id,
  //     img: item.thumb_img ? `/storage/${item.thumb_img}` : null,
  //     fileLink: f ? `/storage/get_board_file/${f.id}/${f.org_name}` : null,
  //     fileName: item.title,
  //     svg,
  //   };
  // });

  // // 테스트 데이터 리스트_2023.0524_송유라
  // const certiTestList = certiTest01?.map(item => {
  //   // const img = '../../assets/images/about/' + item.title + '.jpg';
  //   return {
  //     title: item.title,
  //     content: item.content,
  //     img: item.img,
  //   };
  // });
  // // 테스트 데이터 리스트_2023.0524_송유라
  // const certiTestList02 = certiTest02?.map(item => {
  //   // const img = '../../assets/images/about/' + item.title + '.jpg';
  //   return {
  //     title: item.title,
  //     content: item.content,
  //     img: item.img,
  //   };
  // });

  // 모달 열기 추가_2023.0524_송유라
  const [shownModals, setShownModals] = useState({});
  const toggleComment = id => {
    setShownModals(prevShownModals => ({
      ...prevShownModals,
      [id]: !prevShownModals[id],
    }));
  };

  // const [shownModals2, setShownModals2] = useState({});
  // const toggleComment2 = id => {
  //   setShownModals2(prevShownModals => ({
  //     ...prevShownModals,
  //     [id]: !prevShownModals[id],
  //   }));
  // };
  // const [shownModals3, setShownModals3] = useState({});
  // const toggleComment3 = id => {
  //   setShownModals3(prevShownModals => ({
  //     ...prevShownModals,
  //     [id]: !prevShownModals[id],
  //   }));
  // };
  const linkData = [
    {
      linkName: t('navigation.services'),
      linkUrl: `/${lang}/about/certification`,
    },
    {
      linkName: t('navigation.certification'),
      linkUrl: `/${lang}/about/certification`,
    },
  ];

  return (
    <React.Fragment>
      <section className="ThiraCertification">
        <div className="inner-width">
          {/* <div className="ThiraLocation">
            <Link className="ThiraLocation__btn" to="/">
              Home
            </Link>
            <span className="icon"></span>
            <span className="ThiraLocation__btn">Resource</span>
            <span className="icon"></span>
            <Link className="ThiraLocation__btn" to="/resource/certification">
              Certification
            </Link>
          </div>
          <ThiraTitle title="Certification " /> */}
          {/* 
            텍스트 위치 : CERTIFICATION > certification
            페이지 내 위치 : 회사소개  > 특허/인증 > 인증서섹션 제목
          */}
          <ThiraTitle title={t('CERTIFICATION.certification')} navigation={true} linkData={linkData} />
        </div>
        <hr className="ThiraBorder" />
        <div className="inner-width">
          {/* PC */}
          {/* 인증서 부분 추가 */}
          {category.map(c => {
            return (
              <section className="ThiraCertification-section" key={`certList${c.id}`}>
                {/* 인증서 */}
                <h3>
                  {/*className="pc-h3" : 태블릿이랑 모바일에서 서브타이틀이 안보여서 제거했습니다.*/}
                  {/* {defaultLang === 'kor'
                    ? category[0].name === c.name
                      ? category_kr[0].name
                      : category[1].name === c.name
                      ? category_kr[1].name
                      : category[2].name === c.name
                      ? category_kr[2].name
                      : null
                    : c.name} */}
                  {/* 
                    텍스트 위치 : CERTIFICATION > certification
                    페이지 내 위치 : 회사소개  > 특허/인증 > 인증서, 확인서, 특허증 섹션 제목
                  */}
                  {t('CERTIFICATION.category.0.id') === c.id
                    ? t('CERTIFICATION.category.0.name')
                    : t('CERTIFICATION.category.1.id') === c.id
                    ? t('CERTIFICATION.category.1.name')
                    : t('CERTIFICATION.category.2.id') === c.id
                    ? t('CERTIFICATION.category.2.name')
                    : null}
                </h3>
                {/* 섹션 타이틀 추가 */}
                <ul className="ThiraCertification-section__content">
                  {uiData[c.id]?.map((li, index) => {
                    return (
                      <li key={'certiTestList' + li.id}>
                        <figure className="img-wrap">
                          <img src={li.img} alt={li.fileName} />
                          {/* DEV :: 확장자에 따라 각각의 아이콘 노출해주세요 */}
                          {/* 모바일 환경에서 아이콘은 안보이는데 박스가 보여서 아예 주석처리 했습니다 */}
                          {/* <div className="file-icon">
                            <img src={li.svg} alt="" />
                          </div> */}
                          {/* DEV :: 파일 링크 연결해주세요 */}
                          {li.fileLink && (
                            <a className="img-download-link" href={li.fileLink} download>
                              <span>Download</span>
                            </a>
                          )}
                        </figure>
                        <p className="content-name">{li.title}</p>
                        {/* DEV: 현재 이부분 프론트상으로 분기처리를 해놨는데 데이터 반환값이 어떻게 되는지 파악이 안되어 실 서버에서 적용이 안되는 부분은 개발자님이 한번 확인해 주셨으면 합니다!*/}
                        {li.content && li.content.length > 0 && <p className="content-detail">{li.content}</p>}
                        {/* {ReactDOMServer.renderToString(li.content).replace(/<br\s?\/?>/g, '\n').length > 0 ? ( // 3줄 이상 60자 이상으로 처리한것, 콘텐츠 있으면 보이게 수정.
                        ) : null} */}
                        <button className="ThiraAmrAppCase__more" onClick={() => toggleComment(li.id)}>
                          {/* 
                            텍스트 위치 : CERTIFICATION > button > detail
                            페이지 내 위치 : 회사소개  > 특허/인증 > 상세보기 버튼
                          */}
                          {t('CERTIFICATION.button.detail')}
                        </button>
                        {shownModals[li.id] ? (
                          <div className="default-modal">
                            <div className="default-modal__bg" onClick={() => toggleComment(li.id)}></div>
                            <div className="default-modal__content certification-modal">
                              {/* <hgroup>
                            <h3 style={{color: '#5C5C5C', fontSize: '30px', marginBottom: '10px', textAlign: 'center'}}>인증서 상세정보</h3>
                            <p style={{color: '#000000', fontSize: '45px', marginBottom: '65px', textAlign: 'center', fontWeight: 'bold'}}>{list.title}</p>
                          </hgroup>
                          <div className="certification-content" style={{color: '#000'}}>
                            {list.content}
                          </div> */}
                              <hgroup>
                                {/* <h3>인증서 상세정보</h3> */}
                                <p>{li.title}</p>
                              </hgroup>
                              {/* 이미지박스 */}
                              <div className="certification-image">
                                <img src={li.img} alt={li.title} />
                              </div>
                              {/* 콘텐츠 내용 */}
                              {/* DEV: 위에서 언급했던 분기처리 부분과 동일합니다! */}
                              {li.content && li.content.length > 0 && <div className="certification-content">{li.content}</div>}
                              <button className="default-modal__default_button" type="button" onClick={() => toggleComment(li.id)}>
                                {/* 
                                  텍스트 위치 : CERTIFICATION > button > list
                                  페이지 내 위치 : 회사소개  > 특허/인증 > 목록보기 버튼
                                */}
                                {t('CERTIFICATION.button.list')}
                              </button>
                            </div>
                          </div>
                        ) : null}
                      </li>
                    );
                  })}
                </ul>
                <button className="ThiraAmrAppCase__more" onClick={() => handleClickMore(c.id)}>
                  {/* 
                    텍스트 위치 : CERTIFICATION > button > more
                    페이지 내 위치 : 회사소개  > 특허/인증 > 더보기 버튼
                  */}
                  {t('CERTIFICATION.button.more')}
                </button>
              </section>
            );
          })}
          {/* 인증서 부분 추가 끝 */}
          {/* <ul className="ThiraCertification__content">
            {certificationList?.map((list, index) => {
              return (
                <li key={'certificationList' + list.id} data-aos="fade-up" data-aos-delay={100 * index}>
                  <figure className="img-wrap">
                    <img src={list.img} alt={list.fileName} />
                    <div className="file-icon">
                      <img src={list.svg} alt="" />
                    </div>
                    <a className="img-download-link" href={list.fileLink} download>
                      <span>Download</span>
                    </a>
                  </figure>
                  <p className="content-name">{list.fileName}</p>
                  <a className="img-download-link mo-block" href={list.fileLink} download>
                    <span>Download</span>
                  </a>
                </li>
              );
            })}
          </ul> */}
          {/* {page.current < paging.final && (
            <button className="ThiraAmrAppCase__more " onClick={handleClickMore}>
              More
            </button>
          )} */}
          {/* MOBILE 3개씩 보여짐 */}
          {/* 인증서 부분 추가 */}
          {/*         
          <ul className="ThiraCertification__content mo-flex">
            {certificationList?.map((list, index) => {
              return (
                <li key={'MobileCertificationList' + list.id} data-aos="fade-up" data-aos-delay={100 * index}>
                  <figure className="img-wrap">
                    <img src={list.img} alt={list.fileName} />
                    <div className="file-icon">
                      <img src={list.svg} alt="" />
                    </div>
                    <a className="img-download-link" href={list.fileLink} download>
                      <span>Download</span>
                    </a>
                  </figure>
                  <p className="content-name">{list.fileName}</p>
                  <a className="img-download-link mo-block" href={list.fileLink} download>
                    <span>Download</span>
                  </a>
                </li>
              );
            })}
          </ul>
          <div className="more-button-wrap mo-block">
            {page.current < paging.final && (
              <button type="button" onClick={handleClickMore}>
                More
              </button>
            )}
          </div> */}
        </div>
      </section>
    </React.Fragment>
  );
}
export default ThiraCertification;
