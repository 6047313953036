import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import * as InquiryJs from '../../assets/js/inquiry';
import {ThiraTitle} from '../../component/common/index';
import CaseBanner from '../../component/contents/caseBanner';
import {useIntl} from 'react-intl'; // 다국어
import {useTranslation} from 'react-i18next';
import LineWrap from '../../component/common/lineWrap';
import i18next from '../../i18n';

function ThiraInquiryComplete() {
  // 23.11.28일 수정 start
  const lang = i18next.language; // 현재 보여지는 언어
  const {t} = useTranslation('inquiry');
  const translatedLinkList = t('COMPLETE.link.linkList', {returnObjects: true});
  const translatedBannerList = t('COMPLETE.caseBannerData', {returnObjects: true});
  // 상단 링크
  const linkList = [
    {
      url: `/${lang}/amr/introduction`,
    },
    {
      url: '/', // DEV :: TR 뉴스에 대한 링크, 이벤트 필터를 필터링하여 모든 TR 무역 박람회 뉴스 등을 표시합니다.
    },
    {
      url: `${lang}/amr/CaseStudies`,
    },
    {
      url: `/${lang}/product/models`,
    },
  ];

  // 23.11.28일 수정 end
  //
  //
  //

  return (
    <section className="ThiraInquiryComplete">
      <div className="inner-width">
        <div className="ThiraInquiry__text-container" data-aos="fade-left">
          <h2 className="ThiraTitle">{t('COMPLETE.title')}</h2>
          <p className="sub-title">
            <LineWrap text={t('COMPLETE.subTitle')}></LineWrap>
          </p>

          <div className="util-link-list flex">
            {translatedLinkList.map((item, index) => (
              <Link key={index} to={linkList[index].url}>
                {item.title}
              </Link>
            ))}
          </div>
        </div>
        <div className="ThiraInquiry__form-container"></div>
      </div>
      {/* PUB :: 20230517 추가 */}
      <div className="inner-width">
        {/* <CaseBanner /> */}
        <div className="ThiraCaseCard">
          <h4> {t('COMPLETE.subHeadingTitle')}</h4>
          <ul className="case-card">
            {translatedBannerList?.map((list, index) => {
              return (
                <li className="case-card__list" key={'card' + index}>
                  <b className="case-card__name">
                    {list.title}
                    <br />({list.company})
                  </b>
                  <p className="case-card__desc">{list.desc}</p>
                  <Link className="case-card__link" to={`/${lang}/amr/CaseStudies/detail?board_id=4&contents_id=${list.urlId}`}>
                    {list.linkTitle}
                  </Link>
                </li>
              );
            })}
            <li className="case-card__list empty"></li>
          </ul>
        </div>
      </div>
      {/* // PUB :: 20230517 추가 */}
    </section>
  );
}

export default ThiraInquiryComplete;
