import {Link} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';

function ThiraProductLocation(props) {
  const location = useLocation();
  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어
  // 다국어 세팅
  const {t} = useTranslation('product');

  return (
    <div className="ThiraLocation">
      <Link className="ThiraLocation__btn" to={`/${lang}`}>
        {t('navigation.home')}
      </Link>
      <span className="icon"></span>
      <Link className="ThiraLocation__btn" to={`/${lang}/product/models`}>
        {t('navigation.desc')}
      </Link>
      <span className="icon"></span>
      <Link className="ThiraLocation__btn" to={`/${lang}/product/models`}>
        {t('navigation.model')}
      </Link>
      <span className="icon"></span>
      <select
        name="sel_page"
        className="ThiraLocation__sel"
        defaultValue={props.default}
        onChange={e => {
          const sel_val = e.target.closest('select').value;
          window.location.href = sel_val;
        }}
      >
        <optgroup label={t('section.0.title')}>
          <option value="T200">T200</option>
          <option value="T300">T300</option>
          <option value="T600">T600</option>
          <option value="T1000">T1000</option>
          <option value="T1000F">T1000F</option>
        </optgroup>
        <optgroup label={t('section.1.title')}>
          <option value="L200">L200</option>
        </optgroup>
        <optgroup label={t('section.2.title')}>
          <option value="chargingSystem">Charging system</option>
        </optgroup>
        <optgroup label={t('section.3.title')}>
          <option value="T300_arm">T300 Arm</option>
          <option value="T300_conveyor_A">T300 ConveyorA</option>
          <option value="T300_conveyor_B">T300 ConveyorB</option>
          <option value="T300_lift">T300 Lift</option>
          <option value="T1000_conveyor">T1000 Conveyor</option>
          <option value="L200_lidar">L200 Lidar</option>
        </optgroup>
      </select>
    </div>
  );
}

export default ThiraProductLocation;
