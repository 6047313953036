import {Outlet} from 'react-router-dom';

function ThiraProductDetail(props) {
  return (
    <div className="ThiraProductDetail">
      <div className="inner-width">
        <Outlet name={props.name} />
      </div>
    </div>
  );
}

export default ThiraProductDetail;
