import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Modal from '../../util/Modal';
import DefaultModal from '../../component/modal/DefaultModal';
import axios from 'axios';
import i18next from '../../i18n';
// swiper
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import {Autoplay, EffectCoverflow, Pagination} from 'swiper';

// img
import News01Jpg from '../../assets/images/main/news_01.jpg';
import News02Jpg from '../../assets/images/main/news_02.jpg';
import News03Jpg from '../../assets/images/main/news_03.jpg';

function ThiraMainNews() {
  // 기본 언어
  const defaultLang = localStorage.getItem('lang');
  // 기본 언어
  const {t} = useTranslation('main');
  // 팝업 열기
  const [isActiveModal, setIsActiveModal] = useState(false);
  const onClickModalOpen = () => {
    setIsActiveModal(true);
    Modal.modalOpen();
  };

  const ModalData = {
    comingSoon: true,
    isState: {
      state: isActiveModal,
      setState: setIsActiveModal,
    },
  };
  const [apiData, setApiData] = useState(null);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/board/2?lang=${i18next.language}&pnum=15`)
      .then(response => {
        setApiData(response.data);
      })
      .catch(response => {
        console.log('Error!');
      });
  }, []);

  const NewsData = apiData?.list.map(i => {
    return {
      id: i.id,
      img: i.thumb_img?`/storage/${i.thumb_img}`:'',
      title: i.title,
      contents: i.contents.replace(/(<([^>]+)>)/gi, ''),
      date: i.reg_date.substring(0, 10),
      url: `/${i18next.language}/resource/newsBlog/detail?board_id=2&contents_id=${i.id}`
    };
  });
  // PUB :: 뉴스 링크 연결
  // const NewsData = [
  //   {
  //     img: News01Jpg,
  //     title: t('mainNews.newsData.0.title'),
  //     contents: t('mainNews.newsData.0.contents'),
  //     date: t('mainNews.newsData.0.date'),
  //     url: t('mainNews.newsData.0.url'),
  //   },
  //   {
  //     img: News02Jpg,
  //     title: t('mainNews.newsData.1.title'),
  //     contents: t('mainNews.newsData.1.contents'),
  //     date: t('mainNews.newsData.1.date'),
  //     url: t('mainNews.newsData.1.url'),
  //   },
  //   {
  //     img: News03Jpg,
  //     title: t('mainNews.newsData.2.title'),
  //     contents: t('mainNews.newsData.2.contents'),
  //     date: t('mainNews.newsData.2.date'),
  //     url: t('mainNews.newsData.2.url'),
  //   },
  //   {
  //     img: News01Jpg,
  //     title: t('mainNews.newsData.0.title'),
  //     contents: t('mainNews.newsData.0.contents'),
  //     date: t('mainNews.newsData.0.date'),
  //     url: t('mainNews.newsData.0.url'),
  //   },
  //   {
  //     img: News02Jpg,
  //     title: t('mainNews.newsData.1.title'),
  //     contents: t('mainNews.newsData.1.contents'),
  //     date: t('mainNews.newsData.1.date'),
  //     url: t('mainNews.newsData.1.url'),
  //   },
  //   {
  //     img: News03Jpg,
  //     title: t('mainNews.newsData.2.title'),
  //     contents: t('mainNews.newsData.2.contents'),
  //     date: t('mainNews.newsData.2.date'),
  //     url: t('mainNews.newsData.2.url'),
  //   },
  //   {
  //     img: News01Jpg,
  //     title: t('mainNews.newsData.0.title'),
  //     contents: t('mainNews.newsData.0.contents'),
  //     date: t('mainNews.newsData.0.date'),
  //     url: t('mainNews.newsData.0.url'),
  //   },
  //   {
  //     img: News02Jpg,
  //     title: t('mainNews.newsData.1.title'),
  //     contents: t('mainNews.newsData.1.contents'),
  //     date: t('mainNews.newsData.1.date'),
  //     url: t('mainNews.newsData.1.url'),
  //   },
  //   {
  //     img: News03Jpg,
  //     title: t('mainNews.newsData.2.title'),
  //     contents: t('mainNews.newsData.2.contents'),
  //     date: t('mainNews.newsData.2.date'),
  //     url: t('mainNews.newsData.2.url'),
  //   },
  //   {
  //     img: News01Jpg,
  //     title: t('mainNews.newsData.0.title'),
  //     contents: t('mainNews.newsData.0.contents'),
  //     date: t('mainNews.newsData.0.date'),
  //     url: t('mainNews.newsData.0.url'),
  //   },
  //   {
  //     img: News02Jpg,
  //     title: t('mainNews.newsData.1.title'),
  //     contents: t('mainNews.newsData.1.contents'),
  //     date: t('mainNews.newsData.1.date'),
  //     url: t('mainNews.newsData.1.url'),
  //   },
  // ];

  return (
    apiData 
    ? <React.Fragment>
      <DefaultModal data={ModalData} />
      <section className="ThiraMainNews section">
        <hgroup>
          <h3 className="main-title" data-aos="fade-up">
            {t('mainNews.main.title')}
          </h3>
          <p className="main-desc" data-aos="fade-up" data-aos-delay="300">
            {t('mainNews.main.desc')}
          </p>
        </hgroup>
        {/* pc */}
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={30}
          loop={true}
          autoplay={{
            delay: 2500,
          }}
          centeredSlides={true}
          watchOverflow={true}
          modules={[Autoplay]}
          className="news-swiper pc-block"
        >
          {NewsData?.map((item, index) => {
            return (
              <SwiperSlide className="news" key={'NewsList' + index}>
                <figure className="news__img">
                {item.img && <img src={item.img} alt={item.title + '이미지'} />}
                </figure>
                <p className="news__title">{item.title}</p>
                <p className="news__desc">{item.contents}</p>
                <div className="news__util">
                  <span className="news__date">{item.date}</span>
                  {/* <Link to={item.url} className="news__more-btn" onClick={onClickModalOpen}> */}
                  <Link to={item.url} className="news__more-btn">
                    more
                  </Link>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        {/* mobile */}
        <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          // slidesPerView={'auto'}
          slidesPerView={1.5}
          coverflowEffect={{
            rotate: 0,
            stretch: -50,
            depth: 500,
          }}
          loop={true}
          modules={[EffectCoverflow, Pagination]}
          className="news-swiper mo-block"
        >
          {NewsData?.map((item, index) => {
            return (
              <SwiperSlide className="news" key={'NewsList' + index}>
                <figure className="news__img">
                {item.img && <img src={item.img} alt={item.title + '이미지'} />}
                </figure>
                <p className="news__title">{item.title}</p>
                <p className="news__desc">{item.contents}</p>
                <div className="news__util">
                  <span className="news__date">{item.date}</span>
                  {/* <Link to={item.url} className="news__more-btn" onClick={onClickModalOpen}> */}
                  <Link to={item.url} className="news__more-btn">
                    More
                  </Link>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </section>
    </React.Fragment>
    : null
  );
}

export default ThiraMainNews;
