import React, {useState, useEffect} from 'react';
import {ThiraTitle} from '../../component/common/index';
import ThiraBanner from '../../component/contents/banner';
import {ThiraAmrLocation} from '../../component/amr/index';
import * as AmrJs from '../../assets/js/amr';
import {useIntl} from 'react-intl'; // 다국어
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';
// swiper
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import {Autoplay, EffectFade, Navigation} from 'swiper';
import LineWrap from '../../component/common/lineWrap';
// img
import BannerImg01Jpg from '../../assets/images/amr/banner_amr.jpg';
import BannerImg02Jpg from '../../assets/images/about/banner_solution.jpg';
import BannerImg03Jpg from '../../assets/images/amr/banner_app.jpg';
import Icon01Svg from '../../assets/images/amr/ico_fms_monitoring.svg';
import Icon02Svg from '../../assets/images/amr/ico_fms_algorithms.svg';
import Icon03Svg from '../../assets/images/amr/ico_fms_manage.svg';
import Icon04Svg from '../../assets/images/amr/ico_fms_collision.svg';
import Icon05Svg from '../../assets/images/amr/ico_fms_workflow.svg';
import Icon06Svg from '../../assets/images/amr/ico_fms_modeling.svg';
import FmsBigBanner from '../../assets/images/amr/fms_big_banner.jpg';
import FmsMonitor from '../../assets/images/amr/system_monitor.png';
import Slider01Jpg from '../../assets/images/amr/fms_banner_01.jpg';
import Slider02Jpg from '../../assets/images/amr/fms_banner_02.jpg';
import Slider03Jpg from '../../assets/images/amr/fms_banner_03.jpg';
import Slider04Jpg from '../../assets/images/amr/fms_banner_04.jpg';
import Slider05Jpg from '../../assets/images/amr/fms_banner_05.jpg';
import Slider06Jpg from '../../assets/images/amr/fms_banner_06.jpg';

function ThiraAmrFms() {
  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어
  // 다국어 세팅
  const {t} = useTranslation('amr');

  // icon 데이터
  const iconList = [Icon01Svg, Icon02Svg, Icon03Svg, Icon04Svg, Icon05Svg, Icon06Svg];

  // thumb swiper
  const fmstmSliderData = [Slider01Jpg, Slider02Jpg, Slider03Jpg, Slider04Jpg, Slider05Jpg, Slider06Jpg];

  // 배너 컴포넌트 데이터
  const bannerData = {
    bannerTitle: t('fms.banner.title'),
    bannerList: [
      {
        url: `/${lang}/amr/introduction`,
        background: BannerImg01Jpg,
        text: t('fms.banner.bannerList.0'),
      },
      {
        url: `/${lang}/amr/solutions`,
        background: BannerImg02Jpg,
        text: t('fms.banner.bannerList.1'),
      },
      {
        url: `/${lang}/amr/CaseStudies`,
        background: BannerImg03Jpg,
        text: t('fms.banner.bannerList.2'),
      },
    ],
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const handleSlideChange = swiper => {
    setActiveIndex(swiper.realIndex);
  };

  useEffect(() => {
    AmrJs.lazyLoad();
  });

  return (
    <div className="ThiraAmrFms">
      <div className="inner-width">
        <ThiraAmrLocation name={t('fms.title')} link="fms"></ThiraAmrLocation>
        <ThiraTitle title={t('fms.title')}></ThiraTitle>
      </div>
      <hr className="ThiraBorder" />
      <div className="inner-width">
        <hgroup>
          <h3 className="txt-01">{t('fms.descriptions.0.title')}</h3>
          <p className="txt-02 pc-block">
            <LineWrap text={t('fms.descriptions.0.body')} />
          </p>
        </hgroup>
        <figure className="big-img">
          <img src={FmsBigBanner} alt="banner img" />
        </figure>
        {/* PUB :: 20230517 추가 */}
        {/* FMS System */}
        <div className="ThiraAmrFms__system">
          <div className="text-box">
            <p className="txt-01 mb-init">
              THIRA FMS
              <br />
              AMR Integrated Control FMS System
            </p>
            <p className="txt-02">{t('fms.system.subTitle')}</p>
            <p className="desc">
              {t('fms.system.body')}
              <br />
              <br />
              <br />
            </p>
            <ul>
              {/* {LANG.system.list.map((list, index) => {
                return <li className="desc">• {list}</li>;
              })} */}
              {t('fms.system.list', {returnObjects: true}).map(item => (
                <li className="desc">• {item}</li>
              ))}
            </ul>
          </div>
          <div className="img-box">
            <img src={FmsMonitor} alt="모니터 사진" />
          </div>
        </div>
        {/* // PUB :: 20230517 추가 */}
        {/* ICON */}
        <article className="ThiraAmrFms__icon-list">
          <ul>
            {iconList.map((img, index) => {
              return (
                <li key={'iconList' + index}>
                  <figure>
                    <img src={img} alt="아이콘" />
                  </figure>
                  <div className="icon-text">
                    <p className="txt-03">{t(`fms.iconList.${index}.title`)}</p>
                    {t(`fms.iconList.${index}.desc`, {returnObjects: true}).map((desc, idx) => (
                      // <p key={`fms_iconList_${index}_desc_${idx}`}>- {desc}</p>
                      <p className="fms_descText" key={`fms_iconList_${index}_desc_${idx}`}>
                        {desc}
                      </p>
                    ))}
                  </div>
                </li>
              );
            })}
          </ul>
        </article>

        {/* <article className="ThiraAmrFms__fmstm">
          <h3 className="txt-01">{t('fms.fmstmSlider.title')}</h3>
          <hr />
          {/* pc 
          <div className="fmstm-content pc-flex">
            <div className="content-view">
              <div className="text-box" data-aos="fade-left">
                <p className="txt-01">{t(`fms.fmstmSlider.fmstmSliderData.${activeIndex}.title`)}</p>
                {t(`fms.fmstmSlider.fmstmSliderData.${activeIndex}.desc`, {returnObjects: true}).map((list, index) => {
                  return <p key={index}>- {list}</p>;
                })}
                <button type="button" className="swiper-next-button">
                  {t('fms.fmstmSlider.nextButton')}
                </button>
              </div>
              <div className="img-box" data-aos="fade-up">
                <img src={fmstmSliderData[activeIndex]} alt="이미지" />
              </div>
            </div>
            <Swiper
              spaceBetween={15}
              slidesPerView={4}
              direction="vertical"
              navigation={{
                nextEl: '.swiper-next-button',
              }}
              centeredSlides={true}
              watchSlidesProgress={true}
              slideToClickedSlide={true}
              loop={true}
              autoplay={{
                delay: 2500,
              }}
              modules={[Navigation, Autoplay]}
              className="small-swiper"
              onSlideChange={handleSlideChange}
            >
              {fmstmSliderData.map((img, index) => {
                return (
                  <SwiperSlide key={'slideData2' + index}>
                    <img src={img} alt="이미지" />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          {/* mobile 
          <div className="fmstm-content mo-block">
            <Swiper
              navigation={{
                prevEl: '.mo-swiper-prev-button',
                nextEl: '.mo-swiper-next-button',
              }}
              autoplay={{
                delay: 2500,
              }}
              modules={[Navigation, Autoplay]}
              className="big-thumb-swiper"
            >
              {fmstmSliderData.map((img, idx) => {
                return (
                  <SwiperSlide key={'slideData' + idx}>
                    <div className="swiper-img-box">
                      <img src={img} alt="이미지" />
                    </div>
                    <div className="text-box">
                      {t(`fms.fmstmSlider.fmstmSliderData.${activeIndex}.desc`, {returnObjects: true}).map((list, index) => {
                        return <p key={index}>- {list}</p>;
                      })}
                    </div>
                  </SwiperSlide>
                );
              })}
              <div className="button-wrap">
                <button type="button" className="mo-swiper-prev-button">
                  {t('fms.fmstmSlider.previousButton')}
                </button>
                <button type="button" className="mo-swiper-next-button">
                  {t('fms.fmstmSlider.nextButton')}
                </button>
              </div>
            </Swiper>
          </div>
        </article> */}
      </div>
      {/* ThiraAmrFms__fmstm2 */}
      {/* <hr className="ThiraBorder" />
      <article className="ThiraAmrFms__fmstm2">
        <div className="inner-width">
          <div className="fmstm-content">
            <Swiper
              effect={'fade'}
              autoplay={{
                delay: 2500,
              }}
              loop={true}
              navigation={{
                nextEl: '.fmstm2-swiper-next-button',
              }}
              modules={[Navigation, Autoplay, EffectFade]}
              className="fmstmSlider_2-swiper gray-navigation"
            >
              {LANG.fmstmSlider_2.fmstmSliderData.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="text-box">
                      <div className="txt-01">{item.title}</div>
                      {LANG.fmstmSlider_2.fmstmSliderData[index].desc.map((list, index) => {
                        return (
                          <p className="desc" key={index}>
                            - {list}
                          </p>
                        );
                      })}
                      <button type="button" className="fmstm2-swiper-next-button">
                        {LANG.fmstmSlider.nextButton}
                      </button>
                    </div>
                    <div className="img-box coming-soon">
                      <img src={bottomSwiperData[index]} alt="이미지" />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </article> */}
      <hr className="ThiraBorder" />
      <ThiraBanner data={bannerData} />
    </div>
  );
}

export default ThiraAmrFms;
