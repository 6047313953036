import React, {useEffect} from 'react';
import {ThiraTitle} from '../../component/common/index';
import * as AmrJs from '../../assets/js/amr';
import {ThiraAmrLocation} from '../../component/amr/index';
import ThiraBanner from '../../component/contents/banner';
import {useIntl} from 'react-intl'; // 다국어
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';
// Sources
import Banner01 from '../../assets/images/amr/intro_banner_01.jpg';
import bannerFMS from '../../assets/images/amr/banner_fms.png';
import bannerAppCase from '../../assets/images/amr/banner_application_case.png';
import bannerSolutions from '../../assets/images/amr/banner_solutions.png';
import LineWrap from '../../component/common/lineWrap';

function ThiraAmrIntroduction(props) {
  useEffect(() => {
    AmrJs.lazyLoad();
  });

  // 다국어 세팅
  // const intl = useIntl();
  // const LANG = intl.messages.THIRA_AMR.INTRODUCTION;
  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어
  // 다국어 세팅
  const {t} = useTranslation('amr');

  // 배너 컴포넌트 데이터
  const bannerData = {
    bannerTitle: t('introduction.banner.title'),
    bannerList: [
      {
        url: `/${lang}/amr/fms`,
        background: bannerFMS,
        text: t('introduction.banner.bannerList.0'),
      },
      {
        url: `/${lang}/amr/solutions`,
        background: bannerSolutions,
        text: t('introduction.banner.bannerList.1'),
      },
      {
        url: `/${lang}/amr/CaseStudies`,
        background: bannerAppCase,
        text: t('introduction.banner.bannerList.2'),
      },
    ],
  };

  return (
    <div className="ThiraAmrIntroduction">
      <div className="inner-width">
        <ThiraAmrLocation name={t('introduction.title')} link="introduction"></ThiraAmrLocation>
        <ThiraTitle title={t('introduction.title')}></ThiraTitle>
      </div>
      <hr className="ThiraBorder" />
      <div className="inner-width">
        <h3 className="txt-01">{t('introduction.descriptions.0.title')}</h3>
        <figure className="big-img mt-40">
          <img src={Banner01} alt="banner img" />
        </figure>
        <p className="txt-02 mt-40">
          {/* {LANG.descriptions[0].body.split('\n').map((line, index) => {
            return (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            );
          })} */}
          <LineWrap text={t('introduction.descriptions.0.body')} />
        </p>
      </div>
      <hr className="ThiraBorder" />
      <ThiraBanner data={bannerData} />
    </div>
  );
}

export default ThiraAmrIntroduction;
