'use strict';

// 제품 소개 > 상단 큰 썸네일 & 제품 소개 영역 & 첫 번째 상세내용 시간차 노출
export function showTitle() {
  const $target = document.querySelectorAll('.ThiraProductDetail__top > *');
  const $target_li = document.querySelector('.ThiraProductDetail__cont').querySelectorAll('li');

  $target.forEach((el, index) => {
    setTimeout(() => {
      el.classList.add('active');
      document.querySelectorAll('.ThiraProductDetail__topInf > *').forEach((el, index) => {
        setTimeout(() => {
          el.classList.add('active');
        }, 200 * index);
      });
    }, 200 * index);
  });

  $target_li.forEach((el, index) => {
    setTimeout(() => {
      el.classList.add('active');
    }, 100 * index);
  });
}

// 제품 소개 > 상단 작은 썸네일 시간차 노출
export function showThumbs() {
  document.querySelectorAll('.ThiraProductDetail__smallThum button').forEach((el, index) => {
    setTimeout(() => {
      el.classList.add('active');
    }, 200 * index);
  });
}

// 제품 소개 > 제품 상세 보기(View Detail Page) 클릭 시 상세 내용으로 스크롤 이동
export function viewDetail(el) {
  const $target = document.querySelector('.ThiraProductDetail__cont');
  const yOffset = -100;
  const y = $target.getBoundingClientRect().top + yOffset;

  window.scrollTo({ top: y, behavior: 'smooth' });
}

// 제품 소개 > 작은 썸네일 클릭 시 큰 썸네일 교체 + border 효과
export function showPopup(e) {
  const $img_src = document.querySelectorAll('.ThiraProductDetail__smallThum button:not(.more)');
  const $selected_img_src = e.querySelector('img').getAttribute('src');
  const $big_thum = e.closest('.ThiraProductDetail__top').querySelector('.ThiraProductDetail__bigThum img');

  $big_thum.setAttribute('src', $selected_img_src);

  [...$img_src].map(item => item.classList.remove('border'));
  e.classList.add('border');
}

// 제품 소개 > 상세 내용 우측 화살표 클릭 시 내용 토글
export function toggleArrow(el) {
  // const $target = el.closest('.ThiraProductDetail__cont');
  // const $cont_all = document.querySelectorAll('.ThiraProductDetail__cont');
  // const $target_li = $target.querySelectorAll('li');
  // const $target_text = el.childNodes[0].nodeValue;

  // $cont_all.forEach(el => {
  //   if (el === $target) {
  //     $target.classList.toggle('active');
  //     $target_li.forEach((el, index) => {
  //       setTimeout(() => {
  //         el.classList.add('active');
  //       }, 100 * index);
  //     });
  //   } else {
  //     el.classList.remove('active');
  //     el.querySelectorAll('li').forEach(el => {
  //       el.classList.remove('active');
  //     });
  //   }
  // });
  // 토글 각각 직접 여닫는 방식으로 변경
  const $target = el.closest('.ThiraProductDetail__cont');
  const $target_li = $target.querySelectorAll('li');
  const isActive = $target.classList.contains('active');

  if (isActive) {
    $target.classList.remove('active');
    $target_li.forEach((el, index) => {
      setTimeout(() => {
        el.classList.remove('active');
      }, 100 * index);
    });
  } else {
    $target.classList.add('active');
    $target_li.forEach((el, index) => {
      setTimeout(() => {
        el.classList.add('active');
      }, 100 * index);
    });
  }
}

// 제품 비디오 시작시간 12초로 조정.
export function setVideoStartTime() {
  var video = document.querySelector('.ThiraProductDetail__specification video');
  video.addEventListener(
    'loadedmetadata',
    function () {
      this.currentTime = 12;
    },
    false,
  );
}
