import React from 'react';
// import {Link} from 'react-router-dom';
import {Link} from 'react-scroll';
import {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import i18next from '../../i18n';

function ThiraPolicy({closePopup}) {
  // 기본 언어
  const lang = i18next.language; // 현재 보여지는 언어

  // const thira_url = 'https://www.thirarobotics.com/';

  function tableGo(nickname) {
    const cls = '.' + nickname;
    const scl = document.querySelector(cls);
    if (scl) {
      scl.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }
  return (
    <React.Fragment>
      <>
        {/* PC */}
        <aside className="popup-overlay privacy">
          <div className="popup-content">
            <div className="popup-content__title">
              <h2>{lang === 'ko' ? '개인정보 처리방침' : 'Privacy Policy'}</h2>
            </div>
            <div className="popup-content__txt">
              {lang === 'ko' ? (
                <>
                  <p>
                    {`<(주)티라로보틱스>(‘https://thirarobotics.com/’이하 ‘(주)티라로보틱스’)는 개인정보보호법에 따라
                        이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수
                        있도록 다음과 같은 처리방침을 두고 있습니다.`}
                  </p>
                  <p>
                    {`<(주)티라로보틱스>(‘(주)티라로보틱스’)는 회사는 개인정보처리방침을 개정하는 경우 
                        웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.`}
                  </p>
                  <p>{`○ 본 방침은부터 2023년 05월 31일부터 시행됩니다.`}</p>
                  <ol className="popup-content__txt__ol decimal">
                    {/* 조항1 */}
                    <li>
                      <p className="title">
                        {`개인정보의 처리 목적 <(주)티라로보틱스>(‘https://thirarobotics.com/’이하 ‘(주)티라로보틱스’)은(는) 
                        개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 
                        사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.`}
                      </p>
                      <ol className="popup-content__txt__ol-list kr">
                        <li>
                          <h4>홈페이지 회원가입 및 관리</h4>
                          <p>{`서비스 부정이용 방지, 각종 고지·통지, 고충처리, 분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다.`}</p>
                        </li>
                        <li>
                          <h4>민원사무 처리</h4>
                          <p>{`민원인의 신원 확인, 민원사항 확인, 처리결과 통보 등을 목적으로 개인정보를 처리합니다.`}</p>
                        </li>
                      </ol>
                    </li>
                    {/* 조항2 */}
                    <li>
                      <p className="title">
                        {`개인정보 파일 현황`}
                        <br />
                        {`(‘https://thirarobotics.com/’이하 ‘(주)티라로보틱스’)가 개인정보 보호법 제32조에 따라 등록․공개하는 개인정보파일의 처리목적은 다음과 같습니다.`}
                      </p>
                      <ol className="popup-content__txt__ol-list decimal">
                        <li>
                          <h4>개인정보 파일명 : 티라로보틱스_Q&A_정보수집</h4>
                          <ul className="popup-content__txt__ol-list__ul">
                            <li>개인정보 항목 : 이메일, 휴대전화번호, 이름, 회사전화번호, 직책, 부서, 회사명</li>
                            <li> 수집방법 : 홈페이지</li>
                            <li>보유근거 : 티라로보틱스_Q&A_정보수집</li>
                            <li>보유기간 : 3년</li>
                            <li>관련법령 : 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</li>
                          </ul>
                        </li>
                      </ol>
                      <p>
                        {`※ 기타(‘https://thirarobotics.com/’이하 ‘(주)티라로보틱스’)의 개인정보파일 등록사항 공개는 행정안전부
                            개인정보보호 종합지원 포털(www.privacy.go.kr) → 개인정보민원 → 개인정보열람등 요구 →
                            개인정보파일 목록검색 메뉴를 활용해주시기 바랍니다.`}
                      </p>
                    </li>
                    {/* 조항3 */}
                    <li>
                      <h3>개인정보의 처리 및 보유 기간 </h3>
                      <ol className="popup-content__txt__ol-list circleInNum">
                        <li>
                          {`<(주)티라로보틱스>(‘(주)티라로보틱스’)은(는) 법령에 따른 개인정보 보유·이용기간 또는
                                정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유,이용기간 내에서 개인정보를
                                처리,보유합니다.`}
                        </li>
                        <li>
                          {`각각의 개인정보 처리 및 보유 기간은 다음과 같습니다`}
                          <ol className="popup-content__txt__ol-list__ol num">
                            <li>
                              <h5>{`<민원사무 처리>`}</h5>
                              <p>{`<민원사무 처리>와 관련한 개인정보는 수집.이용에 관한 동의일로부터<3년>까지 위 이용목적을
                                위하여 보유.이용됩니다.`}</p>
                              <ul className="dashed">
                                <li>보유근거 : 티라로보틱스_Q&A_정보수집</li>
                                <li>관련법령 : 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</li>
                                <li>예외사유 :</li>
                              </ul>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    {/* 조항4 */}
                    <li>
                      <h3>정보주체와 법정대리인의 권리·의무 및 그 행사방법 이용자는 개인정보주체로써 다음과 같은 권리를 행사할 수 있습니다.</h3>
                      <ol className="popup-content__txt__ol-list circleInNum">
                        <li>{`정보주체는 (주)티라로보틱스에 대해 언제든지 개인정보 열람,정정,삭제,처리정지 요구 등의 권리를 행사할 수 있습니다.`}</li>
                        <li>{`제1항에 따른 권리 행사는(주)티라로보틱스에 대해 개인정보 보호법 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 (주)티라로보틱스는(는) 이에 대해 지체 없이 조치하겠습니다.`}</li>
                        <li>{`제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.`}</li>
                        <li>{`개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.`}</li>
                        <li>{`개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.`}</li>
                        <li>{`⑥ (주)티라로보틱스는(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.`}</li>
                      </ol>
                    </li>
                    {/* 조항5 */}
                    <li>
                      <h3>처리하는 개인정보의 항목 작성 </h3>
                      <ol className="popup-content__txt__ol-list circleInNum">
                        <li>
                          {`<(주)티라로보틱스>(‘https://thirarobotics.com/’이하 ‘(주)티라로보틱스’)은(는) 다음의 개인정보 항목을 처리하고 있습니다.`}
                          <ol className="popup-content__txt__ol-list__ol num">
                            <li>
                              <h5>{`<민원사무 처리>`}</h5>
                              <ul className="dashed">
                                <li>필수항목 : 이메일, 휴대전화번호, 이름, 회사전화번호, 직책, 부서, 회사명</li>
                                <li>선택항목 : 자택주소, 성별, 생년월일, 직업</li>
                              </ul>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    {/* 조항6 */}
                    <li>
                      <h3>
                        {`개인정보의 파기<(주)티라로보틱스>(‘(주)티라로보틱스’)은(는) 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.`}
                      </h3>
                      <ul className="dashed">
                        <li>
                          <h4>파기절차</h4>
                          <p>
                            {`이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타
                                관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한
                                경우가 아니고서는 다른 목적으로 이용되지 않습니다.`}
                          </p>
                        </li>
                        <li>
                          <h4>파기기한</h4>
                          <p>
                            {`이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 
                            개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는
                            개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.`}
                          </p>
                        </li>
                        <li>
                          <h4>파기방법</h4>
                          <p>{`종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.`}</p>
                        </li>
                      </ul>
                    </li>
                    {/* 조항7 */}
                    <li>
                      <h3>{`개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항 `}</h3>
                      <ol className="circleInNum">
                        <li>
                          <p>{`(주)티라로보틱스 은 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠기(cookie)’를 사용합니다.`}</p>
                        </li>
                        <li>
                          <p>
                            {`쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는
                            소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다. 가. 쿠키의 사용 목적 : 
                            이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을
                            파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다. 나. 쿠키의 설치•운영 및 거부 : 
                            웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수
                            있습니다. 다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.`}
                          </p>
                        </li>
                      </ol>
                    </li>
                    {/* 조항8 */}
                    <li>
                      <h3>{`개인정보 보호책임자 작성`}</h3>
                      <ol className="circleInNum">
                        <li>
                          <p>
                            {`(주)티라로보틱스(‘https://thirarobotics.com/’이하 ‘(주)티라로보틱스) 은(는) 개인정보 처리에 관한
                            업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여
                            아래와 같이 개인정보 보호책임자를 지정하고 있습니다.`}
                          </p>
                          <ul className="triangle">
                            <li>
                              <h4>개인정보 보호 책임자</h4>
                              <ul>
                                <li>성명 : 송희림</li>
                                <li>직책 : 마케팅팀</li>
                                <li>직급 : 팀장</li>
                                <li>연락처 : 031-732-6531, sales@thirarobotics.com</li>
                                <li>※ 개인정보 보호 담당부서로 연결됩니다.</li>
                              </ul>
                            </li>
                            <li>
                              <h4>개인정보 보호 담당부서</h4>
                              <ul>
                                <li>부서명 :</li>
                                <li>담당자 :</li>
                                <li>연락처 :, ,</li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <p>
                            {`정보주체께서는 (주)티라로보틱스(‘https://thirarobotics.com/’이하 ‘(주)티라로보틱스)의 서비스(또는
                            사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을
                            개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 
                            (주)티라로보틱스(‘https://thirarobotics.com/’이하 ‘(주)티라로보틱스) 은(는) 정보주체의 문의에 대해
                            지체 없이 답변 및 처리해드릴 것입니다`}
                          </p>
                        </li>
                      </ol>
                    </li>
                    {/* 조항9 */}
                    <li>
                      <h3>{`개인정보 처리방침 변경`}</h3>
                      <ol className="circleInNum">
                        <li>
                          <p>{`이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및
                        정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.`}</p>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </>
              ) : (
                <>
                  <p>
                    {`We respect your privacy and are committed to protecting it through our compliance with this privacy policy (“Policy”). This Policy describes the types of information we may collect from you or that you may provide (“Personal Information”) on the `}
                    <a href="https://www.thirarobotics.com/" target="_blank">
                      thirarobotics.com
                    </a>
                    {` website (“Website” or “Service”) and any of its related products and services (collectively, “Services”), and our practices for collecting, using, maintaining, protecting, and disclosing that Personal Information. It also describes the choices available to you regarding our use of your Personal Information and how you can access and update it.`}
                  </p>
                  <p>
                    {`This Policy is a legally binding agreement between you (“User”, “you” or “your”) and THIRA Robotics (“THIRA Robotics”, “we”, “us” or “our”). If you are entering into this agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this agreement, in which case the terms “User”, “you” or “your” shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this agreement, you must not accept this agreement and may not access and use the Website and Services. By accessing and using the Website and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Policy. This Policy does not apply to the practices of companies that we do not own or control, or to individuals that we do not employ or manage.`}
                  </p>
                  <h3>Table of contents</h3>
                  <ol className="num">
                    <li>
                      <button onClick={() => tableGo('section-01')}>Collection of information</button>
                    </li>
                    <li>
                      <button onClick={() => tableGo('section-02')}>Privacy of children</button>
                    </li>
                    <li>
                      <button onClick={() => tableGo('section-03')}>Use and processing of collected information</button>
                    </li>
                    <li>
                      <button onClick={() => tableGo('section-04')}>Disclosure of information</button>
                    </li>
                    <li>
                      <button onClick={() => tableGo('section-05')}>Retention of information</button>
                    </li>
                    <li>
                      <button onClick={() => tableGo('section-06')}>Cookies</button>
                    </li>
                    <li>
                      <button onClick={() => tableGo('section-07')}>Data analytics</button>
                    </li>
                    <li>
                      <button onClick={() => tableGo('section-08')}>Do Not Track signals</button>
                    </li>
                    <li>
                      <button onClick={() => tableGo('section-09')}>Social media features</button>
                    </li>
                    <li>
                      <button onClick={() => tableGo('section-10')}>Links to other resources</button>
                    </li>
                    <li>
                      <button onClick={() => tableGo('section-11')}>Information security</button>
                    </li>
                    <li>
                      <button onClick={() => tableGo('section-12')}>Changes and amendments</button>
                    </li>
                    <li>
                      <button onClick={() => tableGo('section-13')}>Acceptance of this policy</button>
                    </li>
                    <li>
                      <button onClick={() => tableGo('section-14')}>The California Consumer Privacy Act</button>
                    </li>
                    <li>
                      <button onClick={() => tableGo('section-15')}>Contacting us</button>
                    </li>
                  </ol>
                  <div className="txt-section section-01">
                    <h3>Collection of information</h3>
                    <p>
                      {`
                      Our top priority is customer data security and, as such, we exercise the no logs policy. We may process only minimal user data, only as much as it is absolutely necessary to maintain the Website and Services. Information collected automatically is used only to identify potential cases of abuse and establish statistical information regarding the usage and traffic of the Website and Services. This statistical information is not otherwise aggregated in such a way that would identify any particular user of the system.
                      `}
                    </p>
                  </div>
                  <div className="txt-section section-02">
                    <h3>Privacy of children</h3>
                    <p>
                      {`
                      We do not knowingly collect any Personal Information from children under the age of 18. If you are under the age of 18, please do not submit any Personal Information through the Website and Services. If you have reason to believe that a child under the age of 18 has provided Personal Information to us through the Website and Services, please contact us to request that we delete that child’s Personal Information from our Services.
                      `}
                    </p>
                    <p>
                      {`
                      We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce this Policy by instructing their children never to provide Personal Information through the Website and Services without their permission. We also ask that all parents and legal guardians overseeing the care of children take the necessary precautions to ensure that their children are instructed to never give out Personal Information when online without their permission.
                      `}
                    </p>
                  </div>
                  <div className="txt-section section-03">
                    <h3>Use and processing of collected information</h3>
                    <p>
                      {`
                      We act as a data controller and a data processor when handling Personal Information, unless we have entered into a data processing agreement with you in which case you would be the data controller and we would be the data processor.
                      `}
                    </p>
                    <p>
                      {`
                      Our role may also differ depending on the specific situation involving Personal Information. We act in the capacity of a data controller when we ask you to submit your Personal Information that is necessary to ensure your access and use of the Website and Services. In such instances, we are a data controller because we determine the purposes and means of the processing of Personal Information.
                      `}
                    </p>
                    <p>
                      {`
                      We act in the capacity of a data processor in situations when you submit Personal Information through the Website and Services. We do not own, control, or make decisions about the submitted Personal Information, and such Personal Information is processed only in accordance with your instructions. In such instances, the User providing Personal Information acts as a data controller.
                      `}
                    </p>
                    <p>
                      {`
                      In order to make the Website and Services available to you, or to meet a legal obligation, we may need to collect and use certain Personal Information. If you do not provide the information that we request, we may not be able to provide you with the requested products or services. Any of the information we collect from you may be used for the following purposes:
                      `}
                    </p>
                    <ul className="fill-circle">
                      <li>Respond to inquiries and offer support</li>
                      <li>Request user feedback</li>
                      <li>Run and operate the Website and Services</li>
                    </ul>
                    <p>
                      {`
                      Processing your Personal Information depends on how you interact with the Website and Services, where you are located in the world and if one of the following applies: (i) you have given your consent for one or more specific purposes; (ii) provision of information is necessary for the performance of an agreement with you and/or for any pre-contractual obligations thereof; (iii) processing is necessary for compliance with a legal obligation to which you are subject; (iv) processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in us; (v) processing is necessary for the purposes of the legitimate interests pursued by us or by a third party.
                      `}
                    </p>
                    <p>
                      {`
                      Note that under some legislations we may be allowed to process information until you object to such processing by opting out, without having to rely on consent or any other of the legal bases. In any case, we will be happy to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Information is a statutory or contractual requirement, or a requirement necessary to enter into a contract.
                      `}
                    </p>
                  </div>
                  <div className="txt-section section-04">
                    <h3>Disclosure of information</h3>
                    <p>
                      {`
                      Depending on the requested Services or as necessary to complete any transaction or provide any Service you have requested, we may share your information with our affiliates, contracted companies, and service providers (collectively, “Service Providers”) we rely upon to assist in the operation of the Website and Services available to you and whose privacy policies are consistent with ours or who agree to abide by our policies with respect to Personal Information. We will not share any personally identifiable information with third parties and will not share any information with unaffiliated third parties.
                      `}
                    </p>
                    <p>
                      {`
                      Service Providers are not authorized to use or disclose your information except as necessary to perform services on our behalf or comply with legal requirements. Service Providers are given the information they need only in order to perform their designated functions, and we do not authorize them to use or disclose any of the provided information for their own marketing or other purposes.
                      `}
                    </p>
                  </div>
                  <div className="txt-section section-05">
                    <h3>Retention of information</h3>
                    <p>
                      {`
                      We will retain and use your Personal Information for the period necessary to comply with our legal obligations, to enforce our agreements, resolve disputes, and unless a longer retention period is required or permitted by law.
                      `}
                    </p>
                    <p>
                      {`
                      We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally. Once the retention period expires, Personal Information shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification, and the right to data portability cannot be enforced after the expiration of the retention period.
                      `}
                    </p>
                  </div>
                  <div className="txt-section section-06">
                    <h3>Cookies</h3>
                    <p>
                      {`
                      Our Website and Services use “cookies” to help personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.
                      `}
                    </p>
                    <p>
                      {`
                      We may use cookies to collect, store, and track information for security and personalization, and for statistical purposes. Please note that you have the ability to accept or decline cookies. Most web browsers automatically accept cookies by default, but you can modify your browser settings to decline cookies if you prefer.
                      `}
                    </p>
                  </div>
                  <div className="txt-section section-07">
                    <h3>Data analytics</h3>
                    <p>
                      {`
                      Our Website and Services may use third-party analytics tools that use cookies, web beacons, or other similar information-gathering technologies to collect standard internet activity and usage information. The information gathered is used to compile statistical reports on User activity such as how often Users visit our Website and Services, what pages they visit and for how long, etc. We use the information obtained from these analytics tools to monitor the performance and improve our Website and Services. We do not use third-party analytics tools to track or to collect any personally identifiable information of our Users and we will not associate any information gathered from the statistical reports with any individual User.
                      `}
                    </p>
                  </div>
                  <div className="txt-section section-08">
                    <h3>Do Not Track signals</h3>
                    <p>
                      {`
                      Some browsers incorporate a Do Not Track feature that signals to websites you visit that you do not want to have your online activity tracked. Tracking is not the same as using or collecting information in connection with a website. For these purposes, tracking refers to collecting personally identifiable information from consumers who use or visit a website or online service as they move across different websites over time. How browsers communicate the Do Not Track signal is not yet uniform. As a result, the Website and Services are not yet set up to interpret or respond to Do Not Track signals communicated by your browser. Even so, as described in more detail throughout this Policy, we limit our use and collection of your Personal Information. For a description of Do Not Track protocols for browsers and mobile devices or to learn more about the choices available to you, visit
                      `}
                      <a href="https://www.internetcookies.com" target="_blank">
                        internetcookies.com
                      </a>
                    </p>
                  </div>
                  <div className="txt-section section-09">
                    <h3>Social media features</h3>
                    <p>
                      {`
                      Our Website and Services may include social media features, such as the Facebook and Twitter buttons, Share This buttons, etc (collectively, “Social Media Features”). These Social Media Features may collect your IP address, what page you are visiting on our Website and Services, and may set a cookie to enable Social Media Features to function properly. Social Media Features are hosted either by their respective providers or directly on our Website and Services. Your interactions with these Social Media Features are governed by the privacy policy of their respective providers.
                      `}
                    </p>
                  </div>
                  <div className="txt-section section-10">
                    <h3>Links to other resources</h3>
                    <p>
                      {`
                      The Website and Services contain links to other resources that are not owned or controlled by us. Please be aware that we are not responsible for the privacy practices of such other resources or third parties. We encourage you to be aware when you leave the Website and Services and to read the privacy statements of each and every resource that may collect Personal Information.
                      `}
                    </p>
                  </div>
                  <div className="txt-section section-11">
                    <h3>Information security</h3>
                    <p>
                      {`
                      We secure information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use, or disclosure. We maintain reasonable administrative, technical, and physical safeguards in an effort to protect against unauthorized access, use, modification, and disclosure of Personal Information in our control and custody. However, no data transmission over the Internet or wireless network can be guaranteed.
                      `}
                    </p>
                    <p>
                      {`
                      Therefore, while we strive to protect your Personal Information, you acknowledge that (i) there are security and privacy limitations of the Internet which are beyond our control; (ii) the security, integrity, and privacy of any and all information and data exchanged between you and the Website and Services cannot be guaranteed; and (iii) any such information and data may be viewed or tampered with in transit by a third party, despite best efforts.
                      `}
                    </p>
                  </div>
                  <div className="txt-section section-12">
                    <h3>Changes and amendments</h3>
                    <p>
                      {`
                      We reserve the right to modify this Policy or its terms related to the Website and Services at any time at our discretion. When we do, we will post a notification on the main page of the Website. We may also provide notice to you in other ways at our discretion, such as through the contact information you have provided.
                      `}
                    </p>
                    <p>
                      {`
                      An updated version of this Policy will be effective immediately upon the posting of the revised Policy unless otherwise specified. Your continued use of the Website and Services after the effective date of the revised Policy (or such other act specified at that time) will constitute your consent to those changes. However, we will not, without your consent, use your Personal Information in a manner materially different than what was stated at the time your Personal Information was collected.
                      `}
                    </p>
                  </div>
                  <div className="txt-section section-13">
                    <h3>Acceptance of this policy</h3>
                    <p>
                      {`
                      You acknowledge that you have read this Policy and agree to all its terms and conditions. By accessing and using the Website and Services and submitting your information you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to access or use the Website and Services.
                      `}
                    </p>
                  </div>
                  <div className="txt-section section-14">
                    <h3>The California Consumer Privacy Act</h3>
                    <p>
                      {`
                      The California Consumer Privacy Act (CCPA) gives California residents certain rights over their personal information. These rights include the right to:
                      `}
                    </p>
                    <ol className="num">
                      <li>Know what personal information a business has collected about you.</li>
                      <li>Request a copy of your personal information.</li>
                      <li>Request that a business delete your personal information.</li>
                      <li>Restrict a business from selling your personal information.</li>
                    </ol>
                    <p>
                      {`
                      To exercise your rights under the CCPA, you can contact the business that collected your personal information and submit a request with “California Privacy Rights” in the subject line.
                      `}
                    </p>
                    <p>
                      {`
                      When you submit a request, you should include the following information:
                      `}
                    </p>
                    <ol className="num">
                      <li>Your name</li>
                      <li>Your California address</li>
                      <li>The information you are requesting</li>
                      <li>The reason you are requesting the information</li>
                    </ol>
                  </div>
                  <div className="txt-section section-15">
                    <h3>Contacting us</h3>
                    <p>
                      {`
                      If you have any questions, concerns, or complaints regarding this Policy, the information we hold about you, or if you wish to exercise your rights, we encourage you to contact us using the details below:
                      `}
                      <a href="mailto:tskim@thirarobotics.com" style={{display: 'block'}}>
                        tskim@thirarobotics.com
                      </a>
                    </p>
                    <p>
                      {`
                      Data protection officer: Brian Kim
                      `}
                      <a href="mailto:tskim@thirarobotics.com" style={{display: 'block'}}>
                        tskim@thirarobotics.com
                      </a>
                    </p>
                  </div>
                  <h3>This document was last updated on July 24, 2023</h3>
                </>
              )}
            </div>
            <div className="popup-content__btn">
              <button onClick={closePopup}>{lang === 'ko' ? '닫기' : 'close'}</button>
            </div>
          </div>
        </aside>
        {/* 모바일 */}
        <aside className="popup-overlay-mo privacy">
          <div className="popup-content">
            <div className="popup-content__title">
              <h2>{lang === 'ko' ? '개인정보 처리방침' : 'Privacy Policy'}</h2>
            </div>
            <div className="popup-content__txt">
              {lang === 'ko' ? (
                <>
                  <p>
                    {`<(주)티라로보틱스>(‘https://thirarobotics.com/’이하 ‘(주)티라로보틱스’)는 개인정보보호법에 따라
                        이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수
                        있도록 다음과 같은 처리방침을 두고 있습니다.`}
                  </p>
                  <p>
                    {`<(주)티라로보틱스>(‘(주)티라로보틱스’)는 회사는 개인정보처리방침을 개정하는 경우 
                        웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.`}
                  </p>
                  <p>{`○ 본 방침은부터 2023년 05월 31일부터 시행됩니다.`}</p>
                  <ol className="popup-content__txt__ol decimal">
                    {/* 조항1 */}
                    <li>
                      <p className="title">
                        {`개인정보의 처리 목적 <(주)티라로보틱스>(‘https://thirarobotics.com/’이하 ‘(주)티라로보틱스’)은(는) 
                        개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 
                        사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.`}
                      </p>
                      <ol className="popup-content__txt__ol-list kr">
                        <li>
                          <h4>홈페이지 회원가입 및 관리</h4>
                          <p>{`서비스 부정이용 방지, 각종 고지·통지, 고충처리, 분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다.`}</p>
                        </li>
                        <li>
                          <h4>민원사무 처리</h4>
                          <p>{`민원인의 신원 확인, 민원사항 확인, 처리결과 통보 등을 목적으로 개인정보를 처리합니다.`}</p>
                        </li>
                      </ol>
                    </li>
                    {/* 조항2 */}
                    <li>
                      <p className="title">
                        {`개인정보 파일 현황`}
                        <br />
                        {`(‘https://thirarobotics.com/’이하 ‘(주)티라로보틱스’)가 개인정보 보호법 제32조에 따라 등록․공개하는 개인정보파일의 처리목적은 다음과 같습니다.`}
                      </p>
                      <ol className="popup-content__txt__ol-list decimal">
                        <li>
                          <h4>개인정보 파일명 : 티라로보틱스_Q&A_정보수집</h4>
                          <ul className="popup-content__txt__ol-list__ul">
                            <li>개인정보 항목 : 이메일, 휴대전화번호, 이름, 회사전화번호, 직책, 부서, 회사명</li>
                            <li> 수집방법 : 홈페이지</li>
                            <li>보유근거 : 티라로보틱스_Q&A_정보수집</li>
                            <li>보유기간 : 3년</li>
                            <li>관련법령 : 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</li>
                          </ul>
                        </li>
                      </ol>
                      <p>
                        {`※ 기타(‘https://thirarobotics.com/’이하 ‘(주)티라로보틱스’)의 개인정보파일 등록사항 공개는 행정안전부
                            개인정보보호 종합지원 포털(www.privacy.go.kr) → 개인정보민원 → 개인정보열람등 요구 →
                            개인정보파일 목록검색 메뉴를 활용해주시기 바랍니다.`}
                      </p>
                    </li>
                    {/* 조항3 */}
                    <li>
                      <h3>개인정보의 처리 및 보유 기간 </h3>
                      <ol className="popup-content__txt__ol-list circleInNum">
                        <li>
                          {`<(주)티라로보틱스>(‘(주)티라로보틱스’)은(는) 법령에 따른 개인정보 보유·이용기간 또는
                                정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유,이용기간 내에서 개인정보를
                                처리,보유합니다.`}
                        </li>
                        <li>
                          {`각각의 개인정보 처리 및 보유 기간은 다음과 같습니다`}
                          <ol className="popup-content__txt__ol-list__ol num">
                            <li>
                              <h5>{`<민원사무 처리>`}</h5>
                              <p>{`<민원사무 처리>와 관련한 개인정보는 수집.이용에 관한 동의일로부터<3년>까지 위 이용목적을
                                위하여 보유.이용됩니다.`}</p>
                              <ul className="dashed">
                                <li>보유근거 : 티라로보틱스_Q&A_정보수집</li>
                                <li>관련법령 : 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</li>
                                <li>예외사유 :</li>
                              </ul>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    {/* 조항4 */}
                    <li>
                      <h3>정보주체와 법정대리인의 권리·의무 및 그 행사방법 이용자는 개인정보주체로써 다음과 같은 권리를 행사할 수 있습니다.</h3>
                      <ol className="popup-content__txt__ol-list circleInNum">
                        <li>{`정보주체는 (주)티라로보틱스에 대해 언제든지 개인정보 열람,정정,삭제,처리정지 요구 등의 권리를 행사할 수 있습니다.`}</li>
                        <li>{`제1항에 따른 권리 행사는(주)티라로보틱스에 대해 개인정보 보호법 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 (주)티라로보틱스는(는) 이에 대해 지체 없이 조치하겠습니다.`}</li>
                        <li>{`제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.`}</li>
                        <li>{`개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.`}</li>
                        <li>{`개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.`}</li>
                        <li>{`⑥ (주)티라로보틱스는(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.`}</li>
                      </ol>
                    </li>
                    {/* 조항5 */}
                    <li>
                      <h3>처리하는 개인정보의 항목 작성 </h3>
                      <ol className="popup-content__txt__ol-list circleInNum">
                        <li>
                          {`<(주)티라로보틱스>(‘https://thirarobotics.com/’이하 ‘(주)티라로보틱스’)은(는) 다음의 개인정보 항목을 처리하고 있습니다.`}
                          <ol className="popup-content__txt__ol-list__ol num">
                            <li>
                              <h5>{`<민원사무 처리>`}</h5>
                              <ul className="dashed">
                                <li>필수항목 : 이메일, 휴대전화번호, 이름, 회사전화번호, 직책, 부서, 회사명</li>
                                <li>선택항목 : 자택주소, 성별, 생년월일, 직업</li>
                              </ul>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    {/* 조항6 */}
                    <li>
                      <h3>
                        {`개인정보의 파기<(주)티라로보틱스>(‘(주)티라로보틱스’)은(는) 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.`}
                      </h3>
                      <ul className="dashed">
                        <li>
                          <h4>파기절차</h4>
                          <p>
                            {`이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타
                                관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한
                                경우가 아니고서는 다른 목적으로 이용되지 않습니다.`}
                          </p>
                        </li>
                        <li>
                          <h4>파기기한</h4>
                          <p>
                            {`이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 
                            개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는
                            개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.`}
                          </p>
                        </li>
                        <li>
                          <h4>파기방법</h4>
                          <p>{`종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.`}</p>
                        </li>
                      </ul>
                    </li>
                    {/* 조항7 */}
                    <li>
                      <h3>{`개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항 `}</h3>
                      <ol className="circleInNum">
                        <li>
                          <p>{`(주)티라로보틱스 은 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠기(cookie)’를 사용합니다.`}</p>
                        </li>
                        <li>
                          <p>
                            {`쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는
                            소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다. 가. 쿠키의 사용 목적 : 
                            이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을
                            파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다. 나. 쿠키의 설치•운영 및 거부 : 
                            웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수
                            있습니다. 다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.`}
                          </p>
                        </li>
                      </ol>
                    </li>
                    {/* 조항8 */}
                    <li>
                      <h3>{`개인정보 보호책임자 작성`}</h3>
                      <ol className="circleInNum">
                        <li>
                          <p>
                            {`(주)티라로보틱스(‘https://thirarobotics.com/’이하 ‘(주)티라로보틱스) 은(는) 개인정보 처리에 관한
                            업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여
                            아래와 같이 개인정보 보호책임자를 지정하고 있습니다.`}
                          </p>
                          <ul className="triangle">
                            <li>
                              <h4>개인정보 보호 책임자</h4>
                              <ul>
                                <li>성명 : 송희림</li>
                                <li>직책 : 마케팅팀</li>
                                <li>직급 : 팀장</li>
                                <li>연락처 : 031-732-6531, sales@thirarobotics.com</li>
                                <li>※ 개인정보 보호 담당부서로 연결됩니다.</li>
                              </ul>
                            </li>
                            <li>
                              <h4>개인정보 보호 담당부서</h4>
                              <ul>
                                <li>부서명 :</li>
                                <li>담당자 :</li>
                                <li>연락처 :, ,</li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <p>
                            {`정보주체께서는 (주)티라로보틱스(‘https://thirarobotics.com/’이하 ‘(주)티라로보틱스)의 서비스(또는
                            사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을
                            개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 
                            (주)티라로보틱스(‘https://thirarobotics.com/’이하 ‘(주)티라로보틱스) 은(는) 정보주체의 문의에 대해
                            지체 없이 답변 및 처리해드릴 것입니다`}
                          </p>
                        </li>
                      </ol>
                    </li>
                    {/* 조항9 */}
                    <li>
                      <h3>{`개인정보 처리방침 변경`}</h3>
                      <ol className="circleInNum">
                        <li>
                          <p>{`이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및
                        정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.`}</p>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </>
              ) : (
                <>
                  <p>
                    {`We respect your privacy and are committed to protecting it through our compliance with this privacy policy (“Policy”). This Policy describes the types of information we may collect from you or that you may provide (“Personal Information”) on the `}
                    <a href="https://www.thirarobotics.com/" target="_blank">
                      thirarobotics.com
                    </a>
                    {` website (“Website” or “Service”) and any of its related products and services (collectively, “Services”), and our practices for collecting, using, maintaining, protecting, and disclosing that Personal Information. It also describes the choices available to you regarding our use of your Personal Information and how you can access and update it.`}
                  </p>
                  <p>
                    {`This Policy is a legally binding agreement between you (“User”, “you” or “your”) and THIRA Robotics (“THIRA Robotics”, “we”, “us” or “our”). If you are entering into this agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this agreement, in which case the terms “User”, “you” or “your” shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this agreement, you must not accept this agreement and may not access and use the Website and Services. By accessing and using the Website and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Policy. This Policy does not apply to the practices of companies that we do not own or control, or to individuals that we do not employ or manage.`}
                  </p>
                  <h3>Table of contents</h3>
                  <ol className="num">
                    <li>
                      <label for="section-01">Collection of information</label>
                    </li>
                    <li>
                      <label for="section-02">Privacy of children</label>
                    </li>
                    <li>
                      <label for="section-03">Use and processing of collected information</label>
                    </li>
                    <li>
                      <label for="section-04">Disclosure of information</label>
                    </li>
                    <li>
                      <label for="section-05">Retention of information</label>
                    </li>
                    <li>
                      <label for="section-06">Cookies</label>
                    </li>
                    <li>
                      <label for="section-07">Data analytics</label>
                    </li>
                    <li>
                      <label for="section-08">Do Not Track signals</label>
                    </li>
                    <li>
                      <label for="section-09">Social media features</label>
                    </li>
                    <li>
                      <label for="section-10">Links to other resources</label>
                    </li>
                    <li>
                      <label for="section-11">Information security</label>
                    </li>
                    <li>
                      <label for="section-12">Changes and amendments</label>
                    </li>
                    <li>
                      <label for="section-13">Acceptance of this policy</label>
                    </li>
                    <li>
                      <label for="section-14">The California Consumer Privacy Act</label>
                    </li>
                    <li>
                      <label for="section-15">Contacting us</label>
                    </li>
                  </ol>
                  <div className="txt-section section-01">
                    <input type="text" id="section-01" style={{opacity: '0', height: '0'}} />
                    <h3>Collection of information</h3>
                    <p>
                      {`
                      Our top priority is customer data security and, as such, we exercise the no logs policy. We may process only minimal user data, only as much as it is absolutely necessary to maintain the Website and Services. Information collected automatically is used only to identify potential cases of abuse and establish statistical information regarding the usage and traffic of the Website and Services. This statistical information is not otherwise aggregated in such a way that would identify any particular user of the system.
                      `}
                    </p>
                  </div>
                  <div className="txt-section section-02">
                    <input type="text" id="section-02" style={{opacity: '0', height: '0'}} />
                    <h3>Privacy of children</h3>
                    <p>
                      {`
                      We do not knowingly collect any Personal Information from children under the age of 18. If you are under the age of 18, please do not submit any Personal Information through the Website and Services. If you have reason to believe that a child under the age of 18 has provided Personal Information to us through the Website and Services, please contact us to request that we delete that child’s Personal Information from our Services.
                      `}
                    </p>
                    <p>
                      {`
                      We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce this Policy by instructing their children never to provide Personal Information through the Website and Services without their permission. We also ask that all parents and legal guardians overseeing the care of children take the necessary precautions to ensure that their children are instructed to never give out Personal Information when online without their permission.
                      `}
                    </p>
                  </div>
                  <div className="txt-section section-03">
                    <input type="text" id="section-03" style={{opacity: '0', height: '0'}} />
                    <h3>Use and processing of collected information</h3>
                    <p>
                      {`
                      We act as a data controller and a data processor when handling Personal Information, unless we have entered into a data processing agreement with you in which case you would be the data controller and we would be the data processor.
                      `}
                    </p>
                    <p>
                      {`
                      Our role may also differ depending on the specific situation involving Personal Information. We act in the capacity of a data controller when we ask you to submit your Personal Information that is necessary to ensure your access and use of the Website and Services. In such instances, we are a data controller because we determine the purposes and means of the processing of Personal Information.
                      `}
                    </p>
                    <p>
                      {`
                      We act in the capacity of a data processor in situations when you submit Personal Information through the Website and Services. We do not own, control, or make decisions about the submitted Personal Information, and such Personal Information is processed only in accordance with your instructions. In such instances, the User providing Personal Information acts as a data controller.
                      `}
                    </p>
                    <p>
                      {`
                      In order to make the Website and Services available to you, or to meet a legal obligation, we may need to collect and use certain Personal Information. If you do not provide the information that we request, we may not be able to provide you with the requested products or services. Any of the information we collect from you may be used for the following purposes:
                      `}
                    </p>
                    <ul className="fill-circle">
                      <li>Respond to inquiries and offer support</li>
                      <li>Request user feedback</li>
                      <li>Run and operate the Website and Services</li>
                    </ul>
                    <p>
                      {`
                      Processing your Personal Information depends on how you interact with the Website and Services, where you are located in the world and if one of the following applies: (i) you have given your consent for one or more specific purposes; (ii) provision of information is necessary for the performance of an agreement with you and/or for any pre-contractual obligations thereof; (iii) processing is necessary for compliance with a legal obligation to which you are subject; (iv) processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in us; (v) processing is necessary for the purposes of the legitimate interests pursued by us or by a third party.
                      `}
                    </p>
                    <p>
                      {`
                      Note that under some legislations we may be allowed to process information until you object to such processing by opting out, without having to rely on consent or any other of the legal bases. In any case, we will be happy to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Information is a statutory or contractual requirement, or a requirement necessary to enter into a contract.
                      `}
                    </p>
                  </div>
                  <div className="txt-section section-04">
                    <input type="text" id="section-04" style={{opacity: '0', height: '0'}} />
                    <h3>Disclosure of information</h3>
                    <p>
                      {`
                      Depending on the requested Services or as necessary to complete any transaction or provide any Service you have requested, we may share your information with our affiliates, contracted companies, and service providers (collectively, “Service Providers”) we rely upon to assist in the operation of the Website and Services available to you and whose privacy policies are consistent with ours or who agree to abide by our policies with respect to Personal Information. We will not share any personally identifiable information with third parties and will not share any information with unaffiliated third parties.
                      `}
                    </p>
                    <p>
                      {`
                      Service Providers are not authorized to use or disclose your information except as necessary to perform services on our behalf or comply with legal requirements. Service Providers are given the information they need only in order to perform their designated functions, and we do not authorize them to use or disclose any of the provided information for their own marketing or other purposes.
                      `}
                    </p>
                  </div>
                  <div className="txt-section section-05">
                    <input type="text" id="section-05" style={{opacity: '0', height: '0'}} />
                    <h3>Retention of information</h3>
                    <p>
                      {`
                      We will retain and use your Personal Information for the period necessary to comply with our legal obligations, to enforce our agreements, resolve disputes, and unless a longer retention period is required or permitted by law.
                      `}
                    </p>
                    <p>
                      {`
                      We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally. Once the retention period expires, Personal Information shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification, and the right to data portability cannot be enforced after the expiration of the retention period.
                      `}
                    </p>
                  </div>
                  <div className="txt-section section-06">
                    <input type="text" id="section-06" style={{opacity: '0', height: '0'}} />
                    <h3>Cookies</h3>
                    <p>
                      {`
                      Our Website and Services use “cookies” to help personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.
                      `}
                    </p>
                    <p>
                      {`
                      We may use cookies to collect, store, and track information for security and personalization, and for statistical purposes. Please note that you have the ability to accept or decline cookies. Most web browsers automatically accept cookies by default, but you can modify your browser settings to decline cookies if you prefer.
                      `}
                    </p>
                  </div>
                  <div className="txt-section section-07">
                    <input type="text" id="section-07" style={{opacity: '0', height: '0'}} />
                    <h3>Data analytics</h3>
                    <p>
                      {`
                      Our Website and Services may use third-party analytics tools that use cookies, web beacons, or other similar information-gathering technologies to collect standard internet activity and usage information. The information gathered is used to compile statistical reports on User activity such as how often Users visit our Website and Services, what pages they visit and for how long, etc. We use the information obtained from these analytics tools to monitor the performance and improve our Website and Services. We do not use third-party analytics tools to track or to collect any personally identifiable information of our Users and we will not associate any information gathered from the statistical reports with any individual User.
                      `}
                    </p>
                  </div>
                  <div className="txt-section section-08">
                    <input type="text" id="section-08" style={{opacity: '0', height: '0'}} />
                    <h3>Do Not Track signals</h3>
                    <p>
                      {`
                      Some browsers incorporate a Do Not Track feature that signals to websites you visit that you do not want to have your online activity tracked. Tracking is not the same as using or collecting information in connection with a website. For these purposes, tracking refers to collecting personally identifiable information from consumers who use or visit a website or online service as they move across different websites over time. How browsers communicate the Do Not Track signal is not yet uniform. As a result, the Website and Services are not yet set up to interpret or respond to Do Not Track signals communicated by your browser. Even so, as described in more detail throughout this Policy, we limit our use and collection of your Personal Information. For a description of Do Not Track protocols for browsers and mobile devices or to learn more about the choices available to you, visit
                      `}
                      <a href="https://www.internetcookies.com" target="_blank">
                        internetcookies.com
                      </a>
                    </p>
                  </div>
                  <div className="txt-section section-09">
                    <input type="text" id="section-09" style={{opacity: '0', height: '0'}} />
                    <h3>Social media features</h3>
                    <p>
                      {`
                      Our Website and Services may include social media features, such as the Facebook and Twitter buttons, Share This buttons, etc (collectively, “Social Media Features”). These Social Media Features may collect your IP address, what page you are visiting on our Website and Services, and may set a cookie to enable Social Media Features to function properly. Social Media Features are hosted either by their respective providers or directly on our Website and Services. Your interactions with these Social Media Features are governed by the privacy policy of their respective providers.
                      `}
                    </p>
                  </div>
                  <div className="txt-section section-10">
                    <input type="text" id="section-10" style={{opacity: '0', height: '0'}} />
                    <h3>Links to other resources</h3>
                    <p>
                      {`
                      The Website and Services contain links to other resources that are not owned or controlled by us. Please be aware that we are not responsible for the privacy practices of such other resources or third parties. We encourage you to be aware when you leave the Website and Services and to read the privacy statements of each and every resource that may collect Personal Information.
                      `}
                    </p>
                  </div>
                  <div className="txt-section section-11">
                    <input type="text" id="section-11" style={{opacity: '0', height: '0'}} />
                    <h3>Information security</h3>
                    <p>
                      {`
                      We secure information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use, or disclosure. We maintain reasonable administrative, technical, and physical safeguards in an effort to protect against unauthorized access, use, modification, and disclosure of Personal Information in our control and custody. However, no data transmission over the Internet or wireless network can be guaranteed.
                      `}
                    </p>
                    <p>
                      {`
                      Therefore, while we strive to protect your Personal Information, you acknowledge that (i) there are security and privacy limitations of the Internet which are beyond our control; (ii) the security, integrity, and privacy of any and all information and data exchanged between you and the Website and Services cannot be guaranteed; and (iii) any such information and data may be viewed or tampered with in transit by a third party, despite best efforts.
                      `}
                    </p>
                  </div>
                  <div className="txt-section section-12">
                    <input type="text" id="section-12" style={{opacity: '0', height: '0'}} />
                    <h3>Changes and amendments</h3>
                    <p>
                      {`
                      We reserve the right to modify this Policy or its terms related to the Website and Services at any time at our discretion. When we do, we will post a notification on the main page of the Website. We may also provide notice to you in other ways at our discretion, such as through the contact information you have provided.
                      `}
                    </p>
                    <p>
                      {`
                      An updated version of this Policy will be effective immediately upon the posting of the revised Policy unless otherwise specified. Your continued use of the Website and Services after the effective date of the revised Policy (or such other act specified at that time) will constitute your consent to those changes. However, we will not, without your consent, use your Personal Information in a manner materially different than what was stated at the time your Personal Information was collected.
                      `}
                    </p>
                  </div>
                  <div className="txt-section section-13">
                    <input type="text" id="section-13" style={{opacity: '0', height: '0'}} />
                    <h3>Acceptance of this policy</h3>
                    <p>
                      {`
                      You acknowledge that you have read this Policy and agree to all its terms and conditions. By accessing and using the Website and Services and submitting your information you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to access or use the Website and Services.
                      `}
                    </p>
                  </div>
                  <div className="txt-section section-14">
                    <input type="text" id="section-14" style={{opacity: '0', height: '0'}} />
                    <h3>The California Consumer Privacy Act</h3>
                    <p>
                      {`
                      The California Consumer Privacy Act (CCPA) gives California residents certain rights over their personal information. These rights include the right to:
                      `}
                    </p>
                    <ol className="num">
                      <li>Know what personal information a business has collected about you.</li>
                      <li>Request a copy of your personal information.</li>
                      <li>Request that a business delete your personal information.</li>
                      <li>Restrict a business from selling your personal information.</li>
                    </ol>
                    <p>
                      {`
                      To exercise your rights under the CCPA, you can contact the business that collected your personal information and submit a request with “California Privacy Rights” in the subject line.
                      `}
                    </p>
                    <p>
                      {`
                      When you submit a request, you should include the following information:
                      `}
                    </p>
                    <ol className="num">
                      <li>Your name</li>
                      <li>Your California address</li>
                      <li>The information you are requesting</li>
                      <li>The reason you are requesting the information</li>
                    </ol>
                  </div>
                  <div className="txt-section section-15">
                    <input type="text" id="section-15" style={{opacity: '0', height: '0'}} />
                    <h3>Contacting us</h3>
                    <p>
                      {`
                      If you have any questions, concerns, or complaints regarding this Policy, the information we hold about you, or if you wish to exercise your rights, we encourage you to contact us using the details below:
                      `}
                      <a href="mailto:tskim@thirarobotics.com" style={{display: 'block'}}>
                        tskim@thirarobotics.com
                      </a>
                    </p>
                    <p>
                      {`
                      Data protection officer: Brian Kim
                      `}
                      <a href="mailto:tskim@thirarobotics.com" style={{display: 'block'}}>
                        tskim@thirarobotics.com
                      </a>
                    </p>
                  </div>
                  <h3>This document was last updated on July 24, 2023</h3>
                </>
              )}
            </div>
            <div className="popup-content__btn">
              <button onClick={closePopup}>{lang === 'ko' ? '닫기' : 'close'}</button>
            </div>
          </div>
        </aside>
      </>
    </React.Fragment>
  );
}
export default ThiraPolicy;
